import React, {Component} from "react";
import fetch from "isomorphic-unfetch";
import {csrfToken} from "../../../utils"
import Select from "react-select";
import swal from "sweetalert2";
import ReactTable from "react-table";
import ReactTableFullScreen from "../../../ReactTableFullScreen";

export default class NoProposals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //seasons: undefined,
            //currentSeason: {id: 0, label: "chargement"},
        };
    }

    /*async getSeasons() {
        let seasons = await fetch("/seasons",
            {
                method: "GET",
                headers: {
                    "X-CSRF-Token": csrfToken,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then((response) => {
            if (!response.ok) {
                return undefined
            } else {
                return response.json()
            }
        }).then((data) => {

            let seasons = data.sort((a,b) => (a.label < b.label) ? 1 : ((b.label < a.label) ? -1 : 0))

            return seasons

            return data
        });



        return seasons;
    }*/

    async componentDidMount() {
        /*let seasons = await this.getSeasons()
        this.setState({seasons: seasons})

        let currentSeason = seasons.find(function(element) {return element.is_current = true})
        this.setState({currentSeason: currentSeason})*/
    }

    render() {
        var data = this.props.payers;
        var seasons = this.props.seasons;
        var currentSeason = this.props.currentSeason;

        const columns = [
            {
                id: "season",
                Header: "Saison",
                width: 200,
                sortable: false,
                filterable: !this.props.nofilter,
                accessor: d => d.season_label,
                Cell: props => (
                    <div style={{textAlign:"center"}}>{props.value.replace("Saison ", "")}</div>
                ),
                Filter: ({ filter, onChange }) => (
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : currentSeason.label}
                    >
                        {seasons.map(function (element) {
                            return <option value={element.label} key={"saison"+element.id}>{element.label}</option>
                        })
                        }
                    </select>
                ),
            },
            {
                id: "payer_lastname",
                Header: "Nom",
                width: 350,
                accessor: d => d.payer_lastname_label,
                Cell: props => {
                    return(
                        <a href={`/payments/summary/${props.original.payer_member_id}`} className="w-100 d-flex font-underlined">
                            {props.original.payer_lastname_label}
                        </a>
                    )
                }
            },
            {
                id: "payer_firstname",
                Header: "Prénom",
                width: 350,
                accessor: d => d.payer_firstname_label,
                Cell: props => {
                    return(
                        <a href={`/payments/summary/${props.original.payer_member_id}`} className="w-100 d-flex font-underlined">
                            {props.original.payer_firstname_label}
                        </a>
                    )
                },
            }
        ]

        return (
            <div className="ibox">
            <ReactTable
                resizable={false}
                previousText="Précédent"
                nextText="Suivant"
                loadingText="Chargement..."
                noDataText="Aucune donnée"
                pageText="Page"
                ofText="sur"
                rowsText="résultats"
                minRows={1}
                columns={columns}
                data={data}
                defaultSorted={[{id: "payer_lastname", asc: true}]}
                filterable
                defaultFiltered={[{ id: "season", value: this.props.currentSeason.label }]}
                defaultFilterMethod={(filter, row) => {
                    if (row[filter.id] != null) {
                        return row[filter.id]
                            .toLowerCase()
                            .includes(filter.value.toLowerCase())
                    }
                }}
            />
            </div>
        )

    }

}