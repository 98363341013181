import React, {Component} from "react";
import Select from 'react-select';

export default class DolibarrOption extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            selectedValue: undefined,
        };
    }

    handleChange(value) {
        this.setState({selectedValue: value.id});

        let link = {dolibarr_id: value.id, activity_ref_pricing_id: this.props.activityRefPricingId}

        this.props.onLinkChange(link);
    }

    componentDidMount() {
        let value = null;

        if (this.props.dolibarrId) {
            value = this.props.dolibarrId;
        }

        this.setState({selectedValue: value});
    }

    render() {
        let selectedValue = null;

        if (this.props.dolibarrId) {
            selectedValue = this.props.dolibarrProducts.find((element) => element.id == this.props.dolibarrId);
        }
 
        if (selectedValue !== undefined && this.props.activityRefPricingId) {
            return(
                <Select
                    defaultValue={selectedValue}
                    onChange={this.handleChange}
                    getOptionValue={(option) => option.id}
                    options={this.props.dolibarrProducts}
                    isClearable
                    isSearchable
                    menuPlacement="auto"
                />
            )
        } else {
            return(
                <div>...</div>
            )
        }
        
        
    }

}