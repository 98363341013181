import React, {Fragment} from 'react';
import * as api from "../../../../tools/api.js";
import swal from "sweetalert2";

export default function SyncMetabase()
{
    function syncMetabase()
    {
        swal.showLoading();

        api.set()
            .success((data) => {
                window.location.reload(); // refresh page
            })
            .error((error) => {
                console.error(error);

                swal.fire({
                    title: "Erreur",
                    text: "Une erreur est survenue lors de la synchronisation avec Metabase.",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            })
            .post("/api/management/sync_metabase", {});
    }

    return <Fragment>
        <div style={{
            display: "grid", // to vertical align
            alignContent: "center", // vertical align
            position: "absolute", // to be on relative to the parent but dynamic (!= fixed)
            zIndex: 100, // to be on top of the other elements
            width: "fit-content", // to be as small as possible
            height: "65px", // to have same height as the over element (determinated by classes with fixed px)
            top: "55px", // padding-top + border + margin des autres éléments
            right: "30px" // padding-right * 2 des autres éléments
        }}>
            <button type="button" className="btn btn-primary" onClick={syncMetabase}>Sync Metabase</button>
        </div>
    </Fragment>
}