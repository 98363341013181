import React, {Fragment} from "react";

import {Field, Form} from "react-final-form";

import Input from "./../../../../components/common/Input";
import InputSelect from "./../../../../components/common/InputSelect";

import {FieldArray} from "react-final-form-arrays";

import arrayMutators from "final-form-arrays";
import * as api from "./../../../../tools/api";
import {redirectTo} from "./../../../../tools/url";
import swal from "sweetalert2";


export default class StripeProductEdit extends React.Component {
    constructor(props) {
        super(props)
    }

    async onSubmit(e) {
        const id = e.prices[0].stripe_product_id

        await api
            .set()
            .success(() => {
                redirectTo("/facturation");
                swal({
                    type: "success",
                    title: "Produit modifié"
                });

            })
            .error((msg) => {
                swal({
                    type: "error",
                    title: "Une erreur est survenue",
                    text: msg.message
                })

            })
            .post(`/stripe_products/${id}`, {product: e});
    }


    validate(e) {
        const errors = {}
        if (e.code_product === undefined || (e.code_product && e.code_product.trim().length < 1))
            errors.code_product = "Le code produit ne peut pas être vide."

        if (e.name === undefined || (e.name && e.name.trim().length < 1))
            errors.name = "Le nom du produit ne doit pas être vide."

        if (e.description === undefined || (e.description && e.description.trim().length < 1))
            errors.description = "La description du produit ne peut pas être vide."

        if (e.tax_rate === undefined)
            errors.tax_rate = "Choisissez un taux de taxe."

        if (e.active === undefined)
            errors.active = "Renseignez si le produit est actif ou archivé."

        if (e.prices) {
            const prices = e.prices
            if (prices.length !== 0) {
                let pricesErrors = []
                let nbDefaultPrice = 0
                for (let i = 0; i < prices.length; i++) {
                    let rowErrors = {}
                    // Vérification sur le prix
                    if (prices[i].unit_amount_decimal === undefined)
                        rowErrors.unit_amount_decimal = "Le prix ne peut pas être vide."

                    if (prices[i].unit_amount_decimal && prices[i].unit_amount_decimal.trim().length > 0) {
                        let regExp = /[a-zA-Z]/g;
                        if (regExp.test(prices[i].unit_amount_decimal) || isNaN(parseFloat(prices[i].unit_amount_decimal)))
                            rowErrors.unit_amount_decimal = "Le prix ne peut pas contenir de caractère."
                        else {
                            const n = parseFloat(prices[i].unit_amount_decimal)
                            if (n < 1)
                                rowErrors.unit_amount_decimal = "Le prix doit être plus grand que 0."
                        }
                    }

                    // Vérification sur le status du prix (actif / archivé)

                    if (prices[i].active === undefined)
                        rowErrors.active = "Renseignez si le prix est actif ou archivé."

                    if (prices[i].price_default !== null && prices[i].price_default !== undefined) {
                        if (typeof (prices[i].active) === "string")
                            prices[i].active = prices[i].active === "true"
                        if (!prices[i].active)
                            rowErrors.active = "Le prix par défaut ne peut pas être archivé."
                    }

                    if (prices[i].price_default !== null && prices[i].price_default !== undefined)
                        nbDefaultPrice++

                    pricesErrors.splice(i, 0, rowErrors)

                }
                if (nbDefaultPrice === 0)
                    pricesErrors[0].price_default = "Choisissez un prix par défaut."
                if (nbDefaultPrice > 1)
                    pricesErrors[0].price_default = "Vous pouvez choisir qu'un seul prix par défaut."

                errors.prices = pricesErrors
            }
        }

        return errors;
    }

    render() {
        const product = this.props.product
        const priceLength = product.stripe_prices.length

        const tax_rates = this.props.tax_rates
        const initPrice = (stripeProduct) => {
            const stripePrices = stripeProduct.stripe_prices
            const indexDefaultPrice = stripePrices.findIndex((price) => price.stripe_price_id === stripeProduct.default_price_id)
            stripePrices[indexDefaultPrice].price_default = indexDefaultPrice
            return {prices: [...stripePrices]}
        }
        const priceTTC = (taxRateID, index, tax_rates, fields) => {
            if (fields.value[index] === undefined)
                return "0.00"

            const priceHt = parseFloat(fields.value[index].unit_amount_decimal.replace(',', '.'))

            if (isNaN(priceHt))
                return "0.00"

            const taxSelect = tax_rates.find(element => element.value === taxRateID)
            if (taxSelect === undefined)
                return "0.00"
            const tax = 1 + (parseFloat(taxSelect.label.replace('%', '')) / 100)

            return (priceHt * tax).toFixed(2)
        }

        const disabledSubmit = (state) => {
            return !(state.valid && state.values.prices !== undefined && state.values.prices.length > 0)
        }
        return <Fragment>
            <div className="row wrapper border-bottom white-bg page-heading m-b-md">
                <h2>Modifier un produit</h2>
            </div>
            <Form
                onSubmit={this.onSubmit}
                validate={this.validate}
                mutators={{
                    ...arrayMutators
                }}
                initialValues={initPrice(product)}
                render={({handleSubmit, submitting, form: {getState, mutators: {push}}}) => (
                    <section className="panel panel-default">
                        <form onSubmit={handleSubmit}>

                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-sm-2 form-group">
                                        <Field
                                            label="Code du produit"
                                            name="code_product"
                                            defaultValue={product.code_product}
                                            required
                                            render={Input}
                                        />
                                    </div>

                                    <div className="col-sm-4 form-group">
                                        <Field
                                            label="Nom du produit"
                                            name="name"
                                            type="text"
                                            defaultValue={product.name}
                                            required
                                            render={Input}
                                        />
                                    </div>
                                    <div className="col-sm-3 form-group">
                                        <Field
                                            label="Produit actif"
                                            name="active"
                                            type="select"
                                            defaultValue={product.active}
                                            required
                                            render={InputSelect}
                                            options={[
                                                {"value": "true", "label": "oui"},
                                                {"value": "false", "label": "non"}
                                            ]}

                                        />
                                    </div>
                                    <div className="col-sm-3 form-group">
                                        <Field
                                            label="Unité"
                                            name="unit_label"
                                            type="text"
                                            defaultValue={product.unit_label}
                                            render={Input}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-2 form-group">
                                        <Field
                                            label="Taux de taxe (HT)"
                                            name="tax_rate"
                                            required
                                            type="select"
                                            defaultValue={product.tax_rate}
                                            render={InputSelect}
                                            options={tax_rates}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6 form-group">
                                        <Field
                                            label="Description"
                                            name="description"
                                            defaultValue={product.description}
                                            required
                                            isArea={true}
                                            render={Input}
                                        />
                                    </div>
                                </div>
                                <FieldArray name="prices">
                                    {({fields}) =>
                                        fields.map((name, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-sm-2 form-group">
                                                    <div>
                                                        <Field
                                                            label={"Prix n°" + (index + 1)}
                                                            disabled={index < priceLength}
                                                            name={`${name}.unit_amount_decimal`}
                                                            type={'text'}
                                                            required
                                                            render={Input}
                                                            format={(parsedValue) => (index < priceLength ? parseFloat(parsedValue).toFixed(2) + "€" : parsedValue)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-2 form-group">
                                                    <label>Prix TTC</label>
                                                    <label className="form-control">{
                                                        priceTTC(getState().values.tax_rate, index, tax_rates, fields) + " €"
                                                    }</label>
                                                </div>
                                                <div className="col-sm-2 form-group">
                                                    <Field
                                                        label="State"
                                                        name={`${name}.active`}
                                                        type="select"
                                                        required
                                                        render={InputSelect}

                                                        options={[
                                                            {"value": true, "label": "actif"},
                                                            {"value": false, "label": "archivé"}
                                                        ]}
                                                    />
                                                </div>

                                                <div className="col-sm-2 form-group">
                                                    <Field label="Prix par défaut "

                                                           id={`${name}.price_default`}
                                                           name={`${name}.price_default`}
                                                           type="checkbox"
                                                           format={v => v === index}
                                                           parse={v => (v ? index : null)}
                                                           render={Input}/>
                                                </div>
                                                {index > priceLength - 1 ?
                                                    <span onClick={() => fields.remove(index)}
                                                          style={{cursor: "pointer"}}>❌</span>
                                                    : ""}


                                            </div>
                                        ))
                                    }

                                </FieldArray>
                                <div className="buttons">
                                    <button type="button" className="btn btn-primary"
                                            onClick={() => push("prices", field)}>
                                        Ajouter un prix
                                    </button>
                                </div>
                                <div className="flex flex-end-justified" type="submit">
                                    <button className="btn btn-primary"
                                            disabled={disabledSubmit(getState()) || submitting}>
                                        {submitting ?
                                            <i className="fas fa-circle-notch fa-spin m-r-sm"/> :
                                            <i className="fas fa-save m-r-sm"/>}
                                        Modifier le produit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </section>

                )}
            />
        </Fragment>
    }
}
const field = {
    unit_amount_decimal: "",
    active: false,
    price_default: null
};