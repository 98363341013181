import React from "react";
import CoreUserPaymentsV2 from '../../../userPayments/v2/UserPaymentsV2';
import * as api from "../../../../tools/api";
import swal from "sweetalert2";
import {toast} from "react-toastify";

export default function UserPaymentsV2({seasons, user, is_current_user})
{
    const urlSearchParams = new URLSearchParams(window.location.search);
    const outcome = urlSearchParams.get('outcome');
    const cs_id = urlSearchParams.get('cs_id');

    const [showPayButton, setShowPayButton] = React.useState(false);
    const [season, setSeason] = React.useState(undefined);

    function verifPaymentSucceeded(cs_id)
    {
        try
        {
            api.set()
                .success((data) =>
                {
                    if(data.payment_succeeded)
                    {
                        toast.hide();
                        toast.success("Paiement validé !", {
                            autoClose: 2000,
                            onClose: () => window.location.reload(),
                        });
                    }
                    else
                    {
                        setTimeout(() => verifPaymentSucceeded(cs_id), 3000);
                    }
                })
                .error(data =>
                {
                    console.error(data);

                    setTimeout(() => verifPaymentSucceeded(cs_id), 2500);
                })
                .get("/users/student_payments/payment_succeeded", {cs_id});
        }
        catch (e)
        {
            console.error(e);

            setTimeout(() => verifPaymentSucceeded(cs_id), 2500);
        }
    }

    switch (outcome)
    {
        case "cancel":
            console.debug("Payment cancelled");
            break;
        case "success":
            console.debug("Payment success");

            if (cs_id)
            {
                toast.info("Paiement en cours de validation...");

                setTimeout(() => verifPaymentSucceeded(cs_id), 1000);
            }
            else
            {
                console.error("Missing cs_id parameter in URL");

                swal({
                    title: "Erreur",
                    text: "Une erreur est survenue lors de la récupération des informations de paiement.",
                    type: "error",
                });
            }
    }

    function onPayClicked(season)
    {
        api.set()
            .success((data) =>
            {
                console.log(data);

                window.location = data.url;
            })
            .error(data =>
            {
                console.error(data);

                swal({
                    title: "Erreur",
                    text: data.error || "Une erreur est survenue lors de la récupération des informations de paiement.",
                    type: "error",
                })
            })
            .get(`/users/${user.id}/student_payments/pay_next_due_payment`, {season_id: season.id});
    }

    React.useEffect(() =>
    {
        if(season) {
            api.set()
                .success((data) => {
                    if(data.can_show_pay_btn)
                    {
                        setShowPayButton(true);
                    }
                    else
                    {
                        setShowPayButton(false);

                        console.error(data.error);

                        toast.error(data.error, {autoClose: 5000});
                    }
                })
                .error(data => {
                    console.error(data.error);

                    setShowPayButton(false);
                })
                .get(`/users/${user.id}/student_payments/can_show_pay_btn`, {season_id: (season || {}).id});
        }
    }, [season]);

    return <CoreUserPaymentsV2
        seasons={seasons}
        user={user}
        is_current_user={is_current_user}
        onPayClicked={showPayButton ? onPayClicked : undefined}
        onSeasonChanged={(season) => setSeason(season)}
    />;
}