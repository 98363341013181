import React, {Fragment, useState, useEffect} from "react";
import * as api from "../../../tools/api";
import BaseParameters from "../../parameters/BaseParameters";
import {optionMapper} from "../../utils";

export default function StatsPage({error, questions, dashboards, seasons, currentSeasonId}) {
    const [season, setSeason] = useState(currentSeasonId);

    function handleChangeSeason(seasonId) {
        setSeason(parseInt(seasonId));
    }

    if (error) {
        return <Fragment>
            <div className="row">
                <div className="col-sm-12">
                    <div className="alert alert-danger">
                        <i className="fas fa-exclamation-triangle"></i> {error}
                    </div>
                </div>
            </div>
        </Fragment>
    }


    const [questionUrls, setQuestionUrls] = useState({});

    useEffect(() => {

        const questionPromises = questions.map(q => {
            return api.set()
                .success((data) => {
                    return {
                        name: q.name,
                        url: data.url,
                        title: data.title
                    };
                })
                .error((error) => {
                    console.error(error);
                })
                .get("/api/stats_metabase/question/" + q.name, {});
        });

        Promise.all(questionPromises).then(urlDatas => {
            const questionTmpUrl = {};

            urlDatas.forEach(urlData => {
                questionTmpUrl[urlData.name] = urlData.url;
            });

            setQuestionUrls(questionTmpUrl);
        });

    }, []);

    return <Fragment>

        <div className="row mt-3">
            <div className="col-sm-12">
                <iframe src={questionUrls["students_activity_evolution"]} width="100%" height="500px"
                        frameBorder="0"></iframe>
            </div>
        </div>

        <div className="row mt-3">

        </div>


        <div className="row mt-1">
            <div className="col-sm-12 d-flex justify-content-end">
                <select
                    style={{width: "auto"}}
                    onChange={e =>
                        handleChangeSeason(e.target.value)
                    }
                    className="form-control m-r"
                    value={season ? season : ""}
                >
                    {seasons.map(optionMapper())}
                </select>
            </div>
            <div className="col-sm-12">
                <BaseParameters
                    className="no-margin"
                    tabsNames={dashboards.map(d => d.title)}
                    divObjects={dashboards.map((d, index) => <DashboardTab dashboardName={d.name} seasonId={season} key={index}/>)}
                />
            </div>
        </div>
    </Fragment>
}


const DashboardTab = ({dashboardName, seasonId}) => {
    const [dashboardUrl, setDashboardUrl] = useState(undefined);

    useEffect(() => {
        const url =
            seasonId ?
                `/api/stats_metabase/dashboard/${dashboardName}?season_id=${seasonId}`
                : `/api/stats_metabase/dashboard/${dashboardName}`;
        api.set()
            .success((data) => {
                setDashboardUrl(data.url);
            })
            .error((error) => {
                console.error(error);
            })
            .get(url);
    }, [seasonId]);

    return <Fragment>
        {dashboardUrl ?
            <iframe src={`${dashboardUrl}`} width="100%" height="750px" frameBorder="0"></iframe>
            : ""
        }
    </Fragment>
};
