import React, {Fragment, useState, useEffect} from "react";
import * as api from "../../../tools/api";
import swal from "sweetalert2";

export default function Settings()
{
    const [settings, setSettings] = useState({
        metabase_url: "",
        metabase_public_token: "",
        metabase_token_expiration: "",
        questions: {},
        dashboards: {}
    });

    useEffect(() => {
        api.set()
            .success((data) => {
                setSettings(data);
            })
            .error((error) => {
                console.error(error);
            })
            .get("/api/stats_metabase/settings", {});
    }, []);

    const save = () => {
        api.set()
            .success((data) => {
                swal({
                    title: "Succès",
                    text: "Les paramètres ont été enregistrés.",
                    type: "success",
                });
            })
            .error((error) => {
                console.error(error);

                swal({
                    title: "Erreur",
                    text: "Une erreur est survenue lors de l'enregistrement des paramètres.",
                    type: "error",
                });
            })
            .post("/api/stats_metabase/settings", {
                settings: settings
            });
    };

    return <Fragment>
        <div className="row">
            <div className="col-sm-12">
                <h2>Configuration du plugin Metabase</h2>
            </div>
        </div>

        <div className="row">
            <div className="col-sm-5">
                <div className="form-group">
                    <label>URL du serveur Metabase :</label>
                    <input type="url" className="form-control" value={settings.metabase_url} onChange={(e) => setSettings({...settings, metabase_url: e.target.value})} />
                </div>
            </div>

            <div className={"col-sm-5"}>
                <div className="form-group">
                    <label>Clé secrète Metabase :</label>
                    <input type="password" className="form-control" value={settings.metabase_public_token} onChange={(e) => setSettings({...settings, metabase_public_token: e.target.value})} />
                </div>
            </div>

            <div className={"col-sm-2"}>
                <div className="form-group">
                    <label>Délai d'expiration du token Metabase :</label>
                    <input type="number" className="form-control" value={settings.metabase_token_expiration} onChange={(e) => setSettings({...settings, metabase_token_expiration: e.target.value})} />
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-sm-12">
                <h2>Questions</h2>
            </div>
        </div>

        <div className={"row"}>
            {Object.keys(settings.questions).map(q => {
                return <div className={"col-sm-6"}>
                    <div className="form-group">
                        <label>id de la question "{settings.questions[q].title}":</label>
                        <input type="number" className="form-control" value={settings.questions[q].id} onChange={(e) => setSettings({...settings, questions: {...settings.questions, [q]: {...settings.questions[q], id: e.target.value}}})} />
                    </div>
                </div>
            })}
        </div>

        <div className="row">
            <div className="col-sm-12">
                <h2>Tableaux de bord</h2>
            </div>
        </div>

        <div className={"row"}>
            {Object.keys(settings.dashboards).map(d => {
                return <div className={"col-sm-6"}>
                    <div className="form-group">
                        <label>id du tableau de bord "{settings.dashboards[d].title}":</label>
                        <input type="number" className="form-control" value={settings.dashboards[d].id} onChange={(e) => setSettings({...settings, dashboards: {...settings.dashboards, [d]: {...settings.dashboards[d], id: e.target.value}}})} />
                    </div>
                </div>
            })}
        </div>

        <div className="row">
            <div className="col-sm-12 text-right">
                <button type="button" className="btn btn-primary" onClick={save}>Enregistrer</button>
            </div>
        </div>
    </Fragment>
}