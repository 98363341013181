import React, {Fragment} from "react";

import TableProduct from "../stripeUtils/TableProduct";
import SelectUser from "../stripeUtils/SelectUser";
import EditOrganizationModal from "../stripeUtils/EditOrganizationModal";

import Input from "./../../../../components/common/Input";

import {Field, Form} from "react-final-form";
import arrayMutators from "final-form-arrays";
import * as api from "./../../../../tools/api";
import {redirectTo} from "./../../../../tools/url";

import swal from "sweetalert2";

const moment = require('moment');
export default class StripeInvoiceEditCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.doSubmit = this.doSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.createOperation = !this.props.invoice.stripe_invoice_id
        this.editOperation = !this.createOperation;
    }

    initInvoice(items) {
        if (!items) return (
            {
                invoiceItems: [],
                archivedProducts: []
            }
        )

        const invoiceItems = []
        const archivedProducts = []
        items.forEach(item => {
            if (item.stripe_price !== undefined) {
                const stripeProductActive = item.stripe_price.stripe_product.active
                //console.log("item: ", item)
                //console.log("discount ?", item.discounts && item.discounts.length > 0 ? item.discounts[0] : 0)
                if (stripeProductActive) {
                    invoiceItems.push({
                        quantity: item.quantity,
                        stripe_ii: item.stripe_ii,
                        stripe_price_id: item.stripe_price_id,
                        stripe_product_id: item.stripe_price.stripe_product_id,
                        stripe_product_name: item.stripe_price.stripe_product.name,
                        taxId: item.tax_rates && item.tax_rates.length > 0 ? item.tax_rates[0].id : null,
                        couponId: item.discounts && item.discounts.length > 0 ? item.discounts[0].coupon.id : "none",
                        active: stripeProductActive
                    })

                } else {
                    archivedProducts.push({
                        quantity: item.quantity,
                        stripe_ii: item.stripe_ii,
                        stripe_price_id: item.stripe_price_id,
                        stripe_product_id: item.stripe_price.stripe_product_id,
                        stripe_product_name: item.stripe_price.stripe_product.name,
                        taxId: item.tax_rates && item.tax_rates.length > 0 ? item.tax_rates[0].id : null,
                        couponId: item.discounts && item.discounts.length > 0 ? item.discounts[0].coupon.id : "none",
                        active: stripeProductActive,
                        price: item.stripe_price.unit_amount_decimal
                    })
                }

            }
        })
        return {
            invoiceItems: [...invoiceItems],
            archivedProducts: [...archivedProducts]
        }
    }

    prepareInvoice(e) {
        const invoice = {
            customer_id: this.editOperation ? e.id_customer : e.id_customer.value,
            due_date: e.due_date,
            description: e.description,
            footer: e.footer
        }
        invoice.items = e.items.map(item => {
                const temp = {
                    stripe_product_id: item.stripe_product_id,
                    stripe_ii: item.stripe_ii,
                    stripe_price_id: item.stripe_price_id,
                    quantity: item.quantity,
                    taxId: item.taxId || null
                };
                if (item.couponId !== "none")
                    temp.couponId = item.couponId;
                return temp;
            }
        )
        return invoice;
    }

    async handleSubmit(e, form) {
        //console.log("handleSubmit e: ", e)
        if (this.preventSubmit(form.getState())) {
            swal({
                type: "error",
                title: "Une erreur est survenue",
                text: "Veuillez ajouter au moins une ligne à la facture."
            })
            return;
        }
        await this.doSubmit(e);
    }

    async doSubmit(e) {
        const invoice = this.prepareInvoice(e);
        const url = this.editOperation ? `/stripe_invoices/${this.props.invoice.stripe_invoice_id}` : "/stripe_invoices/";
        const message = this.editOperation ? "Facture modifié" : "Facture créée";

        //console.log("e", e)
        //console.log("invoice: ", invoice)

        await api
            .set()
            .success(() => {
                redirectTo("/facturation#tab-2");
                swal({
                    type: "success",
                    title: message
                });

            })
            .error((msg) => {
                swal({
                    type: "error",
                    title: "Une erreur est survenue",
                    text: msg.message
                })

            })
            .post(url, {invoice});
    }

    validate(e) {
        const errors = {};

        if (this.createOperation) {
            if (!e.id_customer) {
                errors.id_customer = 'Selectionnez un client.'
            } else {
                if (e.id_customer.displayModal) {
                    errors.id_customer = <EditOrganizationModal userId={e.id_customer.value}/>
                }
                if (e.id_customer.errorValid) {
                    errors.id_customer =
                        <a href={"/users/" + e.id_customer.value + "/edit"}>Ajoutez le numéro de téléphone ainsi que
                            l'adresse postale pour sélectionner ce client</a>
                }
            }
        }
        if (e.due_date) {
            const dateNow = moment(new Date()).format('YYYY-MM-DD');
            if (Date.parse(e.due_date) <= Date.parse(dateNow))
                errors.due_date = "La date d'expiration est incorrecte."
        } else
            errors.due_date = "Selectionnez une date d'expiration."

        /*
        if (e.description === undefined || (e.description && e.description.trim().length < 1))
            errors.description = "La description d'un devis ne peut pas être vide."

        if (e.footer === undefined || (e.footer && e.footer.trim().length < 1))
            errors.footer = "Le pied de page d'un devis ne peut pas être vide."
        */

        if (!e.items || e.items.length === 0) return errors;

        let itemsErrors = []
        e.items.forEach((item, index) => {
            let rowErrors = {}


            // Vérification sur la quantité
            if (item.quantity) {
                let regExp = /[a-zA-Z]/g;
                if (regExp.test(item.quantity) || isNaN(parseFloat(item.quantity)))
                    rowErrors.quantity = "La quantité ne peut pas contenir de caractère."
                else {
                    const n = parseFloat(item.quantity)
                    if (n < 1)
                        rowErrors.quantity = "La quantité doit être plus grande que 0."
                }
            } else {
                rowErrors.quantity = "La quantité ne peut pas être vide."
            }

            // Vérification sur le produit et le prix ont été selectionnés
            if (!item.stripe_product_id || item.stripe_product_id === -1)
                rowErrors.stripe_product_id = "Sélectionnez un produit ou supprimez la ligne."

            if (!item.stripe_price_id || item.stripe_price_id === "none")
                rowErrors.stripe_price_id = "err_must_select_price"

            itemsErrors.splice(index, 0, rowErrors);
        })

        errors.items = itemsErrors

        //console.log("errors: ", errors)
        return errors;

    }

    preventSubmit(formState) {
        // console.log(
        //     "formState: ",
        //     formState,
        //     formState.values ? formState.values.items : null,
        //     formState.values ? formState.values.archivedProducts : null
        // )
        return !(
            formState.valid &&
            formState.values &&
            (formState.values.items || formState.values.archivedProducts > 0)
        )
    }


    render() {
        const required = value => (value ? undefined : 'requis');
        const invoice = this.props.invoice;
        const customer = invoice ? invoice.customer : null;

        const dueDate = invoice ?
            moment(invoice.due_date).format('YYYY-MM-DD')
            :
            (moment().add(1, 'months')).format('YYYY-MM-DD');

        const {invoiceItems, archivedProducts} = this.initInvoice(this.props.items);
        const formTitle = this.editOperation ? "Modifier la facture" : "Créer une facture"
        const initialValues = this.editOperation ?
            {
                items: invoiceItems,
                id_customer: customer.stripe_customer_id,
                stripe_invoice_id: invoice.stripe_invoice_id,
            }
            :
            null;
        const customerLabelOrSelector = this.editOperation ?
            <Fragment>
                <label>Client</label>
                <input disabled={true}
                       defaultValue={`${customer.first_name} ${customer.last_name}`}
                       className="form-control"/>
            </Fragment>
            :
            <SelectUser isInvoice={true}/>

        return <Fragment>
            <div className="row wrapper border-bottom white-bg page-heading m-b-md">
                <h2>{formTitle}</h2>
            </div>
            <Form
                onSubmit={this.handleSubmit}
                validate={this.validate}
                mutators={{
                    ...arrayMutators
                }}
                initialValues={initialValues}

                render={({handleSubmit, submitting, form, errors}) => {
                    //console.log("errors", errors)
                    return <section className="panel panel-default">
                        <form onSubmit={handleSubmit}>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-sm-6 form-group">
                                        {customerLabelOrSelector}
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <Field
                                            label="Date d'expiration"
                                            name="due_date"
                                            type="date"
                                            required
                                            defaultValue={dueDate}
                                            validate={required}
                                            render={Input}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 form-group">
                                        <Field
                                            label="Description"
                                            name="description"
                                            //required
                                            isArea={true}
                                            defaultValue={invoice ? invoice.description : null}
                                            //validate={required}
                                            render={Input}
                                        />
                                    </div>
                                </div>

                                <TableProduct
                                    required={required}
                                    items={invoiceItems}
                                    archivedProducts={archivedProducts}
                                    displayDiscounts={true}
                                    form={form}
                                    coupons={this.props.coupons}
                                    provisionInitialLine={this.createOperation}
                                />

                                <div className="row">
                                    <div className="col-sm-12 form-group">
                                        <Field
                                            label="Pied de page"
                                            name="footer"
                                            type="text"
                                            //required
                                            isArea={true}
                                            defaultValue={invoice ? invoice.footer : null}
                                            //validate={required}
                                            render={Input}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-end-justified" type="submit">
                                    <button type="submit" className="btn btn-primary"
                                            disabled={submitting}>
                                        {submitting ?
                                            <i className="fas fa-circle-notch fa-spin m-r-sm"/> :
                                            <i className="fas fa-save m-r-sm"/>}
                                        {formTitle}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </section>

                }}
            />
        </Fragment>

    }
}