import React from "react";
import StripeListBillings from "./stripeUtils/StripeListBillings";

class GeneralStripe extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return <StripeListBillings/>
    }
}

export default GeneralStripe;
