import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import ReactTable from "react-table";
import * as api from "../../../../tools/api";
import swal from "sweetalert2";
import PropTypes from "prop-types";
import SelectMultiple from "../../../common/SelectMultiple";
import CreatableSelect from "react-select/lib/Creatable";

const moment = require("moment");

/**
 * props:
 * - data: array of all activities datas
 * - payers: array of all payers
 */
export default class ModalShowInvoices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDesiredInvoicesModal: false,
            swal_credit_notes: {
                show: false,
                billing_objects: []
            }
        };

        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.generateCreditNotes = this.generateCreditNotes.bind(this);
        this.generateCreditNotes = this.generateCreditNotes.bind(this);
    }

    hasBillingLogs(data) {
        return data.some(d => ((d.des || {}).billing_logs || []).length > 0 || (d.billing_logs || []).length > 0);
    }

    render() {
        const data = this.props.data || [];

        if (!this.hasBillingLogs(data)) {
            return <Fragment></Fragment>;
        }

        const billings_payers = {};

        for (const billingLog of data.map(d => (d.des || {}).billing_logs || d.billing_logs).filter(b => b !== undefined).flat(1)) {
            billings_payers[billingLog.payer_id] = billings_payers[billingLog.payer_id] || [];

            billings_payers[billingLog.payer_id].push(billingLog);
        }

        return <Fragment>
            <button
                className="btn btn-danger btn-xs mr-2"
                type="button"
                id="editInvoice"
                onClick={() => this.setState({showDesiredInvoicesModal: true})}>
                <i className="fas fa-plus m-r-xs"/>
                Créer un avoir
            </button>

            <Modal
                isOpen={this.state.showDesiredInvoicesModal}
                onRequestClose={() => this.setState({showDesiredInvoicesModal: false})}
                contentLabel="Factures"
                style={{
                    content: {
                        inset: "auto",
                        minWidth: "50%",
                    }
                }}
                ariaHideApp={false}
            >
                {<div>
                    {
                        Object.keys(billings_payers).filter(payer_id => this.props.payers.find(u => u.id == payer_id)).map(payer_id => {
                            const billings = billings_payers[payer_id];
                            const billings_by_id = {};

                            for (const billing of billings) {
                                billings_by_id[billing.billing_object_id] = billings_by_id[billing.billing_object_id] || [];

                                billings_by_id[billing.billing_object_id].push(billing);
                            }

                            return <div className="row" key={payer_id}>
                                <div className="col-sm-12 m-2 mb-4">
                                    <h4>Factures
                                        pour {this.getFullname(this.props.payers.find(p => p.id == payer_id))}</h4>
                                </div>
                                <div className="d-flex justify-content-center">
                                    {
                                        Object.keys(billings_by_id).map(billing_object_id => {
                                            if (billings_by_id[billing_object_id][0]["is_credit_note?"])
                                                return <Fragment key={billing_object_id}/>

                                            // si la facture a un credit_notes, on ne l'affiche pas
                                            if (this.factureIsTotallyCredited(billings_by_id, billing_object_id))
                                                return <Fragment key={billing_object_id}/>

                                            const credit_notes = this.getCreditNotesForInvoice(billings_by_id, billing_object_id);

                                            return <div className="col-sm-6 d-flex justify-content-center" key={billing_object_id}>
                                                <div className={`card`}
                                                     style={{minWidth: "200px", maxWidth: "250px"}}>
                                                    <div className="card-header">
                                                        <h5 className="card-title">Date: {moment(billings_by_id[billing_object_id][0].issue_date).format("DD/MM/YYYY")}</h5>
                                                    </div>

                                                    <div className="card-body">
                                                        {
                                                            billing_object_id == "null" ? <p>
                                                                    <i className="fas fa-exclamation-triangle"/> Facture non
                                                                    générée
                                                                </p>
                                                                : <p>
                                                                    {billings_by_id[billing_object_id][0]["is_credit_note?"] ? "Avoir" : "Facture"} de {billings_by_id[billing_object_id].map(b => b.unit_price * b.prorata).reduce((a, b) => a + b, 0).toFixed(2)}€
                                                                </p>
                                                        }

                                                    </div>
                                                    <button className="card-footer text-center btn"
                                                            style={{backgroundColor: "#ED5565", color: "white"}}
                                                            onClick={() => this.showSwalCreditNotes(billings_by_id[billing_object_id], billings_payers)}
                                                    >
                                                        <i className="fas fa-file mr-1"/> Générer un avoir
                                                    </button>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                }

            </Modal>

            <Modal
                isOpen={this.state.swal_credit_notes.show}
                onRequestClose={() => this.setState({swal_credit_notes: {show: false, billing_objects: []}})}
                contentLabel="Factures"
                style={{
                    content: {
                        inset: "auto",
                        minWidth: "50%",
                    }
                }}
                ariaHideApp={false}>
                <div className="row">
                    <div className="col-sm-12">
                        <h4>Générer un avoir</h4>
                    </div>

                    <div className="col-sm-12" style={{minHeight: "250px"}}>
                        <label>Sélectionner les lignes à générer</label>
                        <CreatableSelect
                            isMulti
                            value={this.state.swal_credit_notes.selected_items}
                            onChange={selected_items => this.setState({
                                swal_credit_notes: {
                                    ...this.state.swal_credit_notes,
                                    selected_items
                                }
                            })}
                            options={this.state.swal_credit_notes.billing_objects.filter((b) => this.showCreditNote(b, billings_payers)).map(b => {
                                return {
                                    value: b.id,
                                    label: this.props.data.find(d => b.product_type === "adh" ? d.id === 0 : d.id == b.product_id).activity
                                }
                            })}
                        />

                        <div className="w-100 mt-3">
                            <label className="mt-2">Montant total de l'avoir à générer :</label>
                            <p>{(this.state.swal_credit_notes.selected_items || []).map(s => this.state.swal_credit_notes.billing_objects.find(b => b.id === s.value).unit_price * this.state.swal_credit_notes.billing_objects.find(b => b.id === s.value).prorata).reduce((a, b) => a + b, 0).toFixed(2)}€</p>
                        </div>
                    </div>

                    <div className="col-sm-12 mt-3 text-right">
                        <button className="btn btn-primary"
                                onClick={() => this.generateCreditNotes(this.state.swal_credit_notes.selected_items.map(s => s.value))}>Générer
                        </button>
                    </div>
                </div>
            </Modal>

        </Fragment>
    }

    factureIsTotallyCredited(billings_by_id, billing_object_id) {
        const billings = billings_by_id[billing_object_id];

        if (billings.length === 0 || billings[0]["is_credit_note?"])
            return false;

        const facture_amount = billings.reduce((a, b) => a + (b["is_credit_note?"] ? 0 : b.unit_price * b.prorata), 0);
        const credit_notes_amount = Object.values(billings_by_id)
            .flat(1)
            .filter(b => billings.map(bb => bb.billing_object_item_id).includes(b.billing_object_item_id) && b["is_credit_note?"])
            .reduce((a, b) => a + b.unit_price * b.prorata, 0);

        return Math.abs(credit_notes_amount) >= facture_amount;
    }

    showCreditNote(billing_object, billings_payers) {
        if (billing_object["is_credit_note?"] || billing_object.unit_price * billing_object.prorata < 0)
            return false;

        const billings = Object.values(billings_payers);

        const credit_notes = billings
            .flat(1)
            .filter(b => b.billing_object_item_id === billing_object.billing_object_item_id && b["is_credit_note?"]);

        const credit_notes_amount = credit_notes.reduce((a, b) => a + b.unit_price * b.prorata, 0);

        return Math.abs(credit_notes_amount) < billing_object.unit_price * billing_object.prorata;
    }

    getCreditNotesForInvoice(billings_by_id, billing_object_id) {
        const billings = billings_by_id[billing_object_id];

        if (billings.length === 0 || billings[0]["is_credit_note?"])
            return [];

        const credit_notes = Object.values(billings_by_id)
            .flat(1)
            .filter(b => billings.map(b => b.billing_object_item_id).includes(b.billing_object_item_id) && b["is_credit_note?"]);

        const credit_notes_by_id = {};

        for (const creditNote of credit_notes) {
            credit_notes_by_id[creditNote.billing_object_id] = credit_notes_by_id[creditNote.billing_object_id] || [];

            credit_notes_by_id[creditNote.billing_object_id].push(creditNote);
        }

        return credit_notes_by_id;
    }

    showSwalCreditNotes(billing_objects, billing_payers) {
        //show a swal with multiple select to choose which credit notes to generate
        this.setState({
            swal_credit_notes: {
                show: true,
                billing_objects: billing_objects,
                selected_items: billing_objects
                    .filter(b => this.showCreditNote(b, billing_payers))
                    .map(b => ({
                        value: b.id,
                        label: this.props.data.find(d => b.product_type === "adh" ? d.id === 0 : d.id == b.product_id).activity
                    }))
            }
        });
    }

    downloadInvoice(billing_object_id, is_credit_note) {
        swal.showLoading();

        api.set()
            .success((data) => {
                if (data.url) {
                    swal.close();
                    window.location = data.url;
                }
            })
            .error((error) => {
                swal({
                    title: "Erreur",
                    text: "Une erreur s'est produite lors de la création de la facture",
                    type: "error"
                });
            })
            .get(`/api/student_payments/stripe/${is_credit_note ? 'credit_note' : 'invoice'}/${billing_object_id}`, {});
    }

    generateCreditNotes(billing_log_ids) {
        swal.showLoading();

        api.set()
            .success((data) => {
                swal({
                    title: "Avoir généré",
                    text: "L'avoir a été généré",
                    type: "success"
                }).then(() => window.location.reload());
            })
            .error((error) => {
                swal({
                    title: "Erreur",
                    text: "Une erreur s'est produite lors de la création de l'avoir",
                    type: "error"
                });
            })
            .post(`/api/student_payments/credit_note`, {billing_log_ids});
    }

    getFullname(user) {
        return user.first_name + ' ' + user.last_name
    }


}

ModalShowInvoices.propTypes = {
    data: PropTypes.array.isRequired,
    payers: PropTypes.array.isRequired
}