import React from "react";

import BaseParameters from "../../../parameters/BaseParameters";
import StripeProducts from "../stripeProducts/StripeProductList";
import StripeQuotes from '../stripeQuotes/StripeQuoteList';
import StripeInvoices from "../stripeInvoices/StripeInvoiceList.js";
import StripeCreditNotesList from "../stripeCreditNotes/StripeCreditNotesList";

class StripeListBillings extends BaseParameters {
    constructor(props) {
        super(props);

        this.state.tabsNames = ['Produits','Devis', 'Factures', 'Avoirs'];

        this.state.divObjects = [
            <StripeProducts urlListData = "/stripe_products/list"/>,
            <StripeQuotes   urlListData = "/stripe_quotes/list"/>,
            <StripeInvoices urlListData = "/stripe_invoices/list"/>,
            <StripeCreditNotesList urlListData = "/stripe_credit_notes/list"/>,
        ];
    }
}
export default StripeListBillings;