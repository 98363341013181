import DataService from "../../common/baseDataTable/DataService";
import * as api from "../../../tools/api";

export default class TaxRatesDataService extends DataService
{
    constructor(urlRootData) {
        super();

        this.urlRootData = urlRootData;
    }

    deleteData(item) {
        return new Promise((resolve, reject) => {
            api.set()
                .success(resolve)
                .error(reject)
                .del(`${this.urlRootData}/${item.id}`, item);
        });
    }

}