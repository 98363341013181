import React, {Fragment} from "react";
import {csrfToken} from "../../utils";
import swal from "sweetalert2";
import { Form, Field, FormSpy } from "react-final-form";
import * as api from "../../../tools/api.js";
import Input from "../../common/Input";
import InputSelect from "../../common/InputSelect";
import {required} from "../../../tools/validators";
import {checkVAT, countries} from 'jsvat/lib/commonjs';

export default class InvoiceHeaderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            legalEntity: this.props.legalEntity,
            siretRna: this.props.siretRna,
            address: this.props.address,
            telephone: this.props.telephone,
            email: this.props.email,
            tax_exempt: this.props.tax_exempt,
            tax_id: this.props.tax_id
        }
        this.taxExemptOptions = [
            {label: "Imposable", value: "none"},
            {label: "Exonéré", value: "exempt"},
            {label:"Autoliquidation de la TVA", value: "reverse"},
        ]
    }

    componentDidUpdate(prevProps) {
        if (prevProps.legalEntity !== this.props.legalEntity) {
            this.setState({legalEntity: this.props.legalEntity})
        }
        if (prevProps.siretRna !== this.props.siretRna) {
            this.setState({siretRna: this.props.siretRna})
        }
        if (prevProps.address !== this.props.address) {
            this.setState({address: this.props.address})
        }
        if (prevProps.telephone !== this.props.telephone) {
            this.setState({telephone: this.props.telephone})
        }
        if (prevProps.email !== this.props.email) {
            this.setState({email: this.props.email})
        }
        if (prevProps.tax_exempt !== this.props.tax_exempt) {
            this.setState({tax_exempt: this.props.tax_exempt})
        }
        if (prevProps.tax_id !== this.props.tax_id) {
            this.setState({tax_id: this.props.tax_id})
        }
    }

    render() {
        return <div className="ibox">
            <div className="ibox-title">
                <h3 style={{width: "100%"}}>
                    Coordonnées de facturation
                </h3>
            </div>

            <div className="ibox-content">
                <Form
                    onSubmit={this.onSubmit.bind(this)}
                    validate={this.onValidate.bind(this)}
                    initialValues={this.state}

                    render={({
                                 submitError,
                                 handleSubmit,
                                 form,
                                 submitting,
                                 pristine,
                                 values
                             }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field
                                        label="Nom de l'entité à facturer"
                                        name="legalEntity"
                                        type="text"
                                        validate={required}
                                        render={Input}
                                        required
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Field
                                        label="Numéro SIRET ou RNA"
                                        name="siretRna"
                                        type="text"
                                        validate={required}
                                        render={Input}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <Field
                                        label="Email"
                                        name="email"
                                        type="text"
                                        validate={required}
                                        render={Input}
                                        required
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Field
                                        label="Entité soumise à la TVA"
                                        name="tax_exempt"
                                        type="select"
                                        validate={required}
                                        render={InputSelect}
                                        disabled={true}
                                        options={this.taxExemptOptions}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field
                                        label="Adresse"
                                        name="address.line1"
                                        type="text"
                                        validate={required}
                                        render={Input}
                                        required
                                    />
                                </div>
                                <div className="col-sm-6">
                                    {values.tax_exempt == "none" ?
                                        <Field
                                            label="Numéro de TVA"
                                            name="tax_id.value"
                                            type="text"
                                            placeholder="FRAB123456789"
                                            disabled={values.tax_exempt != 'none'}
                                            render={Input}
                                        />
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field
                                        label=""
                                        name="address.line2"
                                        type="text"
                                        render={Input}
                                    />
                                </div>
                                <div className="col-sm-6"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field
                                        label="Code postal"
                                        name="address.postal_code"
                                        type="text"
                                        validate={required}
                                        render={Input}
                                        required
                                    />
                                </div>
                                <div className="col-sm-6"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field
                                        label="Ville"
                                        name="address.city"
                                        type="text"
                                        validate={required}
                                        render={Input}
                                        required
                                    />
                                </div>
                                <div className="col-sm-6"></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Field
                                        label="Pays"
                                        name="address.country"
                                        type="select"
                                        validate={required}
                                        render={InputSelect}
                                        options={this.props.countries}
                                        required
                                    />
                                </div>
                                <div className="col-sm-6"></div>
                            </div>

                            <FormSpy subscription={{ values: true }}>
                                {({ values }) => {
                                    if (values.address && values.address.country) {
                                        if (values.address.country === "FR") {
                                            form.change("tax_exempt", "none");
                                        } else {
                                            form.change("tax_exempt", "reverse");
                                        }
                                    }
                                    return null;
                                }}
                            </FormSpy>


                            <div>
                                <div className="text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary mt-4"
                                    >
                                        {this.props.submitCaption} &nbsp;
                                        {
                                            submitting ?
                                                <i className="fas fa-circle-notch fa-spin"></i>
                                                :
                                                ""
                                        }
                                    </button>
                                    <br/>
                                    <i>(Elvis fait appel à Stripe pour simplifier les paiements et la facturation)</i>
                                </div>
                            </div>
                        </form>

                    )}
                />


            </div>


        </div>
    }

    onValidate(values) {
        const errors = {}
        if (values.tax_exempt === 'none') {
            if (!values.tax_id || !values.tax_id.value) {
                // On ne rend plus obligatoire la saisie du numéro fiscal
                // errors.tax_id = {}
                // errors.tax_id.value = 'Valeur requise';
            } else {
                //console.log("onvalidate ; checkvat = ", checkVAT(values.tax_id.value, countries));
                if (checkVAT(values.tax_id.value, countries).isValid !== true) {
                    errors.tax_id = {}
                    errors.tax_id.value = 'Format invalide';
                }
            }
        }
        return errors
    }

    async onSubmit(values) {
        await api
            .set()
            .success((res) => {
                this.props.onSaveInvoiceHeader({
                    legalEntity: values.legalEntity,
                    siretRna: values.siretRna,
                    address: values.address,
                    telephone: values.telephone,
                    email: values.email,
                    tax_exempt: values.tax_exempt,
                    tax_id: {
                        id: res.tax_id.id,
                        value: res.tax_id.value
                    }
                });
            })
            .error((msg) => {
                console.error(msg);
                swal({
                    type: "error",
                    title: "Une erreur est survenue"
                })
            })
            .post('/elvis_subscription_management/save_invoice_header', {
                "legalEntity": values.legalEntity,
                "siretRna": values.siretRna,
                "address": values.address,
                "telephone": values.telephone,
                "email": values.email,
                "tax_exempt": values.tax_exempt,
                "tax_id": {
                    id: this.state.tax_id ? this.state.tax_id.id : null,
                    value: values.tax_id ? values.tax_id.value : null
                }
            });
    }


    navigateToPortal(newWindow) {
        fetch("/elvis_subscription_management/get_portal_link", {
            method: "GET",
            headers: {
                "X-CSRF-Token": csrfToken
            }
        }).then(response => response.json()).then(json => {
            if (newWindow)
                window.open(json.url);
            else
                window.location = json.url
        });
    }

}