import React, {Fragment} from 'react';
import {csrfToken} from "../../utils";
import swal from "sweetalert2";
import {delay} from "lodash";
import * as api from "./../../../tools/api";
import BaseDataTable from "../../common/baseDataTable/BaseDataTable";
import DataService from "../../common/baseDataTable/DataService";
import DefaultCreateButton from "../../common/baseDataTable/DefaultCreateButton";
import ModalFormTaxRate from "./ModalFormTaxRate";
import TaxRatesActionButtons from "./TaxRatesActionButtons";
import Select from "react-select";
import TaxRatesDataService from "./TaxRatesDataService";

function CreateButton({onCreate}) {
    return (
        <DefaultCreateButton
            label={"Créer un taux de taxe"}
            onCreate={onCreate}
        />
    );
}

export default class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            has_stripe_key: false,
            has_account_id: false,
            has_school_configured: false,
            stripe_key: "",
            business_type: 0,
            base_tva: "",
            tva: "",
            street: "",
            postalCode: "",
            city: "",
            countryCode: "FR",
            base_student_payment_activated: false,
            base_invoices_activated: false,
            student_payment_activated: false,
            invoices_activated: false,
            activities_subject_to_vat: false,
            vat_rate: null,
            rates: [],
            showTaxRateParameters: false
        };

        this.registerStripeKey = this.registerStripeKey.bind(this);
        this.createAccount = this.createAccount.bind(this);
        this.accountLink = this.accountLink.bind(this);
        this.liveChangeTva = this.liveChangeTva.bind(this);
        this.editSchoolParams = this.editSchoolParams.bind(this);
        this.changeModules = this.changeModules.bind(this);
        this.changeVatRate = this.changeVatRate.bind(this);
    }

    componentDidMount() {
        swal.showLoading();

        fetch('/api/student_payments/stripe_state', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "X-Csrf-Token": csrfToken
            }
        })
            .then(response => response.json())
            .then(data => {
                swal.close();

                this.setState({
                    has_stripe_key: data.stripe_state.has_api_key,
                    has_account_id: data.stripe_state.has_account_id,
                    has_school_configured: data.stripe_state.has_school_configured,
                    tva: data.stripe_state.tva,
                    base_tva: data.stripe_state.tva,
                    activities_subject_to_vat: data.school_activities_subject_to_vat,
                    vat_rate: data.vat_rate,
                    rates: data.rates_array
                });
            });

        api.set()
            .success(data => {
                this.setState({
                    student_payment_activated: data.student_payment_activated,
                    invoices_activated: data.invoices_activated,
                    base_student_payment_activated: data.student_payment_activated,
                    base_invoices_activated: data.invoices_activated
                })
            })
            .error(data => {
                swal({
                    title: "Erreur",
                    text: "Une erreur est survenue lors de la récupération des paramètres",
                    type: "error"
                })
            })
            .get("/api/student_payment/parameters/modules_activation_state", {});
    }

    registerStripeKey(e) {
        e.preventDefault();

        fetch('/api/student_payments/stripe_key', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-Csrf-Token": csrfToken
            },
            body: JSON.stringify({
                key: this.state.stripe_key
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.setState({
                        has_stripe_key: true
                    });
                } else {
                    swal({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de l'enregistrement de la clé API Stripe",
                        type: "error"
                    });
                }
            });
    }

    createAccount(e) {
        e.preventDefault();

        swal({
            title: "Création du compte",
            text: "Êtes-vous sûr de vouloir créer un compte Stripe ?",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "Oui",
            cancelButtonText: "Non",
            type: "warning",
            showLoaderOnConfirm: true,
            preConfirm: () => new Promise((resolve, reject) => {
                fetch('/api/student_payments/stripe_account', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        "X-Csrf-Token": csrfToken
                    },
                    body: JSON.stringify({
                        additional_info: {
                            business_type: this.state.business_type,
                            tva: this.state.tva
                        }
                    })
                }).then(response => response.json())
                    .then(data => resolve(data))
                    .catch(error => reject(error));
            })
        })
            .then(res => {
                const data = res.value;

                if (data.error_type === 0) {
                    swal({
                        title: "Création réussie",
                        text: "Votre compte Stripe a été créé avec succès mais certaines informations sont manquantes. Veuillez compléter votre compte en cliquant sur le bouton ci-dessous.",
                        type: "success",
                        showConfirmButton: true,
                        confirmButtonText: "Compléter mon compte",
                        showLoaderOnConfirm: true,
                        preConfirm: () => new Promise((resolve, reject) => {
                            this.accountLink();
                        })
                    });

                    this.setState({
                        has_account_id: true
                    });
                } else {
                    swal({
                        title: "Erreur",
                        text: data.message || "Une erreur est survenue lors de la création du compte Stripe",
                        type: "error"
                    });
                }
            })
    }

    accountLink() {
        if (!swal.isVisible())
            swal.showLoading();

        fetch(`/api/student_payments/stripe_account_link?return_url=${window.location.href}&refresh_url=${window.location.href}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "X-Csrf-Token": csrfToken
            }
        }).then(response => response.json())
            .then(data => {
                if (data.success) {
                    window.location = data.url
                }
            });
    }

    liveChangeTva() {
        fetch('/api/student_payments/stripe_tva', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-Csrf-Token": csrfToken
            },
            body: JSON.stringify({
                tva: this.state.tva
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    swal({
                        title: "Modification réussie",
                        text: "La TVA a été modifiée avec succès",
                        type: "success"
                    });
                    this.setState({base_tva: this.state.tva});
                } else {
                    swal({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de la modification de la TVA",
                        type: "error"
                    });
                }
            });
    }

    changeVatRate() {
        fetch('/stripe_tax_rates/save_tax_rate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-Csrf-Token": csrfToken
            },
            body: JSON.stringify({
                tva: this.state.vat_rate
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    swal({
                        title: "Modification réussie",
                        text: "La TVA a été modifiée avec succès",
                        type: "success"
                    });
                } else {
                    swal({
                        title: "Erreur",
                        text: "Une erreur est survenue lors de la modification de la TVA",
                        type: "error"
                    });
                }
            });
    }

    editSchoolParams(e) {
        if (this.state.city && this.state.postalCode && this.state.street && this.state.countryCode) {
            fetch('/parameters/stripe_account', {
                method: 'POST',
                credentials: "same-origin",
                headers: {
                    'Content-Type': 'application/json',
                    "X-Csrf-Token": csrfToken
                },
                body: JSON.stringify({
                    street: this.state.street,
                    postalCode: this.state.postalCode,
                    city: this.state.city,
                    countryCode: this.state.countryCode
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.setState({has_school_configured: true});
                        swal({
                            title: "Modification réussie",
                            text: "Enregistré",
                            type: "success"
                        });
                    } else {
                        swal({
                            title: "Erreur",
                            text: "Une erreur est survenue lors de l'enregistrement",
                            type: "error"
                        });
                    }
                });
        }
    }

    changeModules() {
        api.set()
            .success(data => {
                // pour recharger les menus
                window.location.reload();
            })
            .error(data => {
                swal({
                    title: "Erreur",
                    text: "Une erreur est survenue lors de la mise à jours des paramètres",
                    type: "error"
                })
            })
            .post("/api/student_payment/parameters/modules_activation_state", {
                student_payment_activated: this.state.student_payment_activated,
                invoices_activated: this.state.invoices_activated
            });
    }


    render() {
        const columns = [
            {
                id: "description",
                Header: "Taux de taxe",
                accessor: "description",
            },
            {
                id: "tax_rate",
                Header: "Taux de taxe (%)",
                accessor: "percentage",
            },
            {
                id: "country",
                Header: "Pays",
                accessor: "country",
            },
            {
                id: "inclusive",
                Header: "Inclusif",
                accessor: "inclusive",
                Cell: ({value}) => value ? "TTC" : "Hors taxe"
            },
            {
                id: "active",
                Header: "Actif",
                accessor: "active",
                Cell: ({value}) => value ? "Oui" : "Non"
            }
        ];

        const dataService = new DataService("/stripe_tax_rates");
        const TaxRateDataService = new TaxRatesDataService("/stripe_tax_rates");
        const onDeleteStripeTaxRate = (item) => {
            swal.fire({
                title: "Voulez-vous archiver ce taux de taxe ?",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Archiver",
                cancelButtonText: "Annuler",
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    TaxRateDataService.deleteData(item).then(() => {
                        swal.fire(
                            item.actionLabel.charAt(0).toUpperCase() + item.actionLabel.slice(1) + " !",
                            "Ce taux de taxe a bien été " + item.actionLabel + ".",
                        );
                    });
                } else if (result.dismiss === swal.DismissReason.cancel) {
                    swal.fire(
                        "Annulé",
                        "Ce taux de taxe n'a pas été archivé.",
                        "error"
                    );
                }
            });
        };

        if (!this.state.has_school_configured) {
            return <div>

                <div className="alert alert-warning">
                    <p>Avant toute chose, vous devez compléter les informations de votre site :</p>
                </div>


                <div className="col-lg-6 p-0">
                    <h3 className="m-b-md" style={{color: "#00334A"}}>Coordonnées : </h3>
                    <div className="mt-5 mb-5">
                        <label>Numéro et nom de voie * :</label>
                        <input type="text" name="street" className="form-control"
                               onChange={(e) => this.setState({street: e.target.value})}/>
                    </div>

                    <div className="mt-5 mb-5">
                        <label> Code Postal * :</label>
                        <input type="text" name="postalCode" className="form-control"
                               onChange={(e) => this.setState({postalCode: e.target.value})}/>
                    </div>

                    <div className="mt-5 mb-5">
                        <label>Ville * :</label>
                        <input className="form-control" type="text" name="city"
                               onChange={(e) => this.setState({city: e.target.value})}/>
                    </div>

                    <div className="mt-5 mb-5">
                        <label>Pays * :</label>
                        <select className="form-control" name="countryCode" defaultValue={"FR"}
                                onChange={(e) => this.setState({countryCode: e.target.value})}
                        >
                            {this.props.countries.map(country =>
                                <option value={country[1]} key={country[1]}>{country[0]}</option>
                            )}
                        </select>
                    </div>

                    <div className="text-right">
                        <button type="button" onClick={this.editSchoolParams}
                                className="btn btn-primary">Enregistrer
                        </button>
                    </div>
                </div>

            </div>
        } else if (!this.state.has_stripe_key) {
            return <form onSubmit={this.registerStripeKey}>
                <div className="pl-2">
                    <div className="alert alert-warning">
                        <p>Il n'y a pas de clé API Stripe enregistrée. Veuillez en ajouter une.</p>
                    </div>

                    <div className="col-lg-6 p-0">
                        <label>Clé API Stripe</label>
                        <input required={true} type="text" className="form-control" value={this.state.stripe_key}/>
                        <div className="text-right mt-5">
                            <input type="submit" className="btn btn-primary" value="Enregistrer"/>
                        </div>
                    </div>


                </div>
            </form>
        } else if (!this.state.has_account_id) {
            return <form onSubmit={this.createAccount}>
                <div className="pl-2">
                    <div className="alert alert-warning">
                        <p>Pour activer le plugin, vous allez devoir créer un compte Stripe :</p>
                    </div>

                    <div className="col-lg-6 p-0">
                        <div className="mb-5">
                            <label className="w-100">Sélectionner un type d'organisation :</label>
                            <select className="custom-select mb-3" value={this.state.business_type}
                                    onChange={(e) => this.setState({business_type: e.target.value})}>
                                <option value={0}>Entreprise</option>
                                <option value={1}>Indépendant</option>
                                <option value={2}>Association</option>
                            </select>
                            <br/>

                            <div>
                                <label className="w-100">Entrez votre numéro de TVA :</label>
                                <input type="text" className="form-control" pattern={/^FR[0-9]{11}$/i.source}
                                       value={this.state.tva} onChange={(e) => this.setState({tva: e.target.value})}/>
                            </div>
                        </div>
                        <div className="mt-5 mb-5 text-right">
                            <input type="submit" className="btn btn-primary"
                                   value="Créer un compte avec les informations de l'école sur Stripe"/>
                        </div>
                    </div>
                </div>
            </form>
        } else if (this.state.showTaxRateParameters) {
            return <div className="mt-3">
                {this.state.invoices_activated && (
                    <Fragment>
                        <hr/>
                        <div>
                            <BaseDataTable
                                title={"Taux de taxes"}
                                dataService={dataService}
                                columns={columns}
                                showFullScreenButton={false}
                                defaultSorted={[{id: "description", asc: true}]}
                                createButton={CreateButton}
                                formContentComponent={ModalFormTaxRate}
                                oneResourceTypeName="un taux de taxe"
                                thisResourceTypeName="ce taux de taxe"
                                actionButtons={(props) => <TaxRatesActionButtons {...props}
                                                                                 onDelete={onDeleteStripeTaxRate}/>}
                            />
                        </div>
                        <div className="mt-3">
                            <button onClick={() => this.setState({showTaxRateParameters: false}) }
                                    className="btn"
                                    style={{
                                        color: "#006fb0",
                                        borderColor: "#006fb0",
                                    }}>
                                Retour
                            </button>
                        </div>
                    </Fragment>
                )
                }

            </div>

        } else {

            return <div className="pl-2">
                <div className="mb-5">
                    <p className="h5 mt-5 mb-5">Les paramètres de paiement sont correctement configurés</p>
                    <button type="button" className="btn btn-primary" onClick={this.accountLink}>Modifier mon compte
                        stripe
                    </button>
                    <br/>
                    <small>Vous pouvez également accéder à votre dashboard depuis <a
                        href="https://dashboard.stripe.com/login">stripe</a></small>
                </div>


                <div className="mb-5" style={{marginTop: "50px"}}>
                    <h3 className="mt-5 mb-4" style={{color: "#00334A"}}>Activation des modules</h3>
                    <div className="d-flex align-items-center">
                        <label className="col-lg-4 p-0 my-auto">Activer les paiements par carte bancaire pour les élèves
                            :</label>
                        <input type="checkbox" className="form-control col-1"
                               checked={this.state.student_payment_activated}
                               onChange={(e) => this.setState({student_payment_activated: e.target.checked})}/>
                    </div>
                    <div className="d-flex align-items-center">
                        <label className="col-lg-4 p-0 my-auto">Activer les factures :</label>
                        <input type="checkbox" className="form-control col-1" checked={this.state.invoices_activated}
                               onChange={(e) => this.setState({invoices_activated: e.target.checked})}/>
                    </div>
                    {(this.state.student_payment_activated !== this.state.base_student_payment_activated || this.state.invoices_activated !== this.state.base_invoices_activated) &&
                        <button type="button" className="btn btn-primary mt-3" onClick={this.changeModules}>
                            Enregistrer l'activation des modules
                        </button>}
                </div>

                <div className="mb-5" style={{marginTop: "70px"}}>
                    {this.state.invoices_activated && <div className="d-flex mb-3 justify-content-between" style={{maxWidth: "650px"}}>
                        <h3 className="my-auto p-0" style={{color: "#00334A"}}>Taux de taxe</h3>
                        <button
                            onClick={() => this.setState({showTaxRateParameters: true})}
                            className="btn btn-sm ml-5 mt-3"
                            style={{
                                color: "#006fb0",
                                borderColor: "#006fb0",
                            }}
                        >
                            <i className="fa fa-cog mr-1"/>
                            Paramétrer les taux de taxes
                        </button>
                    </div>}
                    <div>
                        <div style={{maxWidth: "650px"}}>
                            <label className="w-100">Votre numéro de TVA :</label>
                            <input type="text" className="form-control" pattern={/^FR[0-9]{11}$/i.source}
                                   value={this.state.tva}
                                   onChange={(e) => this.setState({tva: e.target.value})}/>
                            {this.state.tva !== this.state.base_tva && (this.state.tva || "").length > 0 && /^FR[0-9]{11}$/i.test(this.state.tva) &&
                                <button type="button" className="btn btn-primary mt-3"
                                        onClick={this.liveChangeTva}>Enregistrer
                                    le numero de TVA</button>}
                        </div>

                        {this.state.activities_subject_to_vat && <div className="mt-2" style={{maxWidth: "650px"}}>
                            <label htmlFor={"VatRate"}>Taux de taxe applicable aux activités musicales (TTC)</label>

                            <select value={this.state.vat_rate}
                                    onChange={(e) => this.setState({vat_rate: e.target.value})}
                                    className="form-control">
                                {(this.state.rates || []).map((rate, index) => {
                                    return <option key={index}
                                                   value={rate.stripe_tax_rate_id}>{rate.percentage} %</option>
                                })}
                            </select>
                            <div className="row text-right">
                                <button type="button" className="btn btn-primary mt-5 mr-4"
                                        onClick={this.changeVatRate}>Enregistrer le
                                    taux de TVA
                                </button>
                            </div>

                        </div>
                        }
                    </div>

                </div>


            </div>
        }
    }


}