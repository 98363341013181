import React, {Fragment} from "react";
import swal from "sweetalert2";

import {csrfToken} from "./../../../utils";
import {makeDebounce} from "./../../../../tools/inputs";
import ReactTableFullScreen from "./../../../ReactTableFullScreen";
import Select from "react-select";

import ModalChangeStatus from "../stripeUtils/ModalChangeStatus";

import * as api from "./../../../../tools/api";

const moment = require("moment");
require("moment/locale/fr");

const debounce = makeDebounce();

class StripeInvoiceList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pages: null,
            sorted: '',
            loading: true,
            filter: {},
            customerList: [{label: 'Chargement', value: -1}],
            showModal: {value: false},
            loadCsv: false,
            loadPDF: {invoiceId: null, loading: false},
        };

        this.fetchData = this.fetchData.bind(this);
        this.selectUserList()
    }

    requestData = (pageSize, page, sorted, filtered, format) => {
        return fetch(`${this.props.urlListData}${format ? "." + format : ""}`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                pageSize,
                page,
                sorted: sorted[0],
                filtered,
            }),
        });
    };

    fetchData(state, instance) {

        this.setState({loading: true, filter: state});
        debounce(() => {
            this.requestData(
                state.pageSize,
                state.page,
                state.sorted,
                state.filtered
            )
                .then(response => response.json())
                .then(data => {
                    const res = {

                        data: data.invoices,
                        pages: data.pages,
                        total: data.total,
                    };

                    return res;
                })
                .then(res => {
                    this.setState({
                        ...res,
                        loading: false,
                    });
                });
        }, 400);
    }

    async selectUserList() {
        const data = await fetch(`/users/simple_list.json`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        const value = await data.json()
        let options = []
        value.users.forEach(user => {
            options.push({'label': user.first_name + " " + user.last_name, 'value': user.id})
        })
        this.setState({customerList: options})
    }

    downloadExport() {
        this.requestData(
            this.state.filter.pageSize,
            this.state.filter.page,
            this.state.filter.sorted,
            this.state.filter.filtered,
            "csv"
        )
            .then(res => res.blob())
            .then(file => {
                const download = document.createElement("a");
                download.download = `Facture_${moment().format(
                    "DD_MM_YYYY"
                )}.csv`;
                download.href = URL.createObjectURL(file);
                document.body.appendChild(download);
                download.click();
                document.body.removeChild(download);
                this.setState({loadCsv: false})
            });
    }

    linkShowOrEdit(id, text, status, number = false) {
        const clasName = (number ? "text-dark" : "w-100 d-flex text-dark")
        return <a href={'/stripe_invoices/' + id} className={clasName}> {text}</a>
    }


    deleteInvoice(id) {

        swal({
            title: "La facture sera supprimée définitivement.",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "annuler",
            confirmButtonText: 'supprimer'
        }).then(res => {
            if (res.value) {
                api
                    .set()
                    .success(() => {
                        swal({
                            type: "success",
                            title: "Facture supprimée"
                        });
                        this.fetchData(this.state.filter)

                    })
                    .error((msg) => {
                        swal({
                            type: "error",
                            title: msg
                        })

                    })
                    .del(`/stripe_invoices/${id}`);

            }
        })

    }

    downloadPDF(id) {
        api
            .set()
            .success((msg) => {
                const download = document.createElement("a");
                download.download = `Facture.pdf`;
                download.href = msg.link;
                document.body.appendChild(download);
                download.click();
                document.body.removeChild(download);
                this.setState({loadPDF: {invoiceId: null, loading: false}})
            })
            .error((msg) => {
                swal({
                    type: "error",
                    title: "Une erreur est survenue",
                    text: msg.message
                })

            })
            .post(`/stripe_invoices/${id}/download_pdf`);
    }

    render() {
        const {data, pages, loading, customerList} = this.state;
        const required = value => (value ? undefined : 'requis')

        const status = [
            {value: 'draft', label: "Brouillon"},
            {value: 'open', label: "Finalisée"},
            {value: 'void', label: "Annulée"},
            {value: 'paid', label: "Payée"},
            {value: 'uncollectible', label: "Non recouvrable"},
        ]

        const columns = [
            {
                id: "number",
                Header: "Numéro de la facture",
                accessor: d => this.linkShowOrEdit(d.stripe_invoice_id, d.number, d.status),
                sortable: false,
                filterable: false,
            },
            {
                id: "created_at",
                Header: "Créé le",
                accessor: d => {
                    let dateFormat = new Date(d.created_at)
                    const date = dateFormat.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
                    return this.linkShowOrEdit(d.stripe_invoice_id, date, d.status)
                },
                filterable: false,
            },
            {
                id: "due_date",
                Header: "Date d'échéance",
                accessor: d => {
                    if (d.due_date === null)
                        return this.linkShowOrEdit(d.stripe_invoice_id, "NON PRÉCISÉ", d.status)

                    let dateFormat = new Date(d.due_date)
                    const date = dateFormat.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
                    return this.linkShowOrEdit(d.stripe_invoice_id, date, d.status)
                },
                filterable: false,
            },
            {
                id: "status",
                Header: "Statut",
                accessor: d => {
                    const s = status.find(s => s.value === d.status).label

                    return this.linkShowOrEdit(d.stripe_invoice_id, s, d.status)
                },
                filterable: false,
            },
            {
                id: "customer",
                Header: "Client",
                accessor: d => {
                    const full_name = d.customer.first_name + " " + d.customer.last_name
                    return this.linkShowOrEdit(d.stripe_invoice_id, full_name, d.status)
                },
                filterable: false,
            },
            {
                id: "Description",
                Header: "Description",
                accessor: d => this.linkShowOrEdit(d.stripe_invoice_id, d.description, d.status),
                filterable: false,
            },
            {
                id: "amount_due",
                Header: "Montant (TTC)",
                accessor: d => {
                    const amount_total = d.amount_due
                    let price = new Intl.NumberFormat('fr-FR', {
                        style: 'currency',
                        currency: 'EUR'
                    }).format(amount_total)

                    return (
                        <div
                            style={{textAlign: "right"}}>{this.linkShowOrEdit(d.stripe_invoice_id, price, d.status, true)}</div>)

                },
                filterable: false,
            },
            {
                id: "actions",
                Header: "Actions",
                Cell: props => {

                    let displayAction = []
                    if (props.original.status === "draft") {
                        displayAction.push(<a
                            id={`edit_${props.original.stripe_invoice_id}`}
                            key={`edit_${props.original.stripe_invoice_id}`}
                            name={`edit_${props.original.stripe_invoice_id}`}
                            className="btn-sm btn-primary m-r-sm"
                            data-tippy-content="Modifier la facture"
                            href={'/stripe_invoices/' + props.original.stripe_invoice_id + "/edit"}><i
                            className="fas fa-edit"/></a>)
                        displayAction.push(<a
                            id={`delete_${props.original.stripe_invoice_id}`}
                            key={`delete_${props.original.stripe_invoice_id}`}
                            name={`delete_${props.original.stripe_invoice_id}`}
                            className="btn-sm btn-danger m-r-sm"
                            data-tippy-content="Supprimer la facture"
                            onClick={() => this.deleteInvoice(props.original.stripe_invoice_id)}><i
                            className="fas fa-trash"/></a>)
                    }

                    if (props.original.status === "open" || props.original.status === "uncollectible")
                        displayAction.push(<ModalChangeStatus
                            id={`changeStatus_${props.original.stripe_invoice_id}`}
                            key={`changeStatus_${props.original.stripe_invoice_id}`}
                            invoiceId={props.original.stripe_invoice_id}
                            currentStatus={props.original.status}/>)

                    if (props.original.status !== "draft")
                        displayAction.push(
                            <a
                                id={`pdf_${props.original.stripe_invoice_id}`}
                                key={`pdf_${props.original.stripe_invoice_id}`}
                                name={`pdf_${props.original.stripe_invoice_id}`}
                                className="btn-sm btn-primary m-r-sm"
                                data-tippy-content="Télécharger le pdf"
                                disabled={this.state.loadPDF.loading} onClick={() => {
                                this.setState({loadPDF: {invoiceId: props.original.stripe_invoice_id, loading: true}})
                                this.downloadPDF(props.original.stripe_invoice_id)
                            }}>
                                {this.state.loadPDF.loading && this.state.loadPDF.invoiceId === props.original.stripe_invoice_id ?
                                    <i className="fas fa-circle-notch fa-spin"/> :
                                    <i className="fas fa-file-download"/>}
                            </a>
                        )

                    return (
                        <div className="text-center">
                            <div className="btn-wrapper">
                                {displayAction}
                            </div>
                        </div>)
                },
                sortable: false,
                filterable: false,
            }
        ];

        const events = [];

        let infoTotalProduct = ""
        if (this.state.total !== undefined)
            infoTotalProduct = <div className="flex flex-center-justified m-t-xs">
                <h3>{`${this.state.total} facture${this.state.total > 1 ? "s" : ""} au total`}</h3></div>

        return <Fragment>
            <div className="row mb-4 mt-3">
                <div className="col-sm-3 form-group">
                    <button data-tippy-content="Mettre le tableau en plein écran"
                            className="btn btn-primary m-r"
                            onClick={() => events[0]()}>
                        <i className="fas fa-expand-arrows-alt"></i>
                    </button>
                    <button
                        className="btn btn-primary m-r"
                        disabled={this.state.loadCsv}
                        onClick={() => {
                            this.setState({loadCsv: true})
                            this.downloadExport()
                        }}
                    >
                        {this.state.loadCsv ? <i className="fas fa-circle-notch fa-spin m-r-sm"/> :
                            <i className="fas fa-upload m-r-sm"/>}
                        Exporter en CSV
                    </button>
                </div>
                <div className="btn-group pull-right">
                    <a href={'/stripe_invoices/new'} className="btn btn-primary m-r"
                       data-tippy-content="Créer une facture">Créer une facture
                    </a>
                </div>
            </div>
            <div className="row mb-4 mt-3">
                <div className="col-sm-3 form-group">
                    <Select
                        onChange={event => {
                            if (event == null) {
                                let tmp = this.state.filter
                                tmp.filtered.splice(tmp.filtered.findIndex(({id}) => id === 'customer'), 1)
                                this.setState({"filter": tmp})
                                this.fetchData(this.state.filter)
                                return
                            }

                            let tmp = this.state.filter
                            if (tmp.filtered !== undefined && tmp.filtered.find(({id}) => id === 'customer') !== undefined) {
                                for (var i = 0; i < tmp.filtered.length; i++) {
                                    if (tmp.filtered[i].id === "customer")
                                        tmp.filtered[i].value = event.value
                                }
                            } else
                                tmp.filtered.push({id: 'customer', value: event.value})

                            this.setState({"filter": tmp})
                            this.fetchData(this.state.filter)
                        }}
                        isClearable={true}
                        placeholder='Sélectionner un client'

                        options={customerList}
                    />
                </div>
                <div className="col-sm-2 form-group">
                    <Select
                        onChange={event => {
                            if (event == null) {
                                let tmp = this.state.filter
                                tmp.filtered.splice(tmp.filtered.findIndex(({id}) => id === 'status'), 1)
                                this.setState({"filter": tmp})
                                this.fetchData(this.state.filter)
                                return
                            }

                            let tmp = this.state.filter
                            if (tmp.filtered !== undefined && tmp.filtered.find(({id}) => id === 'status') !== undefined) {
                                for (var i = 0; i < tmp.filtered.length; i++) {
                                    if (tmp.filtered[i].id === "status")
                                        tmp.filtered[i].value = event.value
                                }
                            } else
                                tmp.filtered.push({id: 'status', value: event.value})

                            this.setState({"filter": tmp})
                            this.fetchData(this.state.filter)
                        }}
                        isClearable={true}
                        placeholder='Sélectionner un statut'

                        options={status}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <ReactTableFullScreen
                        tableName={"invoices"}
                        events={events}
                        id="invoiceTable"
                        data={data}
                        manual
                        pages={pages}
                        loading={loading}
                        onFetchData={this.fetchData}
                        defaultSorted={[{id: "created_at", desc: true}]}
                        columns={columns}
                        filterable
                        defaultFilterMethod={(filter, row) => {
                            if (row[filter.id] != null) {
                                return row[filter.id]
                                    .toLowerCase()
                                    .startsWith(filter.value.toLowerCase());
                            }
                        }}
                        resizable={false}
                        previousText="Précédent"
                        nextText="Suivant"
                        loadingText="Chargement..."
                        noDataText="Aucune facture"
                        pageText="Page"
                        ofText="sur"
                        rowsText="résultats"
                        minRows={1}
                    />
                    {infoTotalProduct}
                </div>
            </div>

        </Fragment>

    }
}


export default StripeInvoiceList;
