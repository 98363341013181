import React, {Component} from "react";
import fetch from "isomorphic-unfetch";
import DolibarrOption from "./DolibarrOption";
import {csrfToken} from "../../../utils"
import swal from "sweetalert2";
import ReactTable from "react-table";

//Gets products from dolibarr
const dolibarrPromise = fetch("/dolibarr/get_products/")

export default class DolibarrLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dolibarrProducts: undefined,
            dolibarrProductsParsed: undefined,
        }
        this.fetchData = this.fetchData.bind(this);
        this.parseDolibarr = this.parseDolibarr.bind(this);
        this.handleLinkChange = this.handleLinkChange.bind(this);
    }

    async fetchData() {
        fetch("/dolibarr/get_products/")
            .then((response) => {
                if (!response.ok) {
                    return undefined
                } else {
                    return response.json()
                }
            }).then((data) => {
                this.setState({dolibarrProducts:data});
                this.parseDolibarr(data);
            });
    }

    parseDolibarr(dolibarrProducts) {
        var dolibarrProductsParsed = [];
        for (let i = 0; i < dolibarrProducts.length; i++) {
            dolibarrProductsParsed.push({
                id: parseInt(dolibarrProducts[i].id),
                label: dolibarrProducts[i].label
            });
        }
        this.setState({dolibarrProductsParsed:dolibarrProductsParsed})
    }

    handleLinkChange(link) {

        fetch("/dolibarr/links_update", {
            method: "PATCH",
            headers: {
                "X-Csrf-Token": csrfToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({link}),
        }).then(res => {
                if (res.ok) {
                    swal({
                        type: 'success',
                        title: '<span style="font-size:2em">Association enregistrée</span>',
                        position: 'top',
                        toast: true,
                        showConfirmButton: false,
                        showCloseButton: false,
                        timer: 1500,
                      })

                    //Add saved element to props to display it without fetching data again
                    let i = this.props.activityRefDolibarrLink.findIndex((element) => element.activity_ref_pricing_id == link.activity_ref_pricing_id);

                    if (i != -1) {
                        this.props.activityRefDolibarrLink[i].dolibarr_id = link.dolibarr_id;
                    } else {
                        this.props.activityRefDolibarrLink.push(link);
                    }

                } else {
                    swal({
                        type: 'error',
                        title: "Une erreur est survenue, l'enregistrement n'a pas pu s'effectuer",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
        })
    }

    componentDidMount() {
        this.fetchData();
    }


    render() {

        let data = this.props.activityRefDolibarrLink;
        
        const columns = [
            {
                id: "from_season",
                Header: "De la saison",
                accessor: d => d.from_season_label.replace("Saison ", ""),
                width: 150,
            },
            {
                id: "to_season",
                Header: "À la saison",
                accessor: d => d.from_season_label.replace("Saison ", ""),
                width: 150,
            },
            {
                id: "activity_ref",
                Header: "Activité",
                accessor: d => d.activity_ref_label,
                width: 350,
            },
            {
                id: "pricing_category",
                Header: "Tarif",
                accessor: d => d.pricing_category_label,
                width: 350,
            },
            {
                id: "dolibarr",
                Header: "Dolibarr",
                filterable: false,
                style: {overflow:"visible"},
                sortable:false,
                Cell: props => {
                    return (
                        <DolibarrOption
                            key={props.original.activity_ref_pricing_id}
                            dolibarrProducts={this.state.dolibarrProductsParsed}
                            activityRefPricingId={props.original.activity_ref_pricing_id}
                            dolibarrId={props.original.dolibarr_id}
                            onLinkChange={this.handleLinkChange}
                        />
                    )
                }
            }
        ]

        if(this.state.dolibarrProductsParsed && data) {
            return (
                <div className='box'>
                    <h1>
                        Correspondances des tarifs avec Dolibarr
                    </h1>
                    <ReactTable
                        resizable={false}
                        ofText="sur"
                        rowsText="résultats"
                        loadingText="Chargement..."
                        previousText="Précédent"
                        nextText="Suivant"
                        columns={columns}
                        data={data}
                        defaultSorted={[{id: "activity_label", asc: true}]}
                        filterable
                        defaultFilterMethod={(filter, row) => {
                            if (row[filter.id] != null) {
                                return row[filter.id]
                                    .toLowerCase()
                                    .includes(filter.value.toLowerCase())
                            }
                        }}
                    />
                </div>
            )
        } else {
            return(
                <div>Chargement</div>
            )
        }
    }

}