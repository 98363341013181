import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import ReactTable from "react-table";
import * as api from "../../../../tools/api";
import swal from "sweetalert2";
import PropTypes from "prop-types";

const moment = require("moment");

/**
 * props:
 * - payers: array of payers
 * - tableDatas: base datas to display
 * - generalColumns: array of columns to display
 * - season: current season id
 */
export default class ModalGenerateInvoice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showInvoiceModal: false,
            selectedPayer: this.props.payers.length === 1 ? this.props.payers[0].id : undefined,
            selectedLines: [],
            selectedLinesQuotites: {},
            dueDate: moment().add(1, "month").format("YYYY-MM-DD"),
        };

        this.createInvoice = this.createInvoice.bind(this);
    }

    render() {
        const tableDatas = (this.props.tableDatas || []).filter(d => {
            const billingLogs = (d.des || {}).billing_logs || d.billing_logs || [];
            const total_prorata = billingLogs.reduce((acc, bl) => acc + bl.prorata, 0);

            return total_prorata < 1;
        });

        if(tableDatas.length === 0)
            return null;

        if (this.props.generalColumns != undefined && this.props.generalColumns.length > 0 && this.props.generalColumns[0].id !== "invoice_state") {
            this.props.generalColumns.unshift({
                id: "invoice_state",
                Header: "",
                maxWidth: 30,
                sortable: false,
                filterable: false,
                show: (this.props.tableDatas || []).filter(d => ((d.des || {}).billing_logs || []).length > 0 || (d.billing_logs || []).length > 0).length > 0,
                Cell: props => {
                    const billingLogs = (props.original.des || {}).billing_logs || (props.original.billing_logs || []).filter(b => b.product_id == this.getIdOfLine(props.original))

                    const total_prorata = billingLogs.reduce((acc, bl) => acc + bl.prorata, 0);
                    const notif_data = (() => {
                        if (total_prorata === 0)
                            return {type: "danger", text: "Pas de facture"};
                        else if (total_prorata >= 1)
                            return {type: "success", text: "Facture complète"};
                        else
                            return {type: "warning", text: "Facture partiel"};
                    })();

                    // inspinia-notify cg-notify-message
                    return <div className="w-100 h-100 text-center d-flex align-items-center justify-content-center">
                        <div className={`img-circle bg-${notif_data.type}`}
                             style={{width: "10px", height: "10px", margin: 0}} data-tippy-content={notif_data.text}/>
                    </div>
                }
            });
        }

        return <Fragment>

            <button
                className="btn btn-primary btn-xs mr-2"
                type="button"
                id="editInvoice"
                onClick={() => this.setState({showInvoiceModal: true})}>
                <i className="fas fa-edit m-r-xs"/>
                Editer une facture
            </button>

            <Modal
                isOpen={this.state.showInvoiceModal}
                contentLabel="Editer une nouvelle facture"
                style={{
                    content: {
                        inset: "auto",
                        minWidth: "50%",
                    }
                }}
                ariaHideApp={false}
                onRequestClose={() => this.setState({showInvoiceModal: false})}
            >
                <h2 className="fas fa-times position-absolute"
                    style={{cursor: "pointer", position: "absolute", right: "10px", top: "5px", margin: "0"}}
                    onClick={() => this.setState({showInvoiceModal: false})}/>

                <div className="row m-b-md">
                    <div className="col-sm-12 text-center">
                        {this.state.selectedPayer === undefined ? "Sélectionner un payeur" : `Sélectionner une ou plusieurs lignes à ajouter a la facture pour ${this.getFullname(this.props.payers.find(p => p.id === this.state.selectedPayer))}`}
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        {this.state.selectedPayer === undefined ?
                            <div>
                                <select
                                    className="form-control"
                                    onChange={(e) => this.setState({selectedPayer: parseInt(e.target.value)})}>
                                    <option key={0} value={undefined}>Sélectionner un payeur</option>
                                    {this.props.payers.map(p => <option key={p.id}
                                                                        value={p.id}>{this.getFullname(p)}</option>)}
                                </select>
                            </div>
                            :
                            <Fragment>
                                <ReactTable
                                    data={tableDatas}
                                    columns={[{
                                        Header: <input
                                            type="checkbox"
                                            checked={this.state.selectedLines.length == tableDatas.length}
                                            onChange={(e) => {
                                                if (tableDatas.length === 0)
                                                    return;

                                                const newState = {...this.state};

                                                if (e.target.checked) {
                                                    newState.selectedLines = tableDatas.map(d => ({
                                                        type: this.getTypeOfLine(d),
                                                        id: this.getIdOfLine(d)
                                                    }));
                                                } else
                                                    newState.selectedLines = [];


                                                this.setState(newState);
                                            }}
                                        />,
                                        filterable: false,
                                        sortable: false,
                                        Cell: props => <div className="w-100 h-100 text-center">
                                            <input type="checkbox"
                                                   checked={(this.state.selectedLines || []).find(b => b.type === this.getTypeOfLine(props.original) && b.id === this.getIdOfLine(props.original)) !== undefined}
                                                   onChange={(e) => this.setState({
                                                       selectedLines: e.target.checked ?
                                                           [
                                                               ...this.state.selectedLines,
                                                               {
                                                                   id: this.getIdOfLine(props.original),
                                                                   type: this.getTypeOfLine(props.original)
                                                               }
                                                           ]
                                                           :
                                                           this.state.selectedLines.filter(i =>
                                                               !(
                                                                   i.type === this.getTypeOfLine(props.original)
                                                                   && i.id === this.getIdOfLine(props.original)
                                                               )
                                                           )
                                                   })}/>
                                        </div>
                                    }, {
                                        Header: "Quotité (%)",
                                        filterable: false,
                                        sortable: false,
                                        Cell: props => {

                                            const billingLogs = (props.original.des || {}).billing_logs || (props.original.billing_logs || []).filter(b => b.product_id == this.getIdOfLine(props.original));
                                            const total_prorata = billingLogs.reduce((acc, bl) => acc + bl.prorata, 0);
                                            const total_prorata_percent = total_prorata * 100;

                                            const max = 100 - total_prorata_percent;

                                            const formatKey = d => `${ this.getIdOfLine(d)}:${this.getTypeOfLine(d)}`;

                                            this.state.selectedLinesQuotites[formatKey(props.original)] = this.state.selectedLinesQuotites[formatKey(props.original)] || max;

                                            return <input
                                                type="number"
                                                className="form-control"
                                                min={0}
                                                max={max}
                                                defaultValue={this.state.selectedLinesQuotites[formatKey(props.original)]}
                                                onChange={(e) => {
                                                    let value = parseInt(e.target.value);

                                                    if (value > max)
                                                        value = max;
                                                    else if (value < 0)
                                                        value = 0;

                                                    this.state.selectedLinesQuotites[formatKey(props.original)] = value;
                                                }}
                                            />
                                        }
                                    }, ...this.props.generalColumns.map(col => ({
                                        ...col,
                                        Footer: undefined
                                    })).filter(col => col.Header != "Tarif" && col.Header != "Prorata")]}
                                    defaultSorted={[{id: "activity", desc: false}]}
                                    resizable={false}
                                    previousText="Précedent"
                                    nextText="Suivant"
                                    loadingText="Chargement..."
                                    noDataText="Aucune donnée"
                                    pageText="Page"
                                    ofText="sur"
                                    rowsText="résultats"
                                    minRows={1}
                                    showPagination={false}
                                    className="-striped whitebg"
                                />

                                <label>Date d'échéance</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={this.state.dueDate}
                                    onChange={(e) => this.setState({dueDate: e.target.value})}
                                />
                            </Fragment>}
                    </div>
                </div>

                {this.state.selectedPayer !== undefined && <div className="row mt-3">
                    <div className="col-sm-6">
                        <button className="btn btn-danger"
                                onClick={() => this.setState({selectedPayer: undefined, selectedLines: []})}>Changer de
                            payeur
                        </button>
                    </div>

                    {this.state.selectedLines.length > 0 && <div className="col-sm-6 text-right">
                        <button className="btn btn-primary" onClick={() => this.createInvoice()}>Créer facture</button>
                    </div>}
                </div>}
            </Modal>
        </Fragment>
    }

    getTypeOfLine(line)
    {
        if(line.des)
            return "des_act";

        if(line.adhesionId)
            return "adh";

        if(line.packId)
            return "pack";

        return "unknown"
    }

    getIdOfLine(line) {
        switch (this.getTypeOfLine(line)) {
            case "des_act":
                return line.des.id;
            case "adh":
                return line.adhesionId;
            case "pack":
                return line.packId;
            default:
                return 0;
        }
    }

    getFullname(user) {
        return user.first_name + ' ' + user.last_name
    }

    createInvoice() {
        swal.showLoading();

        api.set()
            .success((data) => {
                swal({
                    title: "Facture créée",
                    text: "La facture a été créée avec succès",
                    type: "success"
                }).then(() => window.location.reload());
            })
            .error((error) => {
                console.error(error)
                swal({
                    title: "Erreur",
                    text: "Une erreur s'est produite lors de la création de la facture",
                    type: "error"
                });
            })
            .post(`/api/student_payments/invoice`, {
                payer_id: this.state.selectedPayer,
                lines: this.state.selectedLines,
                quotites: this.state.selectedLinesQuotites,
                season_id: this.props.season,
                due_date: this.state.dueDate,
            });
    }
}

ModalGenerateInvoice.propTypes = {
    season: PropTypes.number.isRequired,
    payers: PropTypes.array.isRequired,
    generalColumns: PropTypes.array.isRequired,
    tableDatas: PropTypes.array.isRequired,
}