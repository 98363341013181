import React, {Component} from "react";
import fetch from "isomorphic-unfetch";
import {csrfToken} from "../../../utils"
import swal from "sweetalert2";
import Select from "react-select";


export default class DolibarrLink extends Component {

    constructor(props) {
        super(props);
        this.handleProposalLinks = this.handleProposalLinks.bind(this);
        this.handleNewLink = this.handleNewLink.bind(this);
        this.handleNewProposalLink = this.handleNewProposalLink.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSeasonChange = this.handleSeasonChange.bind(this);
        this.state = {
            databaseId: "",
            dolibarrId: "",
            proposalId: "",
            userBaseId: "",
            seasons: [],
            season: undefined,
        };
    }

    componentDidMount() {
        fetch("/seasons",
            {
                method: "GET",
                credentials: "same-origin",
                cache: "no-store",
                headers: {
                    "X-CSRF-Token": csrfToken,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then((res) => {
                if (res.ok) {
                    res.json().then(data => {
                        data.sort((a, b) => b.id - a.id);
                        this.setState({seasons: data})
                    })
                } else {
                    swal({
                        type: 'error',
                        title: "Erreur lors de la récupération des saisons",
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
        })
    }

    handleSeasonChange(value) {
        this.setState({season: value})
    }

    handleProposalLinks() {

        swal({
            title: "Enregistrement",
            text: "Associer depuis la table des devis ?",
            type: "question",
            showCancelButton: true,
            cancelButtonText: "Annuler",
            showLoaderOnConfirm: true,
            preConfirm: () => new Promise((resolve, reject) =>
            {
                fetch("/dolibarr/update_user_links", {
                    method: "PATCH",
                    headers: {
                        "X-Csrf-Token": csrfToken,
                        "Content-Type": "application/json",
                    }
                }).then(res => {
                    if (res.ok)
                        resolve();
                    else
                        reject()
                })
            })
        }).then(v => {
            if (v.value) {
                swal({
                    type: 'success',
                    title: 'Enregistrement effectué',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
            else
                swal({
                    type: 'error',
                    title: 'Une erreur est survenue',
                })
        })
    }

    getUser(userId) {
        let user = fetch("/dolibarr/show_user/"+userId,
            {
                method: "GET",
                headers: {
                    "X-CSRF-Token": csrfToken,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then((response) => {
            if (!response.ok) {
                return undefined
            } else {
                return response.json()
            }
        }).then((data) => {return data;});

        return user;
    }

    getThirdparty(thirdpartyId) {
        let thirdparty = fetch("/dolibarr/get_tier_by_id/"+thirdpartyId/*this.state.dolibarrId*/,
            {
                method: "GET",
                credentials: "same-origin",
                cache: "no-store",
                headers: {
                    "X-CSRF-Token": csrfToken,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then((response) => {
            if (!response.ok) {
                return undefined
            } else {
                return response.json()
            }
        }).then((data) => {return data;});

        return thirdparty
    }

    async getProposal() {
        let thirdparty = fetch("/dolibarr/get_devis_by_id/"+this.state.proposalId,
            {
                method: "GET",
                credentials: "same-origin",
                cache: "no-store",
                headers: {
                    "X-CSRF-Token": csrfToken,
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }).then((response) => {
            if (!response.ok) {
                return undefined
            } else {
                return response.json()
            }
        }).then(async(data) => {
            let steptwo = await this.getThirdparty(data.socid)
            return steptwo
        });
        return thirdparty
    }

    async handleNewProposalLink() {
        swal.showLoading()

        let user = await this.getUser(this.state.userBaseId)
        let thirdparty = await this.getProposal()
        let season = this.state.season
        let seasonId = this.state.season.id
        let proposalId = this.state.proposalId

        if (user == undefined && thirdparty == undefined) {
            swal({
                type: 'error',
                title: 'Les deux ids sont introuvables',
            })
        } else if (user == undefined) {
            swal({
                type: 'error',
                title: "L'id de l'utilisateur est introuvable dans la base",
            })
        } else if (thirdparty == undefined) {
            swal({
                type: 'error',
                title: "Le devis ou son tiers sont introuvables dans Dolibarr",
            })
        } else {
            let newProposal = {payer_id: user.id, season_id: seasonId, dolibarr_proposal_id: proposalId}

            swal({
                title: "Enregistrement",
                text: "Souhaitez vous associer le devis du tiers Dolibarr " + thirdparty.name + " à l'utilisateur " + user.last_name + " " + user.first_name + " pour la saison " + season.label +" ?",
                type: "question",
                showCancelButton: true,
                cancelButtonText: "Annuler",
                showLoaderOnConfirm: true,
                preConfirm: () => new Promise((resolve, reject) =>
                {
                    fetch("/dolibarr/create_manual_proposal", {
                        method: "POST",
                        headers: {
                            "X-Csrf-Token": csrfToken,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({newProposal}),
                    }).then(res => {
                        if (res.ok)
                            resolve();
                        else
                            swal({
                                type: 'error',
                                title: 'Une erreur est survenue',
                            })
                        reject()
                    })
                })
            }).then(v => {
                if (v.value) {
                    swal({
                        type: 'success',
                        title: 'Enregistrement effectué',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })

        }


    }

    async handleNewLink() {
        swal.showLoading()

        let user = await this.getUser(this.state.databaseId)
        let thirdparty = await this.getThirdparty(this.state.dolibarrId)

        if (user == undefined && thirdparty == undefined) {
            swal({
                type: 'error',
                title: 'Les deux ids sont introuvables',
            })
        } else if (user == undefined) {
            swal({
                type: 'error',
                title: "L'id de l'utilisateur est introuvable dans la base",
            })
        } else if (thirdparty == undefined) {
            swal({
                type: 'error',
                title: "L'id du tiers dolibarr est introuvable",
            })
        } else {
            let association = {user_id: user.id, socid: thirdparty.id}
            swal({
                title: "Enregistrement",
                text: "Souhaitez vous associer l'utilisateur " + user.last_name + " " + user.first_name + " au tiers Dolibarr " + thirdparty.name + " ?",
                type: "question",
                showCancelButton: true,
                cancelButtonText: "Annuler",
                showLoaderOnConfirm: true,
                preConfirm: () => new Promise((resolve, reject) =>
                {
                    fetch("/dolibarr/new_user_link_manual", {
                        method: "POST",
                        headers: {
                            "X-Csrf-Token": csrfToken,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({association}),
                    }).then(res => {
                        if (res.ok)
                            resolve();
                        else
                            swal({
                                type: 'error',
                                title: 'Une erreur est survenue',
                            })
                            reject()
                    })
                })
            }).then(v => {
                if (v.value) {
                    swal({
                        type: 'success',
                        title: 'Enregistrement effectué',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
        }
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value})
    }

    render() {
        return (
            <div>
                <div className="row wrapper border-bottom white-bg page-heading m-b-lg">
                    <h2>Correspondances des utilisateurs avec Dolibarr
                    </h2>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-content no-padding">
                                <div className="container-fluid">
                                    <div className='row m-t m-b p-l p-r'></div>
                                    <div className="col-lg-4">
                                        <h3 className="pull-left">Association manuelle de tiers</h3>
                                    </div>
                                    <div className="col-lg-4">
                                        <input
                                            placeholder="Id Utilisateur Base"
                                            type="text"
                                            value={this.state.databaseId}
                                            className="pull-left"
                                            name="databaseId"
                                            onChange={this.handleChange}
                                        />
                                        <input
                                            placeholder="Id Tiers Dolibarr"
                                            type="text"
                                            value={this.state.dolibarrId}
                                            className="pull-right"
                                            name="dolibarrId"
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <input
                                            disabled=""
                                            type="submit"
                                            value="Enregistrer"
                                            className="btn btn-primary pull-right m-b"
                                            onClick={this.handleNewLink}
                                        />
                                    </div>
                                    <div className='col-lg-12 m-t m-b'></div>
                                    <div className="col-lg-12">
                                        <p>
                                            Permet d'associer un utilisateur Elvis et un tiers Dolibarr avec leurs ids respectifs.
                                        </p>
                                    </div>
                                    <div className='row m-t m-b p-l p-r'></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-content no-padding">
                                <div className="container-fluid">
                                    <div className='row m-t m-b p-l p-r'></div>
                                    <div className="col-lg-4">
                                        <h3 className="pull-left">Association manuelle de devis</h3>
                                    </div>
                                    <div className="col-lg-6 d-flex justify-content-between">
                                        <input
                                            placeholder="Id Utilisateur Base"
                                            type="text"
                                            value={this.state.userBaseId}
                                            name="userBaseId"
                                            onChange={this.handleChange}
                                        />
                                        <input
                                            placeholder="Id Devis Dolibarr"
                                            type="text"
                                            value={this.state.proposalId}
                                            name="proposalId"
                                            onChange={this.handleChange}
                                        />
                                        <div style={{width: "33%"}}>
                                            <Select
                                                id={"seasonSelect"}
                                                options={this.state.seasons}
                                                onChange={this.handleSeasonChange}
                                                defaultValue={this.state.defaultSeason}
                                                getOptionValue={(option) => option.id}
                                                placeholder={"Saison"}
                                                value={this.state.season}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <input
                                            disabled=""
                                            type="submit"
                                            value="Enregistrer"
                                            className="btn btn-primary pull-right m-b"
                                            onClick={this.handleNewProposalLink}
                                        />
                                    </div>
                                    <div className='col-lg-12 m-t m-b'></div>
                                    <div className="col-lg-12">
                                        <p>
                                            Permet d'associer un devis Dolibarr à un utilsateur Elvis (via leurs ids) sur une saison donnée.
                                        </p>
                                    </div>
                                    <div className='row m-t m-b p-l p-r'></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-content no-padding">
                                <div className="container-fluid">
                                    <div className='row m-t m-b p-l p-r'></div>
                                    <div className="col-lg-6">
                                        <h3 className="pull-left">
                                            Associer les utilisateurs via leurs devis depuis la table dolibarr_proposals
                                        </h3>
                                    </div>
                                    <div className="col-lg-6">
                                        <input
                                            disabled=""
                                            type="submit"
                                            value="Associer depuis les devis"
                                            className="btn btn-danger pull-right m-b"
                                            onClick={this.handleProposalLinks}
                                        />
                                    </div>
                                    <div className='col-lg-12 m-t m-b'></div>
                                    <div className="col-lg-12">
                                        <p>Récupère tous les devis Dolibarr enregistrés dans Elvis, puis associe les utilisateurs Elvis aux tiers Dolibarr en fonction des devis.</p>
                                        <p>Exemple : plusieurs tiers et devis ont été créés mais le lien ne s'établit que pour le devis : cette fonctionnalité permettra de créer les liens vers les tiers.</p>
                                        <p><b>Cette fonctionnalité ne doit pas être utilisé pour gérer du cas par cas. Pour un nombre limité d'associations, privilégier l'utilisation de la fonction "Association manuelle de tiers".</b></p>
                                    </div>
                                    <div className='row m-t m-b p-l p-r'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }
}