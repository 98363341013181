import _ from "lodash";
import React from "react";
import {csrfToken, findAndGet, optionMapper} from "../../../utils";
import ReactTableFullScreen from "../../../ReactTableFullScreen";
import ExtendedSubPaymentList from "./ExtendedSubPaymentList";
import MessageModal from "../../../generalPayments/MessageModal";
import BulkEditModal from "../../../generalPayments/BulkEditModal";
import DuePaymentsList from "../../../generalPayments/DuePaymentList";


const NB_DISPLAYED_RECIPIENTS = 3;
const MESSAGE_MODAL_ID = "messagesModal";
const BULK_MODAL_ID = "bulkEditModal";

class ExtendedDuePaymentList extends DuePaymentsList {
    constructor(props) {
        super(props);
    }

    render() {

        const { data, pages, loading } = this.state;


        const totalRecipients = _.chain(this.state.data)
            .filter(
                d =>
                    this.state.validityMap[d.id] === "N" &&
                    (this.state.targets === "all" ||
                        this.state.targets.includes(d.id))
            )
            .map(d => _.get(d, "payment_schedule.user"))
            .compact()
            .uniqBy(u => u.id)
            .value();

        let recipientsToDisplay = totalRecipients.slice(
            0,
            NB_DISPLAYED_RECIPIENTS
        );

        let recipients = recipientsToDisplay
            .map(u => `${u.first_name} ${u.last_name}`)
            .join(", ");

        const restCount = Math.max(
            0,
            this.state.targets === "all"
                ? this.state.total - NB_DISPLAYED_RECIPIENTS
                : totalRecipients.length - NB_DISPLAYED_RECIPIENTS
        );
        if (restCount) recipients += `, et ${restCount} autres`;

        const filteredSeasonId =
            findAndGet(
                this.state.filter.filtered,
                f => f.id === "season_id",
                "value"
            ) || "";

        const events = []

        return (
            <div>
                <div
                    className="flex flex-space-between-justified flex-start-aligned reglement-table-header m-b-sm"
                    style={{ width: "100%" }}
                >
                    <div className="flex flex-center-aligned">
                        <h2 className="m-r">
                            {this.state.rowsCount} échéances
                        </h2>
                        <button
                            className="btn btn-primary m-r-sm"
                            data-tippy-content="Recharger la liste"
                            onClick={() => this.fetchData(this.state.filter)}
                        >
                            <i className="fas fa-sync" />
                        </button>
                        <button
                            data-tippy-content="Réinitialiser les filtres"
                            className="btn btn-primary m-r-sm"
                            onClick={() => this.resetFilters()}
                        >
                            <i className="fas fa-times"></i>
                        </button>

                        <button
                            data-tippy-content="Mettre le tableau en plein écran"
                            className="btn btn-primary m-r"
                            onClick={() => events[0]()}
                        >
                            <i className="fas fa-expand-arrows-alt"></i>
                        </button>

                        <select
                            style={{ width: "auto" }}
                            onChange={e =>
                                this.handleChangeSeason(e.target.value)
                            }
                            value={filteredSeasonId}
                            className="form-control m-r"
                        >
                            <option value="">SAISON</option>
                            {this.props.seasons.map(optionMapper())}
                        </select>

                        <button
                            className="btn btn-primary"
                            data-tippy-content={'Exporter en CSV ' + (this.state.targets.length > 0 ? `les ${this.state.targets === "all" ? this.state.rowsCount : this.state.targets.length} lignes sélectionnées)` : "tous les paiements visibles")}
                            onClick={() => {this.onCsvExport()}}
                            disabled={this.state.csv_export_loading}
                        >
                            {this.state.csv_export_loading ? <i className="fas fa-circle-notch fa-spin"/> : <i className="fas fa-upload"/>}
                        </button>
                    </div>

                    <div className="ibox-title-right">
                        <span>
                            Total échéances:{" "}
                            {new Intl.NumberFormat("fr-FR", {
                                style: "currency",
                                currency: "EUR",
                            }).format(this.state.totalAmount)}
                        </span>

                        <span>
                            Total paiements:{" "}
                            {new Intl.NumberFormat("fr-FR", {
                                style: "currency",
                                currency: "EUR",
                            }).format(this.state.totalPaidAmount)}
                        </span>
                    </div>
                </div>
                {this.state.targets.length > 0 ? this.targetsAlert() : null}

                <div className="ibox-content no-padding">
                    <ReactTableFullScreen
                        events={events}
                        data={data}
                        manual
                        pages={pages}
                        loading={loading}
                        columns={this.state.columns}
                        pageSizeOptions={[10, 12, 15, 20, 50, 100]}
                        page={
                            this.state.filter.page <= this.state.pages
                                ? this.state.filter.page
                                : this.state.pages - 1
                        }
                        pageSize={this.state.filter.pageSize}
                        sorted={this.state.filter.sorted}
                        filtered={this.state.filter.filtered}
                        onPageChange={page =>
                            this.fetchData({ ...this.state.filter, page })
                        }
                        onPageSizeChange={(pageSize, page) =>
                            this.fetchData({
                                ...this.state.filter,
                                page,
                                pageSize,
                            })
                        }
                        onSortedChange={sorted =>
                            this.fetchData({ ...this.state.filter, sorted })
                        }
                        onFilteredChange={filtered =>
                            this.fetchData({
                                ...this.state.filter,
                                filtered,
                            })
                        }
                        filterable
                        resizable={false}
                        previousText="Précédent"
                        nextText="Suivant"
                        loadingText="Chargement..."
                        noDataText="Aucune donnée"
                        pageText="Page"
                        ofText="sur"
                        rowsText="résultats"
                        minRows={10}
                        SubComponent={row => {
                            if (row.original.payments.length > 0) {
                                return (
                                    <ExtendedSubPaymentList
                                        data={row.original.payments}
                                        original={row.original}
                                        paymentMethods={
                                            this.props.paymentMethods
                                        }
                                        locations={this.props.locations}
                                        minYear={this.props.minYear}
                                        maxYear={this.props.maxYear}
                                        statuses={this.props.paymentStatuses}
                                        isPaymentActivated={this.props.isPaymentActivated}
                                        isInvoicesActivated={this.props.isInvoicesActivated}
                                    />
                                );
                            }

                            return null;
                        }}
                    />
                </div>
                <MessageModal
                    id={MESSAGE_MODAL_ID}
                    recipients={recipients}
                    message={this.state.message}
                    onChange={e =>
                        this.setState({
                            message: {
                                ...this.state.message,
                                [e.target.name]: e.target.value,
                            },
                        })
                    }
                    onSend={() => this.sendReminderMail()}
                />
                <BulkEditModal
                    id={BULK_MODAL_ID}
                    onChange={(k, v) =>
                        this.setState({
                            bulkEdit: {
                                ...this.state.bulkEdit,
                                [k]: v,
                            },
                        })
                    }
                    onSubmit={() => this.submitBulkEdit()}
                />
            </div>
        );
    }

}


export default ExtendedDuePaymentList;
