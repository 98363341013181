import React from "react";
import {FieldArray} from "react-final-form-arrays";
import {Field} from "react-final-form";

import inputSelectReact from "./../../../../components/common/InputSelectReact";
import {csrfToken} from "./../../../utils";
import Input from "./../../../../components/common/Input";
import InputSelect from "./../../../../components/common/InputSelect";
import * as StripeHelpers from "../stripeUtils/StripeHelpers"
import {formatStringifiedFloatAsCurrency} from "../stripeUtils/StripeHelpers";

export default class TableProduct extends React.Component {
    cellCount;
    taxList = [];
    taxOptions = [];

    constructor(props) {
        super(props)
        this.cellCount = props.displayDiscounts ? 4 : 3;
        this.state = {
            productOptions: [],
            priceOptions: [],
            couponOptions: this.generateOptionsForCoupon()
        }
        this.fetchTaxes().then(async () => {
                this.taxOptions = this.generateOptionsForTax();
                if (props.items) {
                    await this.fetchActiveProducts();
                    await this.initPriceOptions(props.items);
                }
            }
        )
    }

    componentDidMount() {
        if (this.props.provisionInitialLine)
            this.insertEmptyLine();
    }

    async fetchActiveProducts() {
        const data = await fetch(`/stripe_products/listActiveProducts.json`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })

        const products = await data.json()

        let productOptions = []
        productOptions = products.map(product => {
            const taxRate = this.taxList.find(tax => tax.id === product.tax_rate)
            return {
                'label': product.name,
                'value': product.stripe_product_id,
                'taxRateId': taxRate ? taxRate.id : '',
            }
        })

        this.state.productOptions = productOptions
        this.setState({productOptions: productOptions})
    }

    async fetchTaxes(inclusive = false, active = true) {
        const dataTax = await fetch(`/stripe_tax_rates/listTax.json`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({inclusive: inclusive, active: active})
        })
        const taxList = await dataTax.json()

        this.taxList = taxList.map(tax => ({
            'id': tax.id,
            'display_name': tax.display_name,
            'description': tax.description,
            'country': tax.country,
            'jurisdiction': tax.jurisdiction,
            'effective_percentage': tax.effective_percentage
        }));
    }

    getTaxObject(taxId) {
        if (!taxId) return {};
        return this.taxList.find(item => item.id === taxId);
    }

    setTaxRateForProduct = (productId, index) => {
        const productOption = this.state.productOptions.find(product => product.value === productId);
        this.props.form.change(`items[${index}].taxId`, productOption.taxRateId || '');
        return productOption.taxRateId;
    }

    async fetchProductPrices(productId) {
        if (productId === -1) return {};

        const data = await fetch(`/stripe_prices/${productId}/listActivePrices.json`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        return await data.json();
    }

    async updateProductPrices(productId, index, taxRateId = null, init = false) {
        if (productId === "" || productId === -1) return;

        let res = await this.fetchProductPrices(productId)

        const product = this.state.productOptions.find(product => product.value === productId)

        const taxRateIdToUse = taxRateId || product.taxRateId
        let tax = this.taxList.find(tax => tax.id === taxRateIdToUse)
        if (!tax)
            return


        const prices = res.data.prices
        const default_price_id = res.data.default_price_id
        const options = []

        prices.forEach(price => {
            let unit_amount_excluding_tax =
                price.tax_behavior === "inclusive" ?
                    price.unit_amount_decimal / (1 + tax.percentage / 100.0) :
                    price.unit_amount_decimal;

            let option = {
                'label': (parseFloat(unit_amount_excluding_tax)).toFixed(2) + "€",
                'value': price.stripe_price_id,
                'amount': unit_amount_excluding_tax,

            }

            if (!init && default_price_id === price.stripe_price_id) {
                option['is_default_price'] = true
                options.push(option)
            } else {
                option['is_default_price'] = false
                options.unshift(option)
            }
        })

        if (init)
            return options

        //console.log("will set priceOptions", options, index);
        this.setState(prevState => {
            prevState.priceOptions[index] = options
            return {
                ...prevState,
                priceOptions: [...prevState.priceOptions]
            }
        });
        this.setDefaultPriceForProduct(options, index);
    }

    async handleProductChange(productId, index) {
        //console.log("handleProductChange", productId, index)
        const taxRateId = this.setTaxRateForProduct(productId, index);
        await this.updateProductPrices(productId, index, taxRateId);
    }

    initPriceOptions = async (currentItems) => {
        let tmp = []
        for (let i = 0; i < currentItems.length; i++)
            tmp[i] = await this.updateProductPrices(currentItems[i].stripe_product_id, i, null, true)
        this.setState({priceOptions: tmp})
    }

    removePriceOption = (index) => {
        let options = [...this.state.priceOptions]

        if (options[index] !== undefined) {
            options.splice(index, 1);
            this.setState({priceOptions: options})
        }
    }

    setDefaultPriceForProduct = (options, index) => {
        if (options) {
            const defaultPrice = options.find(option => option.is_default_price === true);
            if (defaultPrice) {
                this.props.form.change(`items[${index}].stripe_price_id`, defaultPrice.value);
            }
        }
    }

    addPriceOptionsForNewItem = () => {
        this.setState(prevState => ({
                    ...prevState,
                    priceOptions: [...prevState.priceOptions, []]
                }
            )
        )
    }

    generateOptionsForCoupon = () => {
        if (!this.props.coupons)
            return [];

        const options = this.props.coupons.map(coupon => ({
            'label': `${coupon.percent_off}% (${coupon.name})`,
            'value': coupon.stripe_coupon_id,
            'percentOff': coupon.percent_off
        }))
        options.unshift({
            'value': "none",
            'label': "(aucune remise)",
            'percentOff': 0
        });
        return options;
    }

    generateOptionsForTax = () => {
        return this.taxList
            .sort((a, b) => a.effective_percentage - b.effective_percentage)
            .map(tax => ({
                'label': `${tax.effective_percentage}% (${tax.description})`,
                'value': tax.id,
            }))
    }

    guessDiscountedAmountExcludingTax = (field, index) => {
        if (!this.state.priceOptions[index]) return null;

        const priceId = field.stripe_price_id
        const quantity = field.quantity || 0

        const couponOption = this.state.couponOptions.find(option => option.value === field.couponId);
        const percentOff = couponOption ? couponOption.percentOff : 0;

        const priceOption = this.state.priceOptions[index].find(element => element.value === priceId)
        if (!priceOption) return null;

        return (priceOption.amount * quantity * (1 - percentOff / 100.0)).toFixed(2)
    }

    guessDiscountedTotalExcludingTax = (fields) => {
        if (!fields.value)
            return 0.00;

        let total = 0.00
        for (let i = 0; i < fields.value.length; i++) {
            total += parseFloat(this.guessDiscountedAmountExcludingTax(fields.value[i], i))
        }
        return total.toFixed(2)
    }

    updateTaxAmounts(taxAmounts, field, index) {
        const taxRate = this.getTaxObject(field.taxId);
        if (!taxRate) return;

        if (!taxAmounts[taxRate.id]) {
            taxAmounts[taxRate.id] = {
                'label': StripeHelpers.formatTaxHeader(taxRate),
                'amount': 0
            };
        }

        const priceOption = this.state.priceOptions[index].find(element => element.value === field.stripe_price_id)
        if (!priceOption) return;

        const discountedAmountExcludingTax = parseFloat(this.guessDiscountedAmountExcludingTax(field, index));

        taxAmounts[taxRate.id].amount += discountedAmountExcludingTax * taxRate.effective_percentage / 100.0 * (field.quantity || 0);
    }

    guessTotalTaxAmounts = (fields) => {
        if (this.state.priceOptions.length === 0) return {};

        // Estimation du montant total de taxes
        // On utilise le taux de défini dans le Field de chaque ligne
        let taxAmounts = {}
        fields.value.forEach((field, index) => {
            this.updateTaxAmounts(taxAmounts, field, index);
        })

        return taxAmounts;
    }

    renderArchivedProducts = (oldProducts) => {
        let archivedProducts = []

        oldProducts.forEach(product => {
            let tax = this.state.taxList.find(tax => tax.id === product.tax_rate)
            let tva = 0
            if (tax !== undefined) {
                tva = tax.percentage / 100.0
                tva = product.price * tva
            }

            archivedProducts.push(
                <tr>
                    <td>{product.stripe_product_name}</td>
                    <td>{parseFloat(product.price).toFixed(2)}€</td>
                    <td>{product.quantity}</td>
                    <td>{parseFloat(product.price * product.quantity).toFixed(2)}€</td>
                    <td>{parseFloat(tva * product.quantity).toFixed(2)}€</td>
                    <td>{parseFloat((parseFloat(product.price) + tva) * product.quantity).toFixed(2)}€</td>
                </tr>
            )
        })
        return archivedProducts
    }

    renderContentRow(title, value, lightHeader = false, lightValue = false) {
        const HeaderTag = lightHeader ? 'p' : 'h4';
        const ValueTag = lightValue ? 'p' : 'h4';

        return (
            <tr key={title}>
                <td colSpan={this.cellCount + 1}>
                    <HeaderTag className="text-right">{title}</HeaderTag>
                </td>
                <td>
                    <ValueTag className="text-right">{value}</ValueTag>
                </td>
            </tr>
        );
    }

    insertEmptyLine() {
        this.props.form.mutators.push(
            "items",
            {
                stripe_product_id: -1,
                stripe_price_id: -1,
                quantity: 1
            });
        this.addPriceOptionsForNewItem();
    }

    render() {
        return (
            <FieldArray name="items">
                {({fields}) => {
                    const discountedTotalExcludingTax = this.guessDiscountedTotalExcludingTax(fields);
                    const taxAmounts = this.guessTotalTaxAmounts(fields);
                    const totalTaxAmount = Object.values(taxAmounts).reduce((acc, taxAmount) => acc + taxAmount.amount, 0);


                    return <div className="row">
                        <div className="col-sm-12">
                            <table className="table">

                                <thead>
                                <tr>
                                    <th>Produit <span className="text-danger">{" *"}</span></th>
                                    <th>Quantité<span className="text-danger">{" *"}</span></th>
                                    <th>Prix unitaire (HT)<span className="text-danger">{" *"}</span></th>
                                    <th>Taux taxe</th>
                                    {this.props.displayDiscounts && <th>Taux remise</th>}
                                    <th>Montant {this.props.displayDiscounts ? "remisé" : null} (HT)</th>
                                    <th>
                                        <button className="btn btn-primary" type="button"
                                                onClick={() => {
                                                    this.insertEmptyLine();
                                                }}> +
                                        </button>
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {(this.props.archivedProducts && this.renderArchivedProducts(this.props.archivedProducts))}

                                {fields.map((name, index) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{width: "30%"}}>
                                                <Field
                                                    id={`stripe_product_id${index}`}
                                                    name={`${name}.stripe_product_id`}
                                                    render={inputSelectReact}
                                                    inline={true}
                                                    noOptionsMessage={'Aucun produit trouvé'}
                                                    options={this.state.productOptions}
                                                    parse={option => {
                                                        const productId = option.value;
                                                        fields.value[index].stripe_price_id = "";
                                                        this.handleProductChange(productId, index);
                                                        return productId;
                                                    }}
                                                    format={(parsedValue) => this.state.productOptions.find((option) => option.value === parsedValue)}
                                                />
                                            </td>
                                            <td style={{width: "10%"}}>
                                                <Field
                                                    id={`${name}.quantity`}
                                                    name={`${name}.quantity`}
                                                    required
                                                    type="number"
                                                    render={Input}
                                                />
                                            </td>
                                            <td style={{width: "20%"}}>
                                                <Field
                                                    id={`stripe_price_id${index}`}
                                                    name={`${name}.stripe_price_id`}
                                                    inline={true}
                                                    prompt={"Sélectionnez un tarif"}
                                                    render={InputSelect}
                                                    options={this.state.priceOptions[index] || []}
                                                />
                                            </td>
                                            <td style={{width: "20%"}}>
                                                <Field
                                                    id={`taxId${index}`}
                                                    name={`${name}.taxId`}
                                                    inline={true}
                                                    prompt={"Sélectionnez un taux de taxe"}
                                                    render={InputSelect}
                                                    options={this.taxOptions || []}
                                                />
                                            </td>
                                            {this.props.displayDiscounts && <td>
                                                <Field
                                                    id={`coupon${index}`}
                                                    name={`${name}.couponId`}
                                                    inline={true}
                                                    render={InputSelect}
                                                    //prompt={"Sélectionner une remise"}
                                                    displayPrompt={false}
                                                    options={this.state.couponOptions || []}
                                                />

                                            </td>}
                                            <td className="text-right">
                                                {formatStringifiedFloatAsCurrency(this.guessDiscountedAmountExcludingTax(fields.value[index], index))}
                                            </td>
                                            <td>
                                                <a className="btn btn-danger" onClick={() => {
                                                    fields.remove(index);
                                                    this.removePriceOption(index)
                                                }}>x</a>
                                            </td>
                                        </tr>

                                    )
                                })}

                                {this.renderContentRow(
                                    "Total HT",
                                    formatStringifiedFloatAsCurrency(discountedTotalExcludingTax)
                                )}

                                {Object.values(taxAmounts).map((taxAmount, index) => (
                                    this.renderContentRow(
                                        taxAmount.label,
                                        formatStringifiedFloatAsCurrency(taxAmount.amount.toFixed(2)), true, true)
                                ))}

                                {this.renderContentRow(
                                    "Total TTC",
                                    formatStringifiedFloatAsCurrency(
                                        (parseFloat(discountedTotalExcludingTax) + totalTaxAmount)
                                            .toFixed(2)
                                    )
                                )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                }
            </FieldArray>
        )

    }

}