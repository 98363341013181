import React, {Fragment, useState} from "react"
import * as api from "./../../../../tools/api"
import {redirectTo} from "./../../../../tools/url"
import Input from "./../../../../components/common/Input"

import swal from "sweetalert2"
import Modal from "react-modal"
import {Field, Form} from "react-final-form"

export default class EditOrganizationModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {isOpen: false}

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleOpenModal = this.handleOpenModal.bind(this)
    }

    onSubmit = (e) => {

        api
            .set()
            .success(() => {
                redirectTo(`/stripe_invoices/new`);
                swal({
                    type: "success",
                    title: "Organisation Modifier"
                });

            })
            .error((msg) => {
                swal({
                    type: "error",
                    title: "Une erreur est survenue",
                    text: msg.message
                })

            })
            .post("/organizations/update_from_user", {organization: {user_id:this.props.userId, organization_tax_id: e.organization_tax_id}});
    }
    validate = (e) => {
        const errors = {}
        if (!(/^FR[0-9]{11}$/.test(e.organization_tax_id)))
            errors.organization_tax_id = "Le numéro de TVA ne peut pas être vide."
        return errors
    }

    handleOpenModal() {
        this.setState({isOpen: true})
    }

    handleCloseModal() {
        this.setState({isOpen: false})
    }

    render() {
        const MODAL_STYLE = {
            content: {
                margin: "auto",
                maxWidth: "600px",
                height: "300px",
                maxHeight: "720px",
            }
        }
        return <Fragment>
            <button className='btn btn-danger' type="button" onClick={this.handleOpenModal}>Modifier les
                données concernant l'organsation du client
            </button>
            <Modal

                ariaHideApp={false}
                onRequestClose={this.handleCloseModal}

                style={MODAL_STYLE}
                isOpen={this.state.isOpen}>
                {
                    <div>
                        <div className="flex flex-space-between-justified">
                            <h2>Ajouter le numéro de TVA</h2>
                        </div>
                        <div className="row m-b-md">
                            <Form
                                onSubmit={this.onSubmit}
                                validate={this.validate}
                                render={({handleSubmit, form: {getState}}) => (
                                    <section>
                                        <form onSubmit={handleSubmit}>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-sm-8 form-group">
                                                        <Field
                                                            label="Numéro de TVA"
                                                            name="organization_tax_id"
                                                            type="text"
                                                            render={Input}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <button className="btn btn-primary" type="button"
                                                        onClick={this.handleCloseModal}>Retour
                                                </button>

                                                <div className="btn-group pull-right">
                                                    <button className="btn btn-primary" type="submit"
                                                            disabled={!getState().valid}>
                                                        Enregistrer
                                                    </button>
                                                </div>

                                            </div>
                                        </form>
                                    </section>
                                )}
                            />
                        </div>
                    </div>
                }
            </Modal>
        </Fragment>
    }
}