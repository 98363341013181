import React, {Fragment, useState} from "react";

import {Field, Form} from "react-final-form";

import Modal from 'react-modal';

import * as api from "./../../../../tools/api";
import {redirectTo} from "./../../../../tools/url";
import swal from "sweetalert2";

const ModalChangeStatus = (props) => {
    const {invoiceId, currentStatus} = props;
    const [isOpen, setIsOpen] = useState(false);

    const onSubmit = async (e) => {
        //console.log("onSubmit", e)
        await api
            .set()
            .success(() => {
                swal({
                    type: "success",
                    title: "Statut modifié"
                });
                redirectTo("/stripe_invoices");

            })
            .error((msg) => {
                swal({
                    type: "error",
                    title: "Une erreur est survenue" + msg
                })

            })
            .post("/stripe_invoices/update_status/" + e.stripe_invoice_id, {status: e.status});
    }
    const validate = (e) => {
        const errors = {};
        if (!e.status)
            errors.status = 'Choisissez le nouveau status'

        return errors;
    }
    const MODAL_STYLE = {
        content: {
            margin: "auto",
            maxWidth: "600px",
            height: "300px",
            maxHeight: "720px",
        }
    };
    return <Fragment>

        <a
            id={`change_status_${invoiceId}`}
            name={`change_status_${invoiceId}`}
            className="btn-sm btn-primary m-r-sm"
            data-tippy-content="Changer le statut"
            type="button"
            onClick={() => setIsOpen(true)}><i
            className="fas fa-list"></i></a>

        <Modal
            id={`modal_change_status_${invoiceId}`}
            ariaHideApp={false}
            style={MODAL_STYLE}
            onRequestClose={() => setIsOpen(false)}
            isOpen={isOpen}>
            <h2 className="fas fa-times position-absolute"
                style={{cursor: "pointer", position: "absolute", right: "10px", top: "5px", margin: "0"}}
                onClick={() => setIsOpen(false)}/>
            <div className="row m-b-md">
                <div className="col-sm-12 text-center">
                    <h3><b>Changer le statut de la facture</b></h3>
                </div>
            </div>

            <div className="row m-b-md">
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    initialValues={{stripe_invoice_id: invoiceId}}
                    render={({handleSubmit, submitting, form: {getState}}) => (
                        <section>
                            <form onSubmit={handleSubmit}>
                                <div className="panel-body">
                                    <label>Marquer la facture comme…</label>
                                    <div id={"mark_as_payed"}>
                                        <label>
                                            <Field
                                                name="status"
                                                component="input"
                                                type="radio"
                                                value="paid"
                                                required
                                            />{' '}
                                            payée
                                        </label>
                                    </div>
                                    <div id={"mark_as_void"}>
                                        <label>
                                            <Field
                                                name="status"
                                                component="input"
                                                type="radio"
                                                value="void"
                                                required
                                            />{' '}
                                            annulée
                                        </label>
                                    </div>
                                    {currentStatus === "uncollectible" ? ""
                                        :
                                        <div id={"mark_as_uncollectible"}>
                                            <label>
                                                <Field
                                                    name="status"
                                                    component="input"
                                                    type="radio"
                                                    value="uncollectible"
                                                    required
                                                />{' '}
                                                non recouvrable
                                            </label>
                                        </div>}
                                    <div className="flex flex-end-justified" type="submit">
                                        <button className="btn btn-primary" type="submit"
                                                disabled={!getState().valid || submitting}>
                                            {submitting ?
                                                <i className="fas fa-circle-notch fa-spin m-r-sm"/> :
                                                <i className="fas fa-save m-r-sm"/>}
                                            Modifier le statut
                                        </button>
                                    </div>
                                </div>

                            </form>

                        </section>
                    )}
                />
            </div>

        </Modal>
    </Fragment>
}
export default ModalChangeStatus;
