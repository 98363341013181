import React, {Fragment} from "react";
import swal from "sweetalert2";
import {csrfToken} from "./../../../utils";
import * as api from "./../../../../tools/api";
import * as StripeHelpers from "../stripeUtils/StripeHelpers";
import {formatTaxHeader, roundFloatWithTwoDecimal} from "../stripeUtils/StripeHelpers";

const moment = require('moment');

export default class StripeInvoiceShow extends React.Component {
    cellCount = 4;

    constructor(props) {
        super(props)
        this.state = {
            load_finalize: false,
        };
    }

    voidCreditNote(stripe_credit_note_id) {

        swal({
            title: "Voulez-vous vraiment annuler l'avoir ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "non",
            confirmButtonText: 'oui'
        }).then(res => {
            if (res.value) {

                fetch(`/stripe_credit_notes/${stripe_credit_note_id}/void_credit_note`, {
                    method: "POST", credentials: "same-origin", headers: {
                        "X-CSRF-Token": csrfToken, "Content-Type": "application/json", Accept: "application/json",
                    }
                })
                    .then(result => {
                        if (result.status === 200) {
                            window.location.reload();
                        } else {
                            result.text().then(text => {
                                swal({
                                    title: "Erreur", type: "error", text: text
                                })
                            })
                        }
                    })
            }
        })

    }

    renderContentRow(title, value, lightHeader = false, lightValue = false) {
        const HeaderTag = lightHeader ? 'p' : 'h4';
        const ValueTag = lightValue ? 'p' : 'h4';

        return (
            <tr key={title}>
                <td colSpan={this.cellCount + 1}>
                    <HeaderTag className="text-right">{title}</HeaderTag>
                </td>
                <td>
                    <ValueTag className="text-right">{value}</ValueTag>
                </td>
            </tr>
        );
    }

    computeItemFields = (item) => {
        const stripeProduct = item.stripe_price.stripe_product;
        item.productName = stripeProduct.name;

        item.quantity = parseFloat(item.quantity) || 0;
        //item.unit_amount_excluding_tax = parseFloat(item.stripe_price.unit_amount_decimal);
        //if (item.stripe_price.tax_behavior === "inclusive") {
        //    item.unit_amount_excluding_tax = item.unit_amount_excluding_tax / (1 + item.first_tax_rate / 100.0);
        //}

        item.discounted_amount = item.quantity * item.unit_amount_excluding_tax;
        item.discounted_amount *= (1 - item.first_discount_rate / 100.0)
    }


    renderLineItems(creditNotes, stripe_invoice_id, status) {

        const items = this.props.items
        const invoice = this.props.invoice

        let rowsTable = []
        items.forEach((item, index) => {
            this.computeItemFields(item);

            rowsTable.push(
                <tr key={`item#${index}`}>
                    <td>{item.productName}</td>
                    <td className="text-right">{item.quantity}</td>
                    <td className="text-right">{StripeHelpers.formatStringifiedFloatAsCurrency(item.unit_amount_excluding_tax)}</td>
                    <td className="text-right">{StripeHelpers.formatAsPercentage(item.first_tax_rate)}</td>
                    <td className="text-right">{StripeHelpers.formatAsPercentage(item.first_discount_rate)}</td>
                    <td className="text-right">{StripeHelpers.formatStringifiedFloatAsCurrency(item.discounted_amount)}</td>
                </tr>
            )
        })
        const {tableCreditNote, creditTotal} = this.renderCreditNotes(creditNotes, status)
        const amount_due = roundFloatWithTwoDecimal(parseFloat(invoice.total) - creditTotal);

        return [
            <div key={"display"} className="row">
                <div className="col-sm-10">
                    <table className="table">
                        <thead>
                        <tr key={"header"}>
                            <th style={{width: "30%"}}>PRODUIT</th>
                            <th className={"text-right"} style={{width: "10%"}}>QUANTITÉ</th>
                            <th className={"text-right"} style={{width: "20%"}}>PRIX UNITAIRE</th>
                            <th className={"text-right"} style={{width: "20%"}}>TAUX TAXE</th>
                            <th className={"text-right"} style={{width: "20%"}}>TAUX REMISE</th>
                            <th className={"text-right"} style={{width: "20%"}}>MONTANT REMISÉ HT</th>
                        </tr>
                        </thead>
                        <tbody>

                        {rowsTable}

                        {invoice.discounts && invoice.discounts.map((discount, index) =>
                            this.renderContentRow(
                                StripeHelpers.formatDiscountHeader(discount),
                                StripeHelpers.formatFloatAsCurrency(-this.getDiscountAmount(discount, invoice.total_discount_amounts)),
                                true,
                                true
                            )
                        )}

                        {this.renderContentRow(
                            "Total hors taxes",
                            StripeHelpers.formatStringifiedFloatAsCurrency(invoice.total_excluding_tax)
                        )}

                        {invoice.total_tax_amounts && invoice.total_tax_amounts.map((tax_amount, index) =>
                            this.renderContentRow(
                                StripeHelpers.formatTaxHeader(tax_amount.tax_rate),
                                StripeHelpers.formatFloatAsCurrency(tax_amount.amount / 100),
                                true,
                                true
                            )
                        )}

                        {this.renderContentRow(
                            "Total",
                            StripeHelpers.formatStringifiedFloatAsCurrency(invoice.total)
                        )}

                        {tableCreditNote}
                        {(isNaN(amount_due) ?
                                "" :
                                this.renderContentRow(
                                    "Montant dû",
                                    StripeHelpers.formatStringifiedFloatAsCurrency(amount_due)
                                )
                        )}
                        </tbody>
                    </table>
                </div>
            </div>,
            <div key={"actions"} className="row">
                {(amount_due === 0 || status !== "open" && status !== "paid" ? "" :

                    <div className="col-sm-6">
                        <a className="btn btn-sm btn-primary m-r-sm m-b-sm"
                           href={`/stripe_credit_notes/${stripe_invoice_id}/new`}>Créer un avoir</a>
                    </div>)}
            </div>]
    }

    getDiscountAmount(discount, total_discount_amounts) {
        return total_discount_amounts.find(total_discount_amount => total_discount_amount.discount === discount.id).amount / 100
    }

    renderCreditNotes(creditNotes, invoiceStatus) {
        if (creditNotes.length === 0)
            return {
                tableCreditNote: [], creditTotal: 0
            }

        let JsxOutput = []
        let sums = {
            total: 0,
            totalExcludingTax: 0,
            totalTax: {}
        };

        creditNotes.forEach((creditNote, index) => {
            this.renderCreditNote(creditNote, invoiceStatus, JsxOutput, sums);
        })

        JsxOutput.push(
            this.renderContentRow(
                "Total du réajustement (HT)",
                StripeHelpers.formatFloatAsCurrency(-sums.totalExcludingTax),
                true)
        )

        const taxAmounts = {}
        creditNotes.map((creditNote) => creditNote.tax_amounts).flat().forEach((tax_amount) => {
            if (taxAmounts[tax_amount.tax_rate]) {
                taxAmounts[tax_amount.tax_rate] += tax_amount.amount
            } else {
                taxAmounts[tax_amount.tax_rate] = tax_amount.amount
            }
        })

        Object.keys(taxAmounts).forEach((taxRateId) => {
            const taxRate = this.props.taxRates.find(taxRate => taxRate.stripe_tax_rate_id === taxRateId)

            console.log("taxRate", taxRate)
            console.log("taxRateId", taxRateId)
            console.log("taxAmounts", taxAmounts)
            JsxOutput.push(
                this.renderContentRow(
                    formatTaxHeader(taxRate),
                    StripeHelpers.formatFloatAsCurrency(-taxAmounts[taxRateId] / 100.0),
                    true,
                    true
                )
            )
        })

        JsxOutput.push(
            this.renderContentRow(
                "Total du réajustement",
                StripeHelpers.formatFloatAsCurrency(-sums.total)
            )
        )

        return {tableCreditNote: JsxOutput, creditTotal: sums.total}
    }

    renderCreditNote(creditNote, invoiceStatus, JsxOutput, sums) {
        sums.total += parseFloat(creditNote.total)
        sums.totalExcludingTax += parseFloat(creditNote.total_excluding_tax)
        if (creditNote.tax_amounts && creditNote.tax_amounts.length > 0) {
            const tax_rate_id = creditNote.tax_amounts[0].tax_rate
            const rate_object = this.props.taxRates.find(taxRate => taxRate.stripe_tax_rate_id === tax_rate_id)
            if (rate_object) {
                const rateHeader = StripeHelpers.formatTaxHeader(rate_object)
                sums.totalTax[rateHeader] =
                    sums.totalTax[rateHeader] ?
                        sums.totalTax[rateHeader] + parseFloat(creditNote.tax_amounts[0].amount) / 100.0
                        :
                        parseFloat(creditNote.tax_amounts[0].amount) / 100.0
            }
        }
        const reason = reasonList[creditNote.reason]
        const number = creditNote.number
        JsxOutput.push(
            <tr key={creditNote.stripe_credit_note_id}>
                <td colSpan={this.cellCount}><h3><a href={creditNote.pdf} target="_blank">{number}</a> : {reason}</h3>
                </td>
                {invoiceStatus == 'open' &&
                    <td>
                        <div className="dropdown">
                            <button className="btn btn-sm btn-primary m-r-sm m-b-sm" type="button"
                                    data-toggle="dropdown">...
                            </button>
                            <ul className="dropdown-menu">
                                <li><a onClick={() => this.voidCreditNote(creditNote.stripe_credit_note_id)}>Annuler
                                    l'avoir</a></li>
                            </ul>
                        </div>
                    </td>}

            </tr>)

        const creditInfo = creditNote.stripe_credit_note_line_items
        creditInfo.forEach((line, i) => {
            JsxOutput.push(
                <tr key={creditNote.stripe_credit_note_id + "-" + i}>
                    <td>{line.description}</td>
                    <td className="text-right">{line.quantity ? -line.quantity : "-"}</td>
                    <td className="text-right">{StripeHelpers.formatFloatAsCurrency(-line.unit_amount_excluding_tax)}</td>
                    <td className="text-right">{StripeHelpers.formatAsPercentage(line.first_tax_rate)}</td>
                    <td className="text-right">{StripeHelpers.formatAsPercentage(line.first_discount_rate)}</td>
                    <td className="text-right">{StripeHelpers.formatStringifiedFloatAsCurrency(-line.discounted_amount_excluding_tax)}</td>
                </tr>)
        })
    }

    finalizeInvoice(id) {

        api
            .set()
            .success((msg) => {
                this.setState({load_finalize: false})
                swal({
                    type: "success",
                    title: "Facture finalisée"
                });
                window.location.reload();
            })
            .error((msg) => {
                swal({
                    type: "error",
                    title: "Une erreur est survenue",
                    text: msg.message
                })

            })
            .post(`/stripe_invoices/update_status/${id}`, {status: 'open'});

    }

    downloadDocument(type = 'invoice_pdf') {
        let url;
        if (type === 'invoice_pdf')
            url = this.props.invoice.invoice_pdf;
        else {
            // on récupère l'URL du premier reçu - on fait l'hypothèse qu'il n'y en a qu'un dans notre cas de figure
            const firstReceiptUrl = _.get(this.props, 'charges[0].receipt_url', null);
            if (firstReceiptUrl) {
                // Astuce pour télécharger le reçu :
                // * l'URL que nous avons renvoie un HTML qui contient un lien vers le PDF
                // * mais nous pouvons obtenir le lien réel vers le PDF en insérant /pdf à la fin de l'URL, juste avant la chaîne de requête
                // donc, on insère /pdf juste avant la chaîne de requête (si elle existe)
                url = firstReceiptUrl.replace(/(\?.*)?$/, '/pdf$1');
            }
        }

        if (url) {
            // puis on ouvre le pdf dans un nouvel onglet
            window.open(url, '_blank');
        }
    }


    render() {

        const invoice = this.props.invoice
        const creditNotes = this.props.creditNotes
        const fullName = invoice.customer.first_name + " " + invoice.customer.last_name
        let months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

        let dateFormat = new Date(invoice.due_date)
        const expires_at = "Le " + dateFormat.getDate() + " " + months[dateFormat.getMonth()] + " " + dateFormat.getFullYear()

        return (
            <div>
                <div className="row wrapper border-bottom white-bg page-heading m-b-md">
                    <h2>Facture {invoice.number}
                        <div className="btn-group pull-right">
                            {invoice.status === 'draft' ?
                                <div>

                                    <a className="btn btn-sm btn-primary m-r-sm m-b-sm"
                                       href={`/stripe_invoices/${invoice.stripe_invoice_id}/edit`}>
                                        <i className="fas fa-edit"/>Modifier la facture
                                    </a>

                                    <button className="btn btn-sm btn-primary m-r-sm m-b-sm"
                                            disabled={this.state.load_finalize} onClick={() => {
                                        this.setState({load_finalize: true})
                                        this.finalizeInvoice(invoice.stripe_invoice_id)
                                    }}> Finaliser la facture {this.state.load_finalize ?
                                        <i className="fas fa-circle-notch fa-spin"/> : ''} </button>
                                </div>
                                :
                                <Fragment>
                                    <button className="btn btn-sm btn-primary m-r-sm m-b-sm"
                                            onClick={() => {
                                                this.downloadDocument("invoice_pdf")
                                            }}>
                                        <i className="fas fa-file-download"/> Télécharger le PDF
                                    </button>

                                    {this.props.charges && this.props.charges.length > 0 &&
                                        <button className="btn btn-sm btn-primary m-r-sm m-b-sm"
                                                onClick={() => {
                                                    this.downloadDocument("receipt_pdf")
                                                }}>
                                            <i className="fas fa-file-download"/> Télécharger le(s) reçu(s)
                                        </button>}

                                </Fragment>

                            }

                        </div>
                    </h2>
                </div>
                <section className="panel panel-default">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-6 ">
                                <label>Client</label>
                                <p>{fullName}</p>
                            </div>
                            <div className="col-sm-4 ">
                                <label>Date d'expiration</label>
                                <p>{expires_at}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 ">
                                <label>Description</label>
                                <p>{invoice.description}</p>
                            </div>
                        </div>
                        {this.renderLineItems(creditNotes, invoice.stripe_invoice_id, invoice.status)}
                        <div className="row">
                            <div className="col-sm-12">
                                <label>Pied de page</label>
                                <p>{invoice.footer}</p>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        )
    }
}
const reasonList = {
    duplicate: "Doublon",
    product_unsatisfactory: "insatisfaisant",
    order_change: "Modification de la commande",
    fraudulent: "Paiement frauduleux"
}