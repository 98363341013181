import React, {Component} from "react";
import fetch from "isomorphic-unfetch";
import swal from "sweetalert2";
import {csrfToken} from "../../../utils"

export default class DolibarrTiers extends Component
{
    constructor(props)
    {
        super(props);
        ////////////
        this.state = {
            newAddress: {},
            change: undefined,
            changes: [],
            updating: false,
            fetching: false,
        };
        ////////////
        this.handleFetch = this.handleFetch.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.updateThirdParty = this.updateThirdParty.bind(this);
    }

    componentDidMount()
    {
        this.handleFetch();
    }

    async handleFetch()
    {
        this.setState({fetching:true});

        let user_season = {user_id: this.props.payer.id, season_id: this.props.season}

        await fetch("/dolibarr/get_user_proposal/", {
            method: "POST",
            headers: {
                "X-Csrf-Token": csrfToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({user_season}),
        }).then(res => {
                if (res.ok)
                    res.json().then(proposal => {
                        // tier_id: {user_id: bigint, dolibarr_user_id: bigint}
                        //this.setState({dolibarr_user_id: porposal.dolibarr_proposal_id})

                        this.props.payer.doliProposal = proposal;
                        this.setState({payer: this.props.payer, fetch: false})
                    })
            }
        );

        //////////////

        let payer_id = this.props.payer.id;

        async function getDolibarrUserId() {
            let res = await fetch("/dolibarr/get_dolibarr_user_id/" + payer_id);
            if (res.ok) {
                let data = await res.json();

                return data.dolibarr_user_id;
            } else {
                return null;
            }
        }

        let dolibarr_thirdparty_id = await getDolibarrUserId();

        ////////////////////

        this.props.payer.doliTiers = dolibarr_thirdparty_id; //
        this.setState({payer: this.props.payer, fetch: false}); //

        ////////////////////

        await fetch(`/dolibarr/get_tier_by_id/${dolibarr_thirdparty_id}`).then(res => {
            if (res.ok)
                res.json().then(data => {
                    this.setState({newAddress: {}});
                    let userAddress = this.props.payer.address;
                    this.setState({tiers: data});

                    let changes = [];

                    if (data.address.trim() != userAddress.street_address.trim()) {
                        let newAddress = {
                            address: userAddress.street_address,
                            zip: userAddress.postcode,
                            town: userAddress.city
                        }

                        this.setState({newAddress: newAddress});

                        changes.push({id: "address", label: "Adresse", data: newAddress});
                    } else {
                        changes.push({id: "address", label: "Adresse", data: false});
                    }


                    if (data.town != userAddress.city) {
                        changes.push({id: "town", label: "Orthographe ville", data: {town: userAddress.city}});
                    } else {
                        changes.push({id: "town", label: "Orthographe ville", data: false});
                    }

                    if (data.code_compta === this.props.codeCompta) {
                        changes.push({id: "code_compta", label: "Code compta", data: false});
                    } else {
                        changes.push({
                            id: "code_compta",
                            label: "Code compta",
                            data: {code_compta: this.props.codeCompta}
                        });
                    }

                    this.setState({changes: changes});

                    this.setState({fetching:false});
                })
            })
    }

    handleUpdate(element) {
        if (!this.state.updating) {
            this.setState({updating:true});
            this.updateThirdParty(element);
        }
    }

    updateThirdParty(element) {
        let tiers = this.state.tiers;

        for (const [key, value] of Object.entries(element.data)) {
            tiers[key] = value;
        }

        fetch("/dolibarr/update_tier", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": csrfToken,
            },
            body: JSON.stringify(tiers),
        }).then(res => {
            if (res.ok) {
                this.handleFetch();
                this.setState({updating:false});
            } else {
                this.setState({updating:false});
                swal({
                    type: "error",
                    title: "Une erreur est survenue et '"+element.label+"' n'a pas été mis(e) à jour dans Dolibarr"
                });
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(prevProps && prevProps.season && this.props.season != prevProps.season)
            this.handleFetch();
    }

    render()
    {
        let somethinghaschanged = false;

        let changes = this.state.changes;

        changes.forEach(function(element) {
           if (element.data !== false) {
               somethinghaschanged = true;
           }
        });

        const payer = this.props.payer;

        return <div>
            <div className="col-lg-6 col-md-6 m-b-lg print-none">
                { (somethinghaschanged && payer.doliProposal == undefined) && 
                    <div className="alert alert-danger">
                        Ne pas créer de devis avant d'avoir mis à jour le tiers
                    </div>
                }

                {payer.doliTiers == undefined /*dolibarr_user_id == undefined*/ ? <button
                    style={{marginBottom:'20px'}}
                    className="btn btn-sm btn-danger m-r-sm"
                    onClick={() => {
                        swal.showLoading()
                        if(payer.address && payer.address.city && payer.address.street_address && payer.address.postcode)
                        {
                            fetch("/dolibarr/create_tier_for_user/" + payer.id, { method: "get" })
                                .then(res => {

                                    if(res.ok)
                                    {
                                        res.json().then(tier =>
                                        {
                                            payer.doliTiers = tier.id

                                            this.setState({payer: payer});

                                            this.setState({dolibarr_user_id: tier.socid})

                                            let user_link = {user_id: payer.id, socid: tier.id}

                                            fetch("/dolibarr/new_user_link", {
                                                method: "POST",
                                                headers: {
                                                    "X-Csrf-Token": csrfToken,
                                                    "Content-Type": "application/json",
                                                },
                                                body: JSON.stringify({user_link}),
                                            }).then(this.handleFetch())
                                        })

                                        return swal({title: "Création réussi", type: "success", text: "ok"});
                                    }

                                    return swal({title: "Echec", type: "error", text: res.statusText});
                                });
                        }
                        else
                        {
                            swal({
                                type: "error",
                                title: "Veuillez renseigner une adresse pour le payeur"
                            });
                        }
                    }
                    }
                >Créer tiers</button> : <a target="_blank" href={`${this.props.dolibarrUrl}societe/card.php?socid=${payer.doliTiers/*this.state.dolibarr_user_id*//*payer.doliTiers.ref*/}`} className="btn m-r-sm btn-sm btn-success btn-outline">Lien vers le tiers</a> }

                {payer.doliProposal != undefined ? <a
                        target="_blank"
                        href={`${this.props.dolibarrUrl}comm/propal/card.php?id=${payer.doliProposal/*[0]*//*.id*/}`}
                        className="btn btn-sm btn-success btn-outline m-r-sm">
                        Lien vers le devis{/*payer.doliProposal[0].ref ? ` ${payer.doliProposal[0].ref}` : ""}*/}
                    </a> :
                    <button
                        style={{marginBottom:'20px'}}
                        className={"btn btn-sm m-r-sm " + (payer.doliTiers == undefined ? "btn-white" : "btn-danger")}
                        onClick={() => {
                            if(payer.doliTiers == undefined)
                                swal({title: "Non", type: "error"});
                            else
                            {
                                swal.showLoading();
                                fetch("/dolibarr/create_devis/?uid="+ payer.id + "&sid=" + this.props.season + "&did=" + payer.doliTiers, {method: "get"})
                                    .then(res =>
                                    {
                                        if(res.ok)
                                        {
                                            res.json().then(devis =>
                                            {
                                                payer.doliProposal = devis.id;
                                                
                                                this.setState({payer: payer})

                                                /////////////////////////////////////////////////////

                                                let newProposal = {dolibarr_proposal_id: devis.id,
                                                    payer_id: payer.id,
                                                    season_id: this.props.season}

                                                fetch("/dolibarr/create_proposal", {
                                                    method: "POST",
                                                    headers: {
                                                        "X-Csrf-Token": csrfToken,
                                                        "Content-Type": "application/json",
                                                    },
                                                    body: JSON.stringify({newProposal}),
                                                }).then(res => {
                                                    if(res.ok) {
                                                        this.handleFetch()
                                                        swal({
                                                            type: "success",
                                                            title: "Devis créé, validé et signé"
                                                        })
                                                    } else {
                                                        swal({
                                                            type: "error",
                                                            title: "Une erreur est survenue avec la base de données"
                                                        })
                                                    }

                                                })

                                                ///////////////////////////////////////////////////
                                            });
                                        }
                                        else
                                        {
                                            res.text().then(textError => swal({
                                                type: "error",
                                                title: "Erreur",
                                                text: textError
                                            }));
                                        }
                                    });


                            }
                        }}
                        disabled={payer.doliTiers == undefined}
                    >Créer devis</button>  }


                    { (somethinghaschanged && payer.doliTiers && !this.state.fetching && !this.state.updating) &&
                        <button className="btn btn-sm btn-danger btn-outline pull-right"
                                style={{marginBottom:'20px'}}
                                data-toggle="collapse"
                                data-target="#collapseUpdates"
                                aria-expanded="false"
                                aria-controls="collapseUpdates"
                        >
                            <i className="fas fa-exclamation-circle"></i>
                            <span className="mx-3">Mise à jour du tiers Dolibarr requise</span>
                            <i className="fas fa-exclamation-circle"></i>
                        </button>
                    }

                    { (!somethinghaschanged && payer.doliTiers && !this.state.fetching && !this.state.updating) &&
                        <button className="btn btn-sm btn-success btn-outline pull-right"
                                data-toggle="collapse"
                                data-target="#collapseUpdates"
                                aria-expanded="false"
                                aria-controls="collapseUpdates"
                        >
                            <span className="mr-3">Tiers Dolibarr à jour</span>
                            <i className="fas fa-check"></i>
                        </button>
                    }

                    { (payer.doliTiers && (this.state.fetching || this.state.updating)) &&
                        <button className="btn btn-sm btn-primary btn-outline pull-right"
                                style={{marginBottom:'20px'}}
                                data-toggle="collapse"
                                data-target="#collapseUpdates"
                                aria-expanded="false"
                                aria-controls="collapseUpdates"
                        >
                            <span className="mr-3">Chargement infos tiers Dolibarr</span>
                            <i className="fas fa-spinner"></i>
                        </button>
                    }

                    <div id="collapseUpdates" className="collapse">
                        <div>
                            {changes.map(function(element) {
                                if (element.data === false) {
                                    return (
                                        <div className="alert alert-success d-flex flex-row justify-content-between align-items-center" key={element.id}>
                                            <span>{element.label}</span>
                                            <i className="fas fa-check"/>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="alert alert-danger d-flex flex-row justify-content-between align-items-center" key={element.id}>
                                            <span>{element.label}</span>

                                            { element.id === "code_compta" ? //cas spécial code compta car put sur ce champ non géré par l'api rest
                                                <div className="row">
                                                    <span className="col-xs-7">Le code compta à jour sera copié dans le presse-papier au clic sur le bouton <i className="fas fa-edit ml-2"></i>
                                                        <br/>
                                                        <br/>
                                                        Rafraîchir les infos avec le bouton <i className="fas fa-sync mx-2"></i> après la mise à jour du code sur Dolibarr</span>
                                                    <div className="col-xs-5">
                                                        <a className={`btn btn-sm btn-danger pull-right ${(this.state.updating || this.state.fetching)  ? "disabled" : ""}`}
                                                           href={`${this.props.dolibarrUrl}comm/card.php?action=editcustomeraccountancycode&id=${this.props.payer.doliTiers}`}
                                                           target="_blank"
                                                           onClick={() => {navigator.clipboard.writeText(element.data.code_compta)}}>
                                                            <span className="mr-3">Éditer le code sur Dolibarr</span>
                                                                <i className="fas fa-edit"></i>
                                                        </a>
                                                        <button className={`btn btn-sm btn-primary pull-right mt-4 ${(this.state.updating || this.state.fetching)  ? "disabled" : ""}`}
                                                                style={{marginBottom:'20px'}}
                                                                onClick={() => this.handleFetch()}
                                                        >
                                                            <span className="mr-3">Rafraîchir infos</span>
                                                            <i className="fas fa-sync"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <button className={`btn btn-danger ${(this.state.updating || this.state.fetching)  ? "disabled" : ""}`} onClick={() => this.handleUpdate(element)}>
                                                    Mettre à jour
                                                </button>
                                            }


                                        </div>
                                    )
                                }
                            }.bind(this))
                            }

                        </div>
                    </div>


                {/*<button // Le bouton est désactivé pour l'instant
                    disabled
                    className={"btn btn-sm m-r-sm " + (payer.doliTiers != undefined && payer.doliProposal != undefined ? "btn-primary" : "btn-white" )}
                    onClick={() => {
                        swal({
                            type: "warning",
                            title: "Êtes-vous sûr ?",
                            html: "<p>Cela va <strong>écraser</strong> les données enregistrées pour correspondre à celles présentes sur le devis de dollar.</p>",
                            confirmButtonText: "Oui",
                            showCancelButton: true,
                            cancelButtonText: "Non"
                        }).then(res =>
                        {
                            if(res.value)
                            {
                                fetch(`/dolibarr/get_invoices/${payer.id}?sid=${this.props.season}`).then(res =>
                                {
                                    if(res.ok)
                                    {
                                        res.json().then(invoices =>
                                        {
                                            payer.dolly_invoices = invoices || [];

                                            this.setState({payer: payer});

                                            const tab = payer.dolly_invoices.length === 0 ? [] : (payer.dolly_invoices[0].last_main_doc || "").split("/")
                                            const totalPaid = payer.dolly_invoices.length === 0 ? 0 : payer.dolly_invoices[0].totalpaid || 0;

                                            // --- Due_payments ---
                                            const dues = this.props.due_paiments;

                                            if(dues.length > 0)
                                                this.props.handleDeleteDues(payer.id, dues.map(d => d.id))

                                            const due = {
                                                amount: payer.doliProposal[0].total_ttc,
                                                payment_method_id: (this.props.paymentsMethods.find(m => (m.label || "").toLowerCase() == "cb") || this.props.paymentsMethods[0]).id,
                                                date: new Date(payer.doliProposal[0].fin_validite || Date.now())
                                            };

                                            this.props.handleCreateDue({payer: payer, duePayments: [due]})

                                            // --- Payments ---
                                            this.props.handleDeletePayments(payer.id, this.props.payments.map(p => p.id))

                                            if(invoices && invoices[0] && invoices[0].payments)
                                            {
                                                const payments = invoices[0].payments.map(p =>
                                                {
                                                    const date = p.date.substring(0, p.date.indexOf(" "));
                                                    const method = this.props.paymentsMethods.find(m => (m.label || "").toLowerCase() == (p.type || "").toLowerCase());

                                                    return {
                                                        amount: p.amount,
                                                        adjusted_amount: p.amount,
                                                        payable_id: payer.id,
                                                        operation: '+',
                                                        payable_type: "User",
                                                        cashing_date: date,
                                                        reception_date: date,
                                                        due_payment_id: this.props.due_paiments[0].id,
                                                        payment_method_id: (method || this.props.paymentsMethods.find(m => (m.label || "").toLowerCase() == "cb") || this.props.paymentsMethods[0]).id
                                                    }
                                                });

                                                payments.forEach(p => this.props.handleDeletePayments(p))
                                            }

                                            swal({
                                                type: "info",
                                                confirmButtonText: "Fermer",
                                                title: invoices == undefined ? "pas de facturations" : `Numéro de facture: ${payer.dolly_invoices[0].ref}`,
                                                html: `<p>Total payée: <strong>${totalPaid}</strong></p>` +
                                                    (invoices == undefined || tab.length <= 0 ? "" : `<a class="btn btn-primary btn-sm" target="_blank" href="${this.props.dolibarrUrl}/document.php?modulepart=${tab[0]}&attachment=0&file=${tab[1]}/${tab[2]}">lien vers le PDF</a>`)
                                            })
                                        })
                                    }
                                })
                            }
                        })
                    }}
                >Récupérer infos devis</button>*/}
            </div>
        </div>
    }
}