import _ from "lodash";
import React from "react";
import swal from "sweetalert2";
import PaymentsList from '../../../generalPayments/PaymentList';
import * as api from "../../../../tools/api";

const MESSAGE_MODAL_ID = "messagesModal";


class ExtendedPaymentList extends PaymentsList {
    constructor(props) {
        super(props);

        let column = _.last(this.state.columns);
        column.Cell = d => (
            <div className="flex">
                {d.value.mail ? (
                    <button
                        data-toggle="modal"
                        onClick={() =>
                            this.setState({ targets: [d.original.id] })
                        }
                        data-target={`#${MESSAGE_MODAL_ID}`}
                        className="btn btn-xs btn-primary"
                        title="Envoyer un rappel"
                    >
                        <i className="fas fa-envelope" />
                    </button>
                ) : null}

                { !!d.original.invoice_id && this.props.isInvoicesActivated ?
                    <button
                        className="btn btn-primary btn-xs m-r-sm"
                        onClick={() => this.handleShowInvoice(d.original.id)}>
                        <i className="fas fa-file-invoice" />
                    </button>
                    : null }
            </div>
        );


    }

    handleShowInvoice(paymentId) {
        api.set()
            .success(({invoice_url}) => {
                console.log(invoice_url);
                window.open(invoice_url, '_blank');
            })
            .error((res) => {
                swal({
                    title: "error",
                    type: "error",
                    text: "Impossible d'afficher la facture"
                });
                console.log("show invoice : ", res)
            })
            .get(
                "/users/student_payments/invoice",
                {payment_id: paymentId  }
            );
    }

}


export default ExtendedPaymentList;

