import React from "react";

import {Field} from "react-final-form";
import inputSelectReact from "./../../../../components/common/InputSelectReact";
import {csrfToken} from "./../../../utils";


export default class SelectUser extends React.Component {
    constructor(props) {
        super(props)
        this.state = {options: [{"label": "chargement des clients", "value": "value"}]}
        this.selectUserList()
    }

    async selectUserList() {
        const data = await fetch(`/users/simple_list.json`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
        const value = await data.json()

        let options = []
        value.users.forEach(user => {
            if (user.telephones[0] === undefined || user.addresses[0] === undefined)
                options.push({
                    'label': user.first_name + " " + user.last_name,
                    "errorValid": true,
                    'value': user.id
                })
            else {
                if (this.props.isInvoice && user.organization_id != null && (user.organization.tax_id === null || user.organization.tax_id.value == null)) {
                    options.push({
                        'label': user.first_name + " " + user.last_name,
                        "displayModal": true,
                        'value': user.id
                    })
                } else
                    options.push({'label': user.first_name + " " + user.last_name, 'value': user.id})
            }
        })

        this.setState({options: options})
    }


    render() {
        return (
            <Field
                label="Client"
                id='id_customer'
                name="id_customer"
                placeholder="Choisissez un client"
                required
                noOptionsMessage={'Aucun client trouvé'}
                render={inputSelectReact}
                options={this.state.options}
            />
        )

    }
}
