import React, { Fragment } from "react";

export default class RoomsParametersEdit extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div>
            <p>dezdzgfergre</p>
        </div>
    }
}