import React, {Fragment} from "react";
import {Field} from "react-final-form";
import Input from "../../common/Input";
import {required, minLength, minValue, maxValue, composeValidators} from "../../../tools/validators";
import checkbox from "../../common/Checkbox";
import Select from "react-select";

export default class ModalFormTaxRate extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { isUpdate } = this.props;
        const countries = [
            { value: "FR", label: "FR" },
            { value: "BE", label: "BE" },
        ];

        const ReactSelectAdapter = ({ input, ...rest }) => (
            <Select {...input} {...rest} searchable required />
        );

        return (
            <Fragment>
                <div className="row">
                    <div className="col">
                        <Field
                            label="Nom du taux de taxe"
                            name="description"
                            type="text"
                            required
                            validate={composeValidators(required, minLength(3))}
                            render={Input}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <Field
                            label="Taux de taxe (%)"
                            name="percentage"
                            type="number"
                            required
                            validate={composeValidators(required, minValue(0), maxValue(100))}
                            render={Input}
                            disabled={isUpdate}
                        />
                    </div>
                </div>

                {!isUpdate && (
                    <Fragment>
                        <div className="row">
                            <div className="col">
                                <label>Pays</label>
                                <Field
                                    label="Pays"
                                    name="country"
                                    component={ReactSelectAdapter}
                                    required
                                    options={countries}
                                    render={Input}
                                />
                            </div>
                        </div>


                        <div className="row mt-3">
                            <div className="col">
                                <Field
                                    id="inclusive"
                                    name="inclusive"
                                    label="Inclusif"
                                    type="checkbox"
                                    required
                                    render={checkbox}
                                />
                            </div>
                        </div>
                    </Fragment>
                )}

                {isUpdate && (
                    <Fragment>
                        <div className="row mt-3">
                            <div className="col">
                                <Field
                                    id="active"
                                    name="active"
                                    label="Actif"
                                    type="checkbox"
                                    required
                                    render={checkbox}
                                />
                            </div>
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}
