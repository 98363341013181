import React from 'react';

export default function taxRatesActionButtons({
                                                  item: item,
                                                  onEdit,
                                                  onDelete,
                                              }) {

    item.actionLabel = item.active ? "archivé" : "activé";
    item.actionToExecute = !item.active;

    let button
    if (item.active)
        button = <a className="btn-sm btn-warning"
                    data-tippy-content="Archiver le produit"
                    onClick={() => onDelete(item)}>
            <i className="fas fa-archive"/>
        </a>
    else
        button = <a className="btn-sm btn-warning"
                    data-tippy-content="Activer le produit"
                    onClick={() => onDelete(item)}>
            <i className="fas fa-arrow-alt-circle-up"/>
        </a>

    return (

        <div className="btn-wrapper">
            <a className="btn-sm btn-primary m-r-sm"
               data-tippy-content="Modifier le taux de taxe"
               onClick={() => onEdit(item)} >
                <i className="fas fa-edit"/>
            </a>

            {/*{button}*/}
        </div>
    );
}