import React from "react";
import GeneralPayments from "../../../generalPayments/GeneralPayments";
import PaymentScheduleList from "../../../generalPayments/PaymentScheduleList";
import CheckList from "../../../generalPayments/CheckList";
import TabbedComponent from "../../../utils/ui/tabs";
import ExtendedDuePaymentList from "./ExtendedDuePaymentList";
import ExtendedPaymentList from "./ExtendedPaymentList";

export default class ExtendedGeneralPayments extends GeneralPayments {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className="col-lg-12 page-reglement">
                <TabbedComponent tabs={[
                    {
                        id: "due_payments",
                        header: "Échéances",
                        body: <ExtendedDuePaymentList
                            paymentMethods={this.props.paymentMethods}
                            locations={this.props.locations}
                            minYear={this.props.minYear}
                            maxYear={this.props.maxYear}
                            statuses={this.props.duePaymentStatuses}
                            seasons={this.props.seasons}
                            paymentStatuses={this.props.paymentStatuses}
                            isPaymentActivated={this.props.isPaymentActivated}
                            isInvoicesActivated={this.props.isInvoicesActivated}

                        />,
                        active: true,
                    },
                    {
                        id: "payments",
                        header: "Règlements",
                        body: <ExtendedPaymentList
                            paymentMethods={this.props.paymentMethods}
                            locations={this.props.locations}
                            minYear={this.props.minYear}
                            maxYear={this.props.maxYear}
                            statuses={this.props.duePaymentStatuses}
                            seasons={this.props.seasons}
                            paymentStatuses={this.props.paymentStatuses}
                            isPaymentActivated={this.props.isPaymentActivated}
                            isInvoicesActivated={this.props.isInvoicesActivated}
                        />,
                    },
                    {
                        id: "schedules_without_payer",
                        header: "Échéanciers sans payeur",
                        body: <PaymentScheduleList seasons={this.props.seasons} />,
                    },
                    {
                        id: "checks",
                        header: "Chèques",
                        body: <CheckList/>,
                    },
                ]}>

                </TabbedComponent>
            </div>
        );
    }
}