import React, {Component, Fragment} from 'react';
import Modal from "react-modal";
import ReactTable from "react-table";
import * as api from "../../../../tools/api";
import swal from "sweetalert2";
import PropTypes from "prop-types";

const moment = require("moment");

export default class ModalBillingPayers extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            showDesiredInvoicesModal: false,
        };
    }

    render()
    {
        const all_billing_logs = this.props.datas.map((data) => (data.des || {}).billing_logs || data.billing_logs).filter((data) => data).flat(1);
        const payer_billing_logs = all_billing_logs.filter((data) => data.payer_id === this.props.payer.id);
        const payer_billing_logs_by_object = payer_billing_logs.reduce((acc, data) =>
        {
            if (!acc[data.billing_object_id]) {
                acc[data.billing_object_id] = [];
            }

            acc[data.billing_object_id].push(data);

            return acc;
        }, {});

        const credit_notes = payer_billing_logs.filter(data => data["is_credit_note?"] === true);

        const columns = [
            {
                id: "date",
                Header: "Date",
                width: 100,
                accessor: d => d[1][0] ? moment(d[0].issue_date).format("DD/MM/YYYY") : "XX/XX/XXXX",
                Cell: props => <div className="w-100 h-100 text-center d-flex align-items-center justify-content-center">{props.value}</div>
            },
            {
                id: "amount",
                Header: "Montant",
                width: 100,
                accessor: d => d[1].reduce((acc, data) => acc + data.unit_price * data.prorata, 0),
                Cell: props => <div className="w-100 h-100 text-center d-flex align-items-center justify-content-center">{props.value}</div>
            },
            {
                id: "objects",
                Header: "Objets",
                Cell: props => {
                    const datas = props.original[1].map(data => {
                        console.log(data)
                        if(data.product_type === "des_act")
                            return this.props.datas.find(d => d.id == data.product_id).activity;
                        else if(data.product_type === "adh")
                            return "Adhésions";
                        else
                            return "";
                    }).filter((data, index, self) => self.indexOf(data) === index).filter(data => data); // Remove duplicates and empty values

                    return <div className="w-100 h-100 text-center d-flex align-items-center justify-content-center">{datas.join(", ")}</div>
                }
            },
            {
                id: "actions",
                Header: "Actions",
                width: 150,
                Cell: props => <div className="w-100 h-100 text-center d-flex align-items-center justify-content-center">
                    <button
                        className="btn btn-primary btn-xs mr-2"
                        type="button"
                        onClick={() => this.download(props.original[0], (props.original[1][0] || {})["is_credit_note?"])}>
                        <i className="fas fa-eye m-r-xs" />
                        Télécharger
                    </button>
                </div>
            }
        ];

        return <Fragment>
            <button
                className="btn btn-primary btn-xs mr-2"
                type="button"
                onClick={() => this.setState({ showDesiredInvoicesModal: true })}>
                <i className="fas fa-eye m-r-xs" />
                Voir les factures
            </button>

            <Modal
                isOpen={this.state.showDesiredInvoicesModal}
                onRequestClose={() => this.setState({ showDesiredInvoicesModal: false })}
                contentLabel="Factures"
                style={{
                    content: {
                        inset: "auto",
                        minWidth: "75%",
                    }
                }}
                ariaHideApp={false}
            >
                <h3>Factures de {this.getFullname(this.props.payer)}</h3>
                <ReactTable
                    data={Object.entries(payer_billing_logs_by_object).filter(data => (data[1][0] || [])["is_credit_note?"] === false)}
                    columns={columns}
                    defaultPageSize={10}
                    className="-striped -highlight"
                    showPagination={false}
                    SubComponent={row => {

                        const tmp = credit_notes.filter(data => row.original[1].map(data => data.billing_object_item_id).includes(data.billing_object_item_id));
                        const datas = Object.entries(tmp.reduce((acc, data) => {
                            if (!acc[data.billing_object_id]) {
                                acc[data.billing_object_id] = [];
                            }

                            acc[data.billing_object_id].push(data);

                            return acc;
                        }, {}));

                        return <div className="p-3">
                            <h4>Avoirs:</h4>
                            <table className="table table-striped table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Montant</th>
                                    <th>Objets</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {datas.map((data, index) => {
                                    return <tr key={index}>
                                        <td>{moment(data[0].issue_date).format("DD/MM/YYYY")}</td>
                                        <td>{data[1].reduce((acc, data) => acc + data.unit_price * data.prorata, 0)}</td>
                                        <td>{data[1].map(data => {
                                            if(data.product_type === "des_act")
                                                return this.props.datas.find(d => d.id == data.product_id).activity;
                                            else if(data.product_type === "adh")
                                                return "Adhésions";
                                            else
                                                return "";
                                        }).filter((data, index, self) => self.indexOf(data) === index).filter(data => data).join(", ")}</td>
                                        <td>
                                            <button
                                                className="btn btn-primary btn-xs mr-2"
                                                type="button"
                                                onClick={() => this.download(data[0], (data[1][0] || {})["is_credit_note?"])}>
                                                <i className="fas fa-eye m-r-xs" />
                                                Télécharger
                                            </button>
                                        </td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        </div>
                    }}
                />
            </Modal>
        </Fragment>
    }

    getFullname(user)
    {
        return user.first_name + ' ' + user.last_name
    }

    download(id, is_credit_note)
    {
        swal.showLoading();

        api.set()
            .success((data) =>
            {
                if(data.url)
                {
                    swal.close();
                    window.location = data.url;
                }
            })
            .error((error) =>
            {
                swal({
                    title: "Erreur",
                    text: "Une erreur s'est produite lors de la création de la facture",
                    type: "error"
                });
            })
            .get(`/api/student_payments/stripe/${is_credit_note ? 'credit_note' : 'invoice'}/${id}`, {});
    }
}

ModalBillingPayers.propTypes = {
    payer: PropTypes.object.isRequired,
    datas: PropTypes.array.isRequired,

}