import React, {Fragment} from "react";
import swal from "sweetalert2";

import {csrfToken} from "./../../../utils";
import {makeDebounce} from "./../../../../tools/inputs";
import ReactTableFullScreen from "./../../../ReactTableFullScreen";

const moment = require("moment");
require("moment/locale/fr");


const debounce = makeDebounce();

class StripeProductList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pages: null,
            sorted: '',
            loading: true,
            filter: {},
        };

        this.fetchData = this.fetchData.bind(this);
    }

    requestData = (pageSize, page, sorted, filtered, format) => {
        return fetch(`${this.props.urlListData}${format ? "." + format : ""}`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                pageSize,
                page,
                sorted: sorted[0],
                filtered,
            }),
        });
    };

    fetchData(state, instance) {

        this.setState({loading: true, filter: state});
        debounce(() => {
            this.requestData(
                state.pageSize,
                state.page,
                state.sorted,
                state.filtered
            )
                .then(response => response.json())
                .then(data => {
                    const res = {

                        data: data.products,
                        pages: data.pages,
                        total: data.total,
                    };

                    return res;
                })
                .then(res => {
                    this.setState({
                        ...res,
                        loading: false,
                    });
                });
        }, 400);
    }

    downloadExport() {
        this.requestData(
            this.state.filter.pageSize,
            this.state.filter.page,
            this.state.filter.sorted,
            this.state.filter.filtered,
            "csv"
        )
            .then(res => res.blob())
            .then(file => {
                const download = document.createElement("a");
                download.download = `Produit_${moment().format(
                    "DD_MM_YYYY"
                )}.csv`;
                download.href = URL.createObjectURL(file);
                document.body.appendChild(download);
                download.click();
                document.body.removeChild(download);
            });
    }

    archiveActiveProduct(id, name, action) {
        swal({
            title: "Voulez-vous vraiment " + action + "r le produit '" + name + "' ?",
            type: "warning",
            showCancelButton: true,
            cancelButtonText: "non",
            confirmButtonText: 'oui'
        }).then(res => {
            if (res.value) {
                fetch(`/stripe_products/${action}/${id}`,
                    {
                        method: "POST",
                        credentials: "same-origin",
                        headers: {
                            "X-CSRF-Token": csrfToken,
                            "Content-Type": "application/json",
                            Accept: "application/json",
                        }
                    })
                    .then(result => {
                        if (result.status === 200) {
                            this.fetchData(this.state.filter)
                        } else {
                            result.text().then(text => {
                                swal({
                                    title: "Erreur",
                                    type: "error",
                                    text: text
                                })
                            })
                        }
                    })
            }
        })

    }

    editLink(text, id, number = false) {
        const clasName = (number ? "text-dark" : "w-100 d-flex text-dark")

        return <a href={'/stripe_products/' + id + "/edit"} className={clasName}>{text}</a>
    }

    render() {
        const {data, pages, loading} = this.state;
        const columns = [
            {
                id: "active",
                Header: "Actif",
                maxWidth: 80,
                accessor: d => {
                    let color;
                    d.active ? color = "#4e9a06" : color = "#ef2929";
                    const circle = <i className="fa fa-circle" style={{color: color}}></i>
                    return (
                        <div className="flex flex-center-start flex-center-justified">
                            {this.editLink(circle, d.stripe_product_id)}
                        </div>
                    )
                },
                Filter: ({filter, onChange}) => (

                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{width: "100%"}}
                        value={filter ? filter.value : ""}
                    >
                        <option value=""/>

                        <option value={"true"} style={{color: "#4e9a06"}}>&#128994;</option>
                        <option value={"false"} style={{color: "#ef2929"}}>&#x1F534;</option>
                        ))
                    </select>

                ),
            },
            {
                id: "code_product",
                Header: "Code produit",
                accessor: d => this.editLink(d.code_product, d.stripe_product_id),

            },
            {
                id: "name",
                Header: "Nom",
                accessor: d => this.editLink(d.name, d.stripe_product_id),
            },
            {
                id: "description",
                Header: "Description",
                accessor: d => this.editLink(d.description, d.stripe_product_id),
            },
            {
                id: "unit_label",
                Header: "Unité",
                accessor: d => {
                    if (d.unit_label === null)
                        return this.editLink("Aucune unité", d.stripe_product_id)
                    else
                        return this.editLink(d.unit_label, d.stripe_product_id)
                },
            },
            {
                id: "default_price",
                Header: "Prix",
                accessor: d => {
                    if (d.default_price === undefined)
                        return "prix undéfini"

                    const default_price = d.default_price.unit_amount_decimal
                    let price = new Intl.NumberFormat('fr-FR', {
                        style: 'currency',
                        currency: 'EUR'
                    }).format(default_price)

                    if (default_price === null || default_price === undefined)
                        price = "Aucun prix"

                    return (<div style={{textAlign: "right"}}>{this.editLink(price, d.stripe_product_id, true)}</div>)

                },
            },

            {
                id: "actions",
                Header: "Actions",
                Cell: props => {

                    let button
                    if (props.original.active)
                        button = <a name={`archive_${props.original.stripe_product_id}`} className="btn-sm btn-warning"
                                    data-tippy-content="Archiver le produit"
                                    onClick={() => this.archiveActiveProduct(props.original.stripe_product_id, props.original.name, "archive")}>
                            <i className="fas fa-archive"/></a>
                    else
                        button = <a name={`active_${props.original.stripe_product_id}`} className="btn-sm btn-warning"
                                    data-tippy-content="Activer le produit"
                                    onClick={() => this.archiveActiveProduct(props.original.stripe_product_id, props.original.name, "active")}>
                            <i className="fas fa-arrow-alt-circle-up"/></a>

                    return (
                        <div className="text-center">
                            <div className="btn-wrapper">
                                <a name={`edit_${props.original.stripe_product_id}`}
                                   className="btn-sm btn-primary m-r-sm" data-tippy-content="Modifier le produit"
                                   href={'/stripe_products/' + props.original.stripe_product_id + "/edit"}>
                                    <i className="fas fa-edit"/>
                                </a>
                                {button}
                            </div>
                        </div>
                    );
                },
                sortable: false,
                filterable: false,
            }
        ];

        const events = [];

        let infoTotalProduct = ""
        if (this.state.total !== undefined)
            infoTotalProduct = <div className="flex flex-center-justified m-t-xs">
                <h3>{`${this.state.total} produit${this.state.total > 1 ? "s" : ""} au total`}</h3></div>


        return <Fragment>
            <div className="row mb-4 mt-3">
                <div className="col-sm-3 form-group">
                    <button data-tippy-content="Mettre le tableau en plein écran"
                            className="btn btn-primary m-r"
                            onClick={() => events[0]()}>
                        <i className="fas fa-expand-arrows-alt"></i>
                    </button>

                    <button
                        className="btn btn-primary m-r"
                        onClick={() => this.downloadExport()}
                    >
                        <i className="fas fa-upload m-r-sm"/>
                        Exporter en CSV
                    </button>
                </div>
                <div className="btn-group pull-right">
                    <a href={'/stripe_products/new'} className="btn btn-primary m-r"
                       data-tippy-content="Créer un produit">Créer un produit
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ReactTableFullScreen
                        events={events}
                        id="productTable"
                        data={data}
                        manual
                        pages={pages}
                        loading={loading}
                        onFetchData={this.fetchData}
                        defaultSorted={[{id: "active", desc: true}]}
                        columns={columns}
                        filterable
                        defaultFilterMethod={(filter, row) => {
                            if (row[filter.id] != null) {
                                return row[filter.id]
                                    .toLowerCase()
                                    .startsWith(filter.value.toLowerCase());
                            }
                        }}
                        resizable={false}
                        previousText="Précédent"
                        nextText="Suivant"
                        loadingText="Chargement..."
                        noDataText="Aucun produit"
                        pageText="Page"
                        ofText="sur"
                        rowsText="résultats"
                        minRows={1}
                    />
                    {infoTotalProduct}
                </div>
            </div>
        </Fragment>
    }
}

export default StripeProductList;
