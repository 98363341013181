import React from "react";
import _ from "lodash";

const moment = require("moment");
require("moment/locale/fr");

import SubPaymentList from "../../../generalPayments/SubPaymentList";
import * as api from "../../../../tools/api";
import swal from "sweetalert2";

class ExtendedSubPaymentList extends SubPaymentList {
    constructor(props) {
        super(props);

        if(props.isInvoicesActivated)
        {
            const column =
                {
                    Header: "Actions",
                    maxWidth: 75,
                    id: "actions",
                    filterable: false,
                    sortable: false,
                    Cell: d => (
                        <div className="flex">
                            { !!d.original.invoice_id ?
                                <button
                                    className="btn btn-primary btn-xs m-r-sm"
                                    onClick={() => this.handleShowInvoice(d.original.id)}>
                                    <i className="fas fa-file-invoice" />
                                </button>
                                : null }
                        </div>
                    ),
                };

            this.state.columns.push(column);
        }
    }

    handleShowInvoice(paymentId) {
        api.set()
            .success(({invoice_url}) => {
                console.log(invoice_url);
                window.open(invoice_url, '_blank');
            })
            .error((res) => {
                swal({
                    title: "error",
                    type: "error",
                    text: "Impossible d'afficher la facture"
                });
                console.log("show invoice : ", res)
            })
            .get(
                "/users/student_payments/invoice",
                {payment_id: paymentId  }
            );
    }


}

export default ExtendedSubPaymentList;
