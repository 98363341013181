import React from 'react'
import swal from "sweetalert2";
import { csrfToken } from "../../../utils";

export default function ResetMailSettings(props)
{
    function onSubmit()
    {
        swal({
            title: 'Confirmation',
            text: 'Voulez-vous vraiment réinitialiser les paramètres de messagerie ?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
        }).then((result) =>
        {
            if (result.value)
            {
                fetch('/api/edit_parameters/mails', {
                    method: 'DELETE',
                    credentials: 'same-origin',
                    headers: {
                        'X-CSRF-TOKEN': csrfToken,
                        "Content-Type": "application/json",
                    },
                }).then(response =>
                {
                    if (response.ok)
                    {
                        swal({
                            title: 'Success',
                            text: 'Les paramètres ont bien été réinitialisés',
                            type: 'success'
                        }).then(_ => window.location.reload());
                    }
                    else
                    {
                        swal({
                            title: 'Error',
                            text: 'Une erreur est survenue, contactez un administrateur',
                            type: 'error'
                        });
                    }
                });
            }
        });
    }

    return <button type="submit" className="btn btn-danger" onClick={onSubmit}>Réinitialiser les paramètres</button>
}