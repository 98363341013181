import React from "react";
import swal from "sweetalert2";
import {csrfToken} from "./../../../utils";
import * as api from "./../../../../tools/api";
import * as StripeHelpers from "../stripeUtils/StripeHelpers";
import {redirectTo} from "../../../../tools/url";

const moment = require('moment');

export default class StripeQuoteShow extends React.Component {
    cellCount = 3;

    constructor(props) {
        super(props)
        this.state = {
            load_finalize: false,
            loading: false,
        };
    }


    renderContentRow(title, value, lightHeader = false, lightValue = false) {
        const HeaderTag = lightHeader ? 'p' : 'h4';
        const ValueTag = lightValue ? 'p' : 'h4';

        return (
            <tr key={title}>
                <td colSpan={this.cellCount + 1}>
                    <HeaderTag className="text-right">{title}</HeaderTag>
                </td>
                <td>
                    <ValueTag className="text-right">{value}</ValueTag>
                </td>
            </tr>
        );
    }

    computeItemFields = (item) => {
        const stripeProduct = item.stripe_price.stripe_product;
        item.productName = stripeProduct.name;

        item.quantity = parseFloat(item.quantity) || 0;
        item.unit_amount_excluding_tax = parseFloat(item.stripe_price.unit_amount_decimal);
        if (item.stripe_price.tax_behavior === "inclusive") {
            item.unit_amount_excluding_tax = item.unit_amount_excluding_tax / (1 + item.first_tax_rate / 100.0);
        }

        item.discounted_amount = item.quantity * item.unit_amount_excluding_tax;
    }


    renderLineItems() {

        const items = this.props.items
        const quote = this.props.quote

        let rowsTable = []
        items.forEach((item, index) => {
            this.computeItemFields(item);

            rowsTable.push(
                <tr key={`item#${index}`}>
                    <td>{item.productName}</td>
                    <td className="text-right">{item.quantity}</td>
                    <td className="text-right">{StripeHelpers.formatStringifiedFloatAsCurrency(item.unit_amount_excluding_tax)}</td>
                    <td className="text-right">{StripeHelpers.formatAsPercentage(item.first_tax_rate)}</td>
                    <td className="text-right">{StripeHelpers.formatStringifiedFloatAsCurrency(item.discounted_amount)}</td>
                </tr>
            )
        })

        const taxTotals = _.get(quote, 'total_details.breakdown.taxes', []);

        return (
            <div className="row">
                <div className="col-sm-10">
                    <table className="table">
                        <thead>
                        <tr key={"header"}>
                            <th style={{width: "30%"}}>PRODUIT</th>
                            <th className={"text-right"} style={{width: "10%"}}>QUANTITÉ</th>
                            <th className={"text-right"} style={{width: "20%"}}>PRIX UNITAIRE</th>
                            <th className={"text-right"} style={{width: "20%"}}>TAUX TAXE</th>
                            <th className={"text-right"} style={{width: "20%"}}>MONTANT HT</th>
                        </tr>
                        </thead>
                        <tbody>

                        {rowsTable}

                        {this.renderContentRow(
                            "Total hors taxes",
                            StripeHelpers.formatStringifiedFloatAsCurrency(quote.amount_subtotal)
                        )}

                        {quote.discounts && quote.discounts.map((discount, index) =>
                            this.renderContentRow(
                                StripeHelpers.formatDiscountHeader(discount),
                                StripeHelpers.formatFloatAsCurrency(-this.getDiscountAmount(discount, quote.total_discount_amounts)),
                                true,
                                true
                            )
                        )}

                        {taxTotals.map((tax_row, index) =>
                            this.renderContentRow(
                                StripeHelpers.formatTaxHeader(tax_row.rate),
                                StripeHelpers.formatFloatAsCurrency(tax_row.amount / 100),
                                true,
                                true
                            )
                        )}

                        {this.renderContentRow(
                            "Total",
                            StripeHelpers.formatStringifiedFloatAsCurrency(quote.amount_total)
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    getDiscountAmount(discount, total_discount_amounts) {
        return total_discount_amounts.find(total_discount_amount => total_discount_amount.discount === discount.id).amount / 100
    }


    acceptQuote(id) {

        api
            .set()
            .success((msg) => {
                this.setState({load_finalize: false})
                redirectTo(msg.new_invoice);

            })
            .error((msg) => {
                swal({
                    type: "error",
                    title: "Une erreur est survenue",
                    text: msg.message
                })

            })
            .post(`/stripe_quotes/accept/${id}`);

    }

    downloadPDF(id) {
        const filePDF = fetch(`${id}/download_pdf`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json",
            },
        });
        filePDF.then(res => res.blob())
            .then(file => {
                const download = document.createElement("a");
                download.download = `Devis.pdf`;
                download.href = URL.createObjectURL(file);
                document.body.appendChild(download);
                download.click();
                document.body.removeChild(download);
                this.setState({loading: false})
            });

    }


    render() {

        const quote = this.props.quote
        const fullName = quote.customer.first_name + " " + quote.customer.last_name
        let months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

        let dateFormat = new Date(quote.expires_at)
        const expires_at = "Le " + dateFormat.getDate() + " " + months[dateFormat.getMonth()] + " " + dateFormat.getFullYear()

        return (
            <div>
                <div className="row wrapper border-bottom white-bg page-heading m-b-md">
                    <h2>Devis {quote.number}
                        <div className="btn-group pull-right">
                            {quote.status === 'open' || quote.status === 'accepted' ?
                                <button className="btn btn-sm btn-primary m-r-sm m-b-sm" disabled={this.state.loading}
                                        onClick={() => {
                                            this.setState({loading: true})
                                            this.downloadPDF(quote.stripe_quote_id)
                                        }}>
                                    {this.state.loading ? <i className="fas fa-circle-notch fa-spin"/> :
                                        <i className="fas fa-file-download"/>} Télécharger le PDF </button>

                                : ''}
                            {quote.status === 'open' ?
                                <button className="btn btn-sm btn-primary m-r-sm m-b-sm"
                                        disabled={this.state.loading} onClick={() => {
                                    this.setState({load_finalize: true})
                                    this.acceptQuote(quote.stripe_quote_id)
                                }}> Convertir en facture{this.state.load_finalize ?
                                    <i className="fas fa-circle-notch fa-spin"/> : ''} </button>


                                : ''}

                            {quote.status === 'draft' ?
                                <a className="btn btn-sm btn-primary m-r-sm m-b-sm"
                                   href={`/stripe_quotes/${quote.stripe_quote_id}/edit`}>
                                    <i className="fas fa-edit"/>Modifier le devis
                                </a>
                                : ''}
                        </div>
                    </h2>

                </div>
                <section className="panel panel-default">
                    <div className="panel-body">
                        <div className="row">
                            <div className="col-sm-6 form-group">
                                <label>Client</label>
                                <p>{fullName}</p>
                            </div>
                            <div className="col-sm-4 form-group">
                                <label>Date d'expiration</label>
                                <p>{expires_at}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 form-group">
                                <label>En-tête</label>
                                <p>{quote.header}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 form-group">
                                <label>Description</label>
                                <p>{quote.description}</p>
                            </div>
                        </div>
                        {this.renderLineItems()}
                        <div className="row">
                            <div className="col-sm-12">
                                <label>Pied de page</label>
                                <p>{quote.footer}</p>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        )
    }
}