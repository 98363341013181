import React, {Fragment} from "react";

export default class InvoiceHeaderSummaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            legalEntity: this.props.legalEntity,
            siretRna: this.props.siretRna,
            address: this.props.address,
            telephone: this.props.telephone,
            email: this.props.email,
            tax_exempt: this.props.tax_exempt,
            tax_id: this.props.tax_id
        }
        this.taxExemptOptions = [
            {label: "Imposable", value: "none"},
            {label: "Exonéré", value: "exempt"},
        ]
    }

    componentDidUpdate(prevProps) {
        if (prevProps.legalEntity !== this.props.legalEntity) {
            this.setState({legalEntity: this.props.legalEntity})
        }
        if (prevProps.siretRna !== this.props.siretRna) {
            this.setState({siretRna: this.props.siretRna})
        }
        if (prevProps.address !== this.props.address) {
            this.setState({address: this.props.address})
        }
        if (prevProps.telephone !== this.props.telephone) {
            this.setState({telephone: this.props.telephone})
        }
        if (prevProps.email !== this.props.email) {
            this.setState({email: this.props.email})
        }
        if (prevProps.tax_exempt !== this.props.tax_exempt) {
            this.setState({tax_exempt: this.props.tax_exempt})
        }
        if (prevProps.tax_id !== this.props.tax_id) {
            this.setState({tax_id: this.props.tax_id})
        }
    }

    render() {
        const address = this.state.address || {};
        const tax_id = this.state.tax_id || {};

        return <div className="ibox">
            <div className="ibox-title">
                <h3 style={{width: "100%"}}>
                    Coordonnées de facturation
                </h3>
            </div>

            <div className="ibox-content">
                {this.state.legalEntity ?
                    <Fragment>
                        <div className="row m-b-sm">
                            <div className="col-sm-1">
                                <i className="fas fa-building" aria-hidden="true"></i>
                            </div>
                            <div className="col-sm-10">
                                <Fragment>
                                    {this.state.legalEntity}<br/>
                                    {this.state.siretRna}
                                </Fragment>
                            </div>
                        </div>


                        <div className="row m-b-sm">
                            <div className="col-sm-1">
                                <i className="fas fa-regular fa-map-marker-alt"></i>
                            </div>
                            <div className="col-sm-10">
                                {address.line1}<br/>
                                {address.line2 ?
                                    <Fragment>{address.line2}<br/></Fragment>
                                    :
                                    ""
                                }
                                {address.postal_code}&nbsp;{address.city}<br/>
                                {address.country}<br/>
                            </div>
                        </div>


                        <div className="row m-b-sm">
                            <div className="col-sm-1">
                                <i className="fas fa-receipt" aria-hidden="true"></i>
                            </div>
                            <div className="col-sm-10">
                                {(this.taxExemptOptions.find(item => item.value === this.state.tax_exempt) || {}).label}
                                <br/>
                                {tax_id.value}
                            </div>
                        </div>
                    </Fragment>
                    :
                    "Aucune coordonnée de facturation enregistrée"
                }

                <div className="row m-b-sm">
                    <div className="col-sm-6">
                    </div>
                    <div className="col-sm-6">

                        <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            onClick={this.props.onEdit}
                        >
                            Editer
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }


}