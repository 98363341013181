export function formatDiscountHeader(discount) {
    return `${discount.coupon.name} (${discount.coupon.percent_off}% de réduction)`
}

export function formatFloatAsCurrency(value) {
    if(!value) return "-";

    return value.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    });
}

export function roundFloatWithTwoDecimal(value) {
    return Math.floor(value*100)/100;
}
export function formatStringifiedFloatAsCurrency(stringifiedFloat) {
    return parseFloat(stringifiedFloat).toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    });
}

export function formatAsPercentage(value) {
    return value ? `${value} %` : "-";
}

export function formatTaxHeader(taxRate) {
    const jurisdiction = [taxRate.country, taxRate.jurisdiction].filter(value => typeof value === 'string' && value).join("-") || "FR";
    return `${taxRate.display_name} - ${jurisdiction} à ${formatAsPercentage(taxRate.effective_percentage)}`;
}
