import React, {Fragment, useEffect} from "react";
import swal from "sweetalert2";
import * as api from "../../../tools/api";

export default function Support()
{
    useEffect(() => {
        api.set()
            .success((data) => {
                const script = document.createElement('script');

                script.src = "https://youtrack.sixmon.net/static/simplified/form/form-entry.js";
                script.async = true;
                script.id = data.ythid;
                script.setAttribute("data-yt-url", "https://youtrack.sixmon.net");
                script.setAttribute("data-theme", "light");
                script.setAttribute("data-lang", "fr");

                document.getElementById("yt-helpdesk").appendChild(script);
            })
            .error((error) => {
                console.error(error);

                swal({
                    title: "Erreur",
                    text: "Une erreur est survenue lors de la création du formulaire de support.",
                    type: "error",
                });
            })
            .get("/api/support/ythid", {});
    }, []);

    return <Fragment>
        <div className="row wrapper border-bottom white-bg page-heading m-b-md">
            <h2>
                Demande de support
            </h2>
        </div>

        <div id="yt-helpdesk"></div>
    </Fragment>
}