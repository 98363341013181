export default class IDataService {
    listData(filter, format) {
        throw new Error("Not Implemented");
    }

    updateData(data) {
        throw new Error("Not Implemented");
    }

    createData(data) {
        throw new Error("Not Implemented");
    }

    deleteData(data) {
        throw new Error("Not Implemented");
    }
}
