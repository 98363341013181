import React, {Fragment} from "react";

const moment = require('moment');

import InputSelect from "./../../../../components/common/InputSelect";
import Input from "./../../../../components/common/Input";

import {Field, Form} from "react-final-form";
import {FieldArray} from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";

import * as api from "./../../../../tools/api";
import {redirectTo} from "./../../../../tools/url";

import swal from "sweetalert2";

export default class StripeCreditNotesCreate extends React.Component {
    constructor(props) {
        super(props)
    }

    onSubmit(e) {
        const lineSelect = []
        e.lineSelect.forEach(item => {
            if (item.selected) {
                lineSelect.push({"stripe_li": item.stripe_li, "quantity": item.quantity})
            }
        })

        api
            .set()
            .success(() => {
                redirectTo("/stripe_invoices/" + e.stripe_invoice_id);
                swal({
                    type: "success",
                    title: "Avoir créé"
                });

            })
            .error((msg) => {
                swal({
                    type: "error",
                    title: "Une erreur est survenue"
                })

            })
            .post("/stripe_credit_notes/" + e.stripe_invoice_id, {line: lineSelect, reason: e.reason, memo: e.memo});
    }

    validate(e) {
        const errors = {};

        if (!e.reason) {
            errors.reason = "Le motif est obligatoire"
        }
        errors.lineSelect = []
        e.lineSelect.forEach((line, index) => {
            const quantity = parseInt(line.quantity)

            if (line.selected) {
                if (quantity < 1 || isNaN(quantity))
                    errors.lineSelect[index] = {quantity: "La quantité doit être supérieure à 0"}

                if (quantity > line.lessThanQuantity)
                    errors.lineSelect[index] = {quantity: "La quantité pour se produit ne peut pas dépasser " + line.lessThanQuantity}
            }

        })
        return errors;
    }

    formatFloatWithTwoDecimal(unit_amount_decimal) {
        return unit_amount_decimal.toFixed(2)
    }

    dataLine(index, name, state = null) {

        const lineItem = this.props.lineItems[index]
        const taxRates = this.props.taxRates
        const stripeProduct = lineItem.stripe_price.stripe_product
        //console.log("lineItem", lineItem);

        if (name === "name")
            return stripeProduct.name

        let price = parseFloat(lineItem.stripe_price.unit_amount_decimal)

        if (name === "price")
            return this.formatFloatWithTwoDecimal(price) + " €"

        if (name === "totalPriceHT") {
            const quantity = state
            return this.formatFloatWithTwoDecimal(lineItem.discounted_amount) + " €"
        }

        if (name === "TVA")
            return this.formatFloatWithTwoDecimal(lineItem.first_tax_rate * 1) + " %"

        if (name === "percentOff")
            return lineItem.first_discount_rate ? lineItem.first_discount_rate + " %" : "-"
    }

    renderTotals(baseAmount, state) {
        const lineItems = this.props.lineItems
        const taxRates = this.props.taxRates
        const stateValues = state.values.lineSelect
        let totalHT = 0
        let totalTVA = 0

        for (let i = 0; i < lineItems.length; i++) {

            if (stateValues[i].selected === true) {

                let price = 0
                if (stateValues[i].quantity !== undefined)
                    price = lineItems[i].discounted_amount

                totalHT += price

                totalTVA = totalTVA + (price * lineItems[i].first_tax_rate / 100.0)

            }
        }

        let montantTotalCrediter = totalHT + totalTVA
        let montantFacture = parseFloat(baseAmount)
        let montantReajuste = montantFacture - montantTotalCrediter
        if (montantReajuste === montantFacture)
            montantReajuste = 0

        return [
            <tr key={"totalHT"}>

                <td colSpan={6}><h4 className={"text-right"}>Sous-total (HT)</h4></td>
                <td className="text-right">{this.formatFloatWithTwoDecimal(totalHT)} €</td>
            </tr>,
            <tr key={"totalTVA"}>

                <td colSpan={6}><p className={"text-right"}>TVA total</p></td>
                <td className="text-right">{this.formatFloatWithTwoDecimal(totalTVA)} €</td>
            </tr>,
            <tr key={"totalCredit"}>

                <td colSpan={6}><h4 className={"text-right"}>Montant total à créditer</h4></td>
                <td className="text-right">{this.formatFloatWithTwoDecimal(montantTotalCrediter)} €</td>
            </tr>,
            <tr key={"invoiceAmount"}>

                <td colSpan={6}><p className={"text-right"}>Montant de la facture</p></td>
                <td className="text-right">{this.formatFloatWithTwoDecimal(montantFacture)} €</td>
            </tr>,
            <tr key={"adjustedAmount"}>

                <td colSpan={6}><h4 className={"text-right"}>Montant dû réajusté</h4></td>
                <td className="text-right">{this.formatFloatWithTwoDecimal(montantReajuste)} €</td>
            </tr>,


        ]
    }

    render() {

        const items = this.props.lineItems

        const baseAmount = items[0].stripe_invoice.amount_due
        const reasonList = () => {
            return [
                {"value": "duplicate", "label": "Doublon"},
                {"value": "product_unsatisfactory", "label": "Produit insatisfaisant"},
                {"value": "order_change", "label": "Modification de la commande"},
                {"value": "fraudulent", "label": "Paiement frauduleux"}
            ]
        }
        const initCreditNote = (items, id_invoice) => {

            const initValue = [{}]
            for (let i = 0; i < items.length; i++) {

                initValue[i] = {
                    "stripe_li": items[i].stripe_li,
                    "quantity": items[i].quantity,
                    "lessThanQuantity": items[i].quantity,
                    "unit_amount": items[i].stripe_price.unit_amount_decimal,
                    "tax_id": items[i].stripe_price.stripe_product.tax_rate,
                    "selected": true
                }

            }

            return {lineSelect: [...initValue], stripe_invoice_id: id_invoice}
        }
        const disableSubmit = (state) => {
            for (let i = 0; i < state.length; i++)
                if (state[i].selected)
                    return false
            return true
        }
        const requiredSelect = value => (value !== undefined ? undefined : 'requis')

        return <Fragment>
            <div className="row wrapper border-bottom white-bg page-heading m-b-md">
                <h2>Créer un avoir</h2>
            </div>
            <section className="panel panel-default">
                <Form
                    onSubmit={this.onSubmit}
                    validate={this.validate}
                    mutators={{
                        ...arrayMutators,
                    }}
                    initialValues={initCreditNote(items, this.props.idInvoice)}

                    render={({handleSubmit, form: {getState}}) => (
                        <div className="panel-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-4 form-group">
                                        <Field
                                            label="Motif"
                                            name="reason"
                                            type="select"
                                            required
                                            render={InputSelect}
                                            validate={requiredSelect}
                                            options={reasonList()}

                                        />
                                    </div>
                                </div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>Produit</th>
                                        <th>Quantité du crédit</th>
                                        <th>Prix unitaire HT</th>
                                        <th>Taux taxe</th>
                                        <th>Taux remise</th>
                                        <th>Montant remisé HT à créditer</th>
                                    </tr>
                                    </thead>
                                    <FieldArray name="lineSelect">
                                        {({fields}) => (
                                            <tbody>
                                            {fields.map((name, index) => (
                                                <tr key={index}>
                                                    <td><Field name={`${name}.selected`} component="input"
                                                               type="checkbox"/></td>
                                                    <td>{this.dataLine(index, "name")}</td>
                                                    <td className="text-right">
                                                        <Field
                                                            name={`${name}.quantity`}
                                                            required type="number"
                                                            render={Input}
                                                            disabled={!getState().values.lineSelect[index].selected}
                                                        />
                                                    </td>
                                                    <td className="text-right">{this.dataLine(index, "price")}</td>
                                                    <td className="text-right">{this.dataLine(index, "TVA")}</td>
                                                    <td className="text-right">{this.dataLine(index, "percentOff")}</td>
                                                    <td className="text-right">{this.dataLine(index, "totalPriceHT", getState().values.lineSelect[index].quantity)}</td>

                                                </tr>
                                            ))}

                                            {this.renderTotals(baseAmount, getState())}

                                            </tbody>
                                        )}
                                    </FieldArray>
                                </table>
                                <div className="row">
                                    <div className="col-sm-6 form-group">
                                        <Field
                                            label="Note"
                                            name="memo"
                                            isArea={true}
                                            render={Input}

                                        />
                                    </div>
                                </div>
                                <div className="flex flex-end-justified" type="submit">
                                    <button className="btn btn-primary"
                                            disabled={disableSubmit(getState().values.lineSelect)}>
                                        <i className="fas fa-save m-r-sm"></i>&nbsp;Créer un avoir
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                />
            </section>
        </Fragment>
    }
}